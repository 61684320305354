.vintageWrapper {
    .desktop {
        display: block !important;
    }

    .mobile {
        display: none !important;
    }

    .button {
        width: 250px;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 40px;
        height: 50px;
        color: $primaryColor;
        border: 0px solid $primaryColor;

        &.yellow {
            background-color: $vintageYellow;
        }

        &.green {
            background-color: $vintageGreen;
        }
    }

    .background-title {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 855px;
        background-image: url('../img/satellites/vintage/background-vintage.jpg');
        background-size: cover;
        background-repeat: no-repeat;

        .title {
            width: 100%;
            max-width: 600px;
            font-family: $primaryFont;
            text-align: center;
            color: $white;
            text-transform: uppercase;

            img {
                width: 100%;
            }

            span {
                color: $vintageYellow;
                display: block;
                font-size: 120px;
                font-weight: 400;
                text-transform: uppercase;
            }
        }
    }

    .second-main-content {
        max-width: 1330px;
        width: 100%;
        margin: 20px auto;

        .content {
            display: flex;
            align-items: center;
            gap: 87px;

            .content-txt {
                h2 {
                    font-size: 40px;
                    font-weight: 700;
                    text-transform: uppercase;
                    letter-spacing: -1.3px;
                    line-height: 45px;
                    margin-bottom: 22px;
                }

                p {
                    font-size: 16px;
                    line-height: 20px;
                }

                span {
                    color: $vintageYellow;
                }
            }

            .content-img {
                position: relative;

                .button {
                    position: absolute;
                    top: 46.5%;
                    left: 31%;
                }
            }

            &.content-main-2 {
                flex-direction: row-reverse;
                margin-top: -160px;
                gap: 87px;

                .content-img {
                    margin-right: -15px;
                }

                .content-txt {
                    text-align: right;

                    h2 {
                        margin-top: -10px;
                    }
                }

                span {
                    color: $vintageGreen;
                }
            }
        }
    }

    .background-piece {
        background-color: $vintageYellow;
        height: 855px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        h2 {
            color: var(--Base, var(--Base, #000B21));
            text-align: center;
            font-size: 37px;
            font-weight: 700;
            text-transform: uppercase;
            margin-bottom: 100px;

            span {
                color: $white;
            }
        }

        .content-piece {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 180px;
            width: 100%;
            max-width: 1200px;
            margin: 0 auto;

            .block-piece {
                display: flex;
                flex-direction: column;
                align-content: center;
                align-items: center;

                img {
                    margin-bottom: 40px;
                }

                p {
                    text-align: center;
                    font-size: 16px;
                    line-height: 20px;
                }
            }
        }
    }

    .content-engagement {
        height: 855px;
        background-image: url('../img/satellites/vintage/photo-bloc-engagement.png');
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .block-text {
            width: 355px;
            left: 17%;
            position: relative;

            h2 {
                color: $white;
                font-size: 40px;
                font-weight: 700;
                text-transform: uppercase;
                margin-bottom: 25px;
                letter-spacing: -1.3px;
            }

            p {
                color: $white;
                font-size: 16px;
                line-height: 20px;
                font-weight: 400;
            }
        }
    }

    .content-tutorial {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        max-width: 794px;
        margin: auto;

        h2 {
            color: $primaryColor;
            text-align: center;
            font-size: 37px;
            font-weight: 700;
            text-transform: uppercase;
            margin-top: 60px;
            margin-bottom: 50px;
            width: 100%;
        }

        p {
            font-size: 16px;
            line-height: 20px;
        }

        .block-tutorial {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 20px;
            margin-bottom: 50px;

            span {
                color: $vintageYellow;
            }

            &.block-tutorial-3 {
                span {
                    color: $vintageGreen;
                }
            }
        }

        .img-recycle {
            margin: 70px auto 60px;
        }
    }

    .background-question {
        background-color: $vintageGreen;
        min-height: 735px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 60px;

        .button {
            background-color: $primaryColor ;
            color: $white;
            margin: 0 auto 25px auto;
        }

        h2 {
            font-size: 37px;
            font-weight: 700;
            text-transform: uppercase;
            margin-bottom: 70px;
        }

        ul {
            text-align: center;
            font-size: 20px;
            font-weight: 700;
            line-height: 40px;
            text-transform: uppercase;

            li {
                p {
                    cursor: pointer;
                    line-height: normal;
                    margin-bottom: 13px;

                    &:after {
                        content: "";
                        background-image: url(../img/satellites/vintage/arrow.png);
                        background-repeat: no-repeat;
                        background-size: auto;
                        position: relative;
                        display: inline-block;
                        left: 13px;
                        top: 3px;
                        width: 20px;
                        height: 20px;
                        background-position: center center;
                    }

                    &.active {
                        &:after {
                            transform: rotate(90deg);
                        }
                    }
                }

                span {
                    display: none;
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: 400;
                    text-transform: initial;
                    margin-bottom: 13px;

                    ul {
                        margin-bottom: 0;
                    }

                    li {
                        font-size: 16px;
                        line-height: 20px;
                        font-weight: 400;
                        text-transform: initial;
                    }

                    &.active {
                        display: block;
                    }
                }
            }
        }

        .content-vend {
            margin-bottom: 60px;
        }

    }
}