.fullpage-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 55vh;
    span {
        font-size: 0;
    }
    &:before {
        content: '';
        display: block;
        width: 80px;
        height: 20px;
        background: transparent url(../svg/three_dots.svg) center no-repeat;
        background-size: cover;
    }
}

.search_recap {
    .recap {
        text-align: center;
        margin-top: 75px;
        margin-bottom: 65px;
    }
    .search_term {
        font-weight: 400;
        margin-top: 10px;
        margin-bottom: 25px;
        font-size: 50px;
        text-transform: uppercase;
        span {
            font-weight: 500;
            font-size: 50px;
        }
    }
    .nbr_result {
        font-size: 15px;
    }
}


//FILTERS RECHERCHE
#search_page {
    .sort_price_search {
        label {
            display: flex;
            span {
                white-space: nowrap;
            }
        }
    }
    
    .form_itm.check.eclats {
        display: flex;
        white-space: nowrap;
    }

    //Visuels - rollover
    .imgWrapper {
        position: relative;

        &:hover {
            .product_wrapper {
                opacity: 1;

                .productVisualMulti {
                    .rollover_rayonsPrev {
                        left: 1rem;
                    }
        
                    .rollover_rayonsNext {
                        right: 1rem;
                    }
                }
            }
        }

        .product_wrapper {
            position: absolute;
            opacity: 0;
            top: 0;
            left: 0;
            width: 100%;
            transition: opacity 0.2s linear;

            .productVisualMulti {
                .swiper-slide {
                    img {
                        width: 100%;
                        height: auto;
                    }
                }

                .rollover_rayonsPrev,
                .rollover_rayonsNext {
                    position: absolute;
                    z-index: 2;
                    top: 50%;
                    @include bgIconPath('../svg/spritesheet.svg');
                    @include bgIconCoord(29, $col, $row);
                    width: 1rem;
                    height: 1rem;
                    cursor: pointer;
                    user-select: none;
                    transition: all 0.3s ease-in-out, opacity 0s ease-in-out;
    
                    &:focus {
                        outline: 0;
                    }
                }
    
                .rollover_rayonsPrev {
                    left: -1rem;
                    transform: translateY(-50%) rotate(90deg);
                }
    
                .rollover_rayonsNext {
                    right: -1rem;
                    transform: translateY(-50%) rotate(-90deg);
                }
            }
        }
    }
}

//NO RESULTS
.no_results_wrapper {
    max-width: 1280px;
    width: 98%;
    margin: 0 auto;
    text-align: center;
    padding-top: 0;
    padding-bottom: 30px;
    .title_no_result, .search_term {
        font-size: 45px;
        font-weight: 400;
        text-transform: uppercase;
    }
    .search_term {
        font-weight: 500;
        margin-top: 10px;
        margin-bottom: 50px;
        font-size: 50px;
    }
    .tips_title {
        font-size: 18px;
        text-transform: uppercase;
        font-weight: 500;
        margin-bottom: 20px;
    }
    .search_tips .tip {
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0;
        }
        &::before {
            content: '';
            height: 3px;
            width: 3px;
            display: block;
            background: black;
            margin-right: 12px;
        }
    }
    .catPush_list {
        margin-top: 100px;
        .insideImg {
            display: flex;
            justify-content: center;
            .home-column {
                max-width: 306px;
                margin-right: 20px;
                &:last-child {
                    margin-right: 0;
                }
                .home-module-img {
                    width: 100%;
                }
                .home-module-title span {
                    margin-top: 10px;
                    font-weight: 500;
                    font-size: 15px;
                    display: block;
                }
            }
        }
    }
}