@import '../../_app/_src/scss/desktop/mixins/typeFace';

$helveticacond: (
    normal: (light, regular, medium, bold)
);

@include typeFace('helveticacond', $helveticacond);

// *********************************************************
//  EXEMPLE :
//  font-family: 'Metropolis', sans-serif;
//  font-weight: 400;
// *********************************************************

$primaryFont: 'helveticacond', sans-serif;