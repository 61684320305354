
body {
    font-family: $primaryFont;
    color: $bgPrimaryBtn;
    padding-top: 84px;
}

.home_test {
    width: 100%;
    height: auto;
    vertical-align: top;
}

.pricetag+.pricetag {
    text-decoration: line-through;
    margin-left: 1rem;
}

.cache,
[v-cloak] {
    display: none;
}

.reinsuranceMarquee .swiper-wrapper {
    transition-timing-function: linear!important;

    .swiper-slide {
        font-size: 18px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: .02rem;
        text-align: center;

        &.yellow {
            color: $yellow;
        }

        &.green {
            color: $green;
        }

        &.pink {
            color: $pink;
        }

        &.blue {
            color: $blue;
        }
    }
}

.swiper-pagination-bullet {
    width: 8px;
    height: 8px;
}

.swiper-pagination-bullet-active {
    background: white;
}

.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 8px;
}

.pricetag,
.price_tag {
    .no_cents {
        display: none;
    }

    span.currency {
        margin-left: .2rem;
    }
}