.locator_heading {
    width: 100%;
    max-width: 1920px;
    position: relative;
    margin: 0 auto;
    margin-bottom: 50px;
    .imgWrapper {
        width: 100%;
        img {
            width: 100%;
            height: auto;
        }
    }
    .title {
        font-size: 90px;
        font-weight: 500;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $bgPrimaryBtn;
        text-transform: uppercase;
    }
}

.store_locator_page {
    width: 98%;
    max-width: 1280px;
    margin: 0 auto;
    .formWrapper {
        width: 100%;
        margin-bottom: 50px;
        .wrap_fields {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 48px;
        }
    }
    //Selecteur pays
    .field_country {
        display: none;
    }
    // Input champ adresse
    .field_address {
        position: relative;
        width: 74%;
        height: 100%;
        input[type="text"] {
            width: 100%;
            height: 100%;
            border: none;
            border-bottom: 1px solid black;
            box-sizing: border-box;
            &:focus {
                outline: none;
                box-shadow: none;
            }
            &::placeholder {
                font-family: $primaryFont;
                color: $bgPrimaryBtn;
                font-weight: 500;
                font-size: 15px;
            }
        }
    }
    //Bouton de géolocalisation
    button.loc_btn {
        width: 21%;
        height: 50px;
        background-color: $bgPrimaryBtn;
        color: white;
        font-size: 18px;
        padding: 0;
    }
    .wrapper_retailers_map {
        display: flex;
        justify-content: space-between;
        overflow: hidden;
        //Liste des distributeurs
        .liste_recherche_distributeur {
            width: 31%;
            .resultat_recherche {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                font-size: 25px;
                text-transform: uppercase;
                font-weight: 500;
                margin-bottom: 30px;
                @media screen and(max-width: 1280px) {
                    font-size: 18px;
                }
                .nb_magasins {
                    font-weight: 600;
                }
                > div {
                    font-weight: 400;
                }
            }
            .liste_distributeur_wrapper {
                #liste_distributeur {
                    height: 560px;
                    .elem_liste_distributeur {
                        width: 90%;
                        padding: 15px 0 15px 15px;
                        margin-bottom: 10px;
                        .storePic {
                            display: none;
                        }
                        .storeInfos {
                            color: $bgPrimaryBtn;
                            line-height: 20px;
                            font-size: 15px;
                        }
                        .nom_store {
                            text-transform: uppercase;
                            font-weight: 500;
                        }
                        .store_more a {
                            color: $bgPrimaryBtn;
                            font-weight: 500;
                            text-underline-position: from-font;
                        }
                        .phone_store {
                            display: none;
                        }
                        .horaire_today {
                            color: #ff0000;
                            display: flex;
                            align-items: center;
                            @media screen and(max-width: 865px) {
                                align-items: flex-start;
                            }
                            .txt_store_open,
                            .puce.store_close {
                                color: #1ca82c;
                                position: relative;
                                display: flex;
                                align-items: center;
                                margin-right: .25rem;
                                &:before {
                                    content: "";
                                    height: 8px;
                                    display: block;
                                    background: #1ca82c;
                                    width: 8px;
                                    margin-right: .45rem;
                                    border-radius: 50%;
                                    margin-bottom: 2px
                                }
                                &+span {
                                    color: black;
                                }
                            }
                            .puce.store_close {
                                &:before {
                                    content: "";
                                    height: 8px;
                                    display: block;
                                    background: #ff0000;
                                    width: 8px;
                                    margin-right: .25rem;
                                    border-radius: 50%;
                                    margin-bottom: 2px
                                }
                            }
                        }
                        &:nth-child(4n+1) {
                            background: #fdbed1;
                        }
                        &:nth-child(4n+2) {
                            background: #bbe5c7;
                        }
                        &:nth-child(4n+3) {
                            background: #fcf1a9;
                        }
                        &:nth-child(4n+4) {
                            background: #aac4f0;
                        }
                    }
                    .os-scrollbar-vertical {
                        padding: 0;
                        width: 2px;
                        background: #e5e5e5;
                    }
                    .os-scrollbar-handle {
                        width: 2px;
                    }
                }
            }
        }
        //CARTE
        #retailers_map {
            width: 67% !important;
            height: 615px;
            .info_popup {
                font-size: 14px;
                line-height: 1.5;
                letter-spacing: .04rem;
                padding: 1rem;
                a {
                    color: black;
                    text-decoration: none;
                }
                .name {
                    font-weight: 500;
                }
                .cta_row {
                    margin-top: .5rem;
                    a {
                        text-decoration: underline;
                        text-underline-position: under;
                        &+a {
                            margin-left: 2rem;
                        }
                    }
                }
            }
        }
    }
}

#retailers_map {
    .gm-style-iw.gm-style-iw-c {
        top: 0;
        box-shadow: unset;
        border-radius: 0;
        padding: unset;
        .gm-style-iw-d {
            overflow: unset !important;
        }
    }
    .info_popup {
        font-family: $primaryFont;
        font-size: 15px;
        font-weight: 500;
        width: 280px;
        box-sizing: border-box;
        .name {
            text-transform: uppercase;
            font-weight: unset;
            margin-bottom: .3rem;
        }
    }
    .gm-style .gm-style-iw-t::after {
        background: url(../img/maps/marker.png);
        background-size: contain;
        background-repeat: no-repeat;
        box-shadow: unset;
        transform: unset;
        top: -5px;
        left: 90px;
        height: 45px;
        width: 45px;
    }
}

//Page détail boutique
.masgasin_pages {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 98%;
    max-width: 1280px;
    margin: 0 auto;
    flex-wrap: wrap;
    .title_shop_detail {
        width: 100%;
        text-transform: uppercase;
        text-align: center;
        color: $bgPrimaryBtn;
        font-size: 90px;
        font-weight: 500;
        margin-top: 35px;
        margin-bottom: 80px;
        letter-spacing: 0.1rem;
    }
    #store_detail_map {
        width: 67%;
        height: 613px;
    }
    .storeDetails {
        width: 33%;
        padding: 37px;
        box-sizing: border-box;
        height: 613px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: relative;
        font-family: $primaryFont;
        @media screen and(max-width: 1024px) {
            .btn_send_info {
                text-align: center;
            }
            .store_open {
                align-items: baseline !important;
            }
        }
        @media screen and(max-width: 800px) {
            padding: 15px;
        }
        .retour_store_locator {
            position: absolute;
            text-decoration: none;
            text-transform: capitalize;
            top: 35px;
            font-size: 13px;
            display: flex;
            align-items: center;
            color: $bgPrimaryBtn;
            opacity: .5;
            &::before {
                content: '';
                border: solid $bgPrimaryBtn;
                opacity: .5;
                border-width: 0 1px 1px 0;
                display: inline-block;
                padding: 3px;
                transform: rotate(135deg);
                margin-right: .3rem;
            }
        }
        .title {
            font-size: 25px;
            font-weight: 500;
            text-transform: uppercase;
            margin-bottom: .3rem;
        }
        section:first-of-type {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .storeInfos {
                width: 100%;
                box-sizing: border-box;
                font-size: 15px;
                line-height: 1.5;
            }
            .storeHours {
                margin: 1rem 0;
                .label {
                    display: block;
                    font-weight: 500;
                    text-transform: uppercase;
                    margin-bottom: .5rem;
                    margin-top: 1.5rem;
                }
                .time_table {
                    margin-bottom: 1rem;
                    .day_wrapper {
                        display: flex;
                        .day {
                            width: 90px;
                        }
                        .day_hours {
                            text-transform: uppercase;
                        }
                    }
                }
                .store_open, .store_close {
                    display: flex;
                    align-items: center;
                    &::before {
                        content: '';
                        width: 8px;
                        height: 8px;
                        display: block;
                        border-radius: 50%;
                        margin-right: .5rem;
                    }
                    span {
                        margin-right: .3rem;
                    }
                }
                .store_open  {
                    .txt_store_open {
                        color: #1ca82c;
                    }
                    &::before {
                        background: #1ca82c;
                    }
                }
                .store_close {
                    color: #ff0000;
                    &::before {
                        background: #ff0000;
                    }
                }
            }
        }
        section:last-of-type {
            margin-top: 1rem;
            h3 {
                height: 50px;
                &:first-child {
                    margin-bottom: 20px;
                }
            }
            a {
                height: 100%;
            }
        }
    }
    .end_locator_detail {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        overflow: hidden;
        .end_locator_1 {
            width: 33%;
            margin-right: 30px;
        }
        .end_locator_2 {
            width: 66%;
        }
    }
}