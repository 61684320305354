.satellite_breadcrumbs_wrapper {
    display: none;
}

.wrapper_content_sat.page_text {
    width: 98%;
    max-width: 1280px;
    margin: 0 auto;

    .title {
        font-size: 90px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: .04rem;
        text-align: center;
        margin: 28px 0 80px;
    }

    .row {
        margin-bottom: 50px;
        font-size: 15px;
        line-height: 1.2;
        text-align: justify;
    }

    h2 {
        font-size: 19px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: .04rem;
        text-align: center;
        margin-bottom: 12px;
    }

    .content_sat.siteMap {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;

        h2 {
            text-align: left;
            margin-bottom: 2rem;
        }

        .sitemap_section {
            width: 25%;

            &.categId5 {
                display: none;
            }

            h3 {
                font-weight: 500;
                margin-bottom: .5rem;
            }

            ul {
                margin: 0 0 2rem .5rem;
            }

            a {
                color: $primaryColor;
                text-decoration: underline;
            }
        }
    }
}