$white:      	 #FFFFFF;
$black:      	 #000000;

$primaryColor: #000b21;
$secondaryColor: white;
$footer_bgviolet: #680340;
$footer_bgrose: #FF4C8D;

$pink: #f95d8c; // Footer - Rollover basket reinsurance - Product reinsurance
$yellow: #fcd55f; // Footer - Rollover basket reinsurance - Product reinsurance
$blue: #4a81df; // Rollover basket reinsurance - Product reinsurance
$blue_high: #87e2ff; // Cookies acceptance - Addresses
$purple: #d7acfe; // Newsletter sub background - Orders - bg alertbox

$pink_light: #ffa8d1; // Search rollover - Basket
$pink_dark: #fc55b0;

$bg_connexion: #fdcadd; // Login background :)

$green: #56bd74; // Orders
$light_green: #bdfdc1; // Orders
$yellow_news: #fdf971; // Newsletter preferences
$blue_light: #e5f5fc; // Basket - Order detail total

$vintageYellow : #FDE14C; // Jaune page vintage
$vintageGreen : #A3DABE; // vert page vintage
/**
 * BUTTONS
 */
$bgPrimaryBtn: #000b21;

/* forms */
$error_red: #FF6D6D;

$leadbelcherMetal: #888c8d;