.faq {
    .reinsurance {
        display: none;
    }
    .wrapper_moncompte_main.faq {
        width: 100%;
        max-width: unset;
        margin: unset;
    }
}
.faqWrapper {
    width: 98%;
    max-width: 1280px;
    margin: 0 auto;
    h1 {
        margin-top: 2rem;
        font-size: 90px;
        font-weight: 500;
        text-transform: uppercase;
        color: $bgPrimaryBtn;
        text-align: center;
        letter-spacing: .05rem;
    }
    .site_search {
        position: relative;
        margin: 60px auto 50px;
        margin-bottom: 0;
        .w-input-container {
            .w-input-element:not([type=radio]):not([type=checkbox]),
            input[type=text].w-input-element {
                border: 0;
                padding: 0;
                height: 38px;
                outline: none;
                box-shadow: none;
                &::placeholder {
                    font-family: $primaryFont;
                    font-size: 15px;
                    font-weight: 500;
                    color: $bgPrimaryBtn;
                }
            }
            &:after {
                content: '';
                display: block;
                width: 100%;
                height: 1px;
                background-color: $black;
            }
        }
        .search_results {
            display: none;
            border: 1px solid #ebebeb;
            border-top: none;
            background: #fff;
            position: absolute;
            top: 75%;
            width: 100%;
            z-index: 2;
            font-size: 16px;
            color: $bgPrimaryBtn;
            letter-spacing: .04rem;
            transition: all 0.2s ease-out;
            max-height: 30rem;
            overflow-y: auto;
            li {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                height: 25px;
                border-top: 1px solid #ebebeb;
                padding-left:5px;
                cursor: pointer;
                &:first-of-type {
                    border: none;
                }
            }
        }
    }
    .theme_detail {
        margin-top: 50px;
    }
    .theme_global {
        display: none;
    }
    #theme_page {
        display: flex;
        flex-direction: column-reverse;
    }
    .theme_menu {
        ul {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: 30px;
            li {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                width: calc(33% - 33px);
                height: 210px;
                text-align: center;
                color: $bgPrimaryBtn;
                box-sizing: border-box;
                cursor: pointer;
                border: solid 1px $bgPrimaryBtn;
                margin-right: 54px;
                margin-bottom: 30px;
                background-repeat: no-repeat;
                background-position: 50% 25px;
                background-color: transparent;
                transition: background-color .2s linear;
                @media screen and(max-width: 1024px) {
                    width: calc(33% - 50px);
                }
                h2 {
                    font-size: 35px;
                    font-weight: 500;
                    color: $bgPrimaryBtn;
                    line-height: 1.3;
                    text-transform: uppercase;
                    letter-spacing: .02rem;
                    width: 72%;
                    padding-top: 60px;
                    margin: 0 auto;
                    @media screen and(max-width: 1280px) {
                        font-size: 25px;
                    }
                    @media screen and(max-width: 1024px) {
                        font-size: 18px;
                    }
                }
                &.active {
                    border: none;
                    &:nth-child(1) {
                        background-color: #bdfdc3;
                    }
                    &:nth-child(2) {
                        background-color: #f7dc29;
                    }
                    &:nth-child(3) {
                        background-color: #f95D8B;
                    }
                    &:nth-child(4) {
                        background-color: #4d84df;
                    }
                    &:nth-child(5) {
                        background-color: #5a5fbf;
                    }
                    &:nth-child(6) {
                        background-color: $purple;
                    }
                    &:nth-child(7) {
                        background-color: #fec9dd;
                    }
                    &:nth-child(8) {
                        background-color: $green;
                    }
                    span {
                        color: white;
                    }
                }
                @media screen and(max-width: 1281px) {
                    span {
                        width: 95%;
                    }
                }
                &:nth-child(3n) {
                    margin-right: 0;
                }
            }
        }
    }
    .faq_title {
        display: none;
        font-size: 40px;
        color: $bgPrimaryBtn;
        font-weight: 500;
        margin-top: 10px;
        margin-bottom: 20px;
    }
    .faq_element.active {
        .faq_title {
            display: block;
        }
    }
    .container_question_reponse {
        border-bottom: solid 1px lightgray;
        &:last-child {
            border-bottom: none;
            margin-bottom: 45px;
        }
    }
    .faq_question {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding-top: 30px;
        padding-bottom: 25px;
        font-size: 19px;
        color: $bgPrimaryBtn;
        font-weight: 500;
        letter-spacing: .04rem;
        text-transform: uppercase;
        cursor: pointer;
        &:after {
            content: '+';
            font-size: 20px;
            font-weight: 300;
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
        }
        &.active {
            &:after {
                content: '_';
            }
        }
    }
    .faq_reponse {
        font-size: 15px;
        letter-spacing: .02rem;
        font-weight: 500;
        line-height: 1.1;
        padding: 0px 10px 25px 0px;
        box-sizing: border-box;
        strong {
            font-weight: 600;
        }
        table {
            min-width: 35rem;
            tr {
                border: 1px solid #ccc;
                td {
                    padding: .5rem;
                    border: 1px solid #ccc;
                    min-width: 2rem;
                }
            }
        }
    }

    #form_contact1.w-contact-form {
        .w-textarea textarea {
            height: 96px;
            padding-top: 1.3rem;

            &:focus {
                + .w-input-label {
                    transform: translateY(50%);
                }
            }
        }
    }
}

.fr {
    .faqWrapper {
        #form_contact1.w-contact-form {
            padding-top: 2rem;
        }
    }
}