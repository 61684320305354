$col: 6;
$row: 6;

/*******************************************************************/
/* HEADER LOGOS */
/*******************************************************************/

.bgSprite {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    &:before {
        content: '';
        width: 15px;
        height: 15px;
    }

    @include spriter('../svg/spritesheet.svg', 6, 6, (1: 'switch_lang', 2: 'search', 3: 'account', 4: 'wish', 5: 'basket', 6: 'trash', 7: 'facebook_w', 8: 'facebook_b', 9: 'instagram_w', 10: 'instagram_b', 11: 'twitter_w', 12: 'twitter_b', 13: 'pinterest_w', 14: 'pinterest_b', 15: 'secured_pay', 16: 'delivery', 17: 'customer_serv', 18: 'returns', 19: 'faq_cmd', 20: 'faq_size', 21: 'faq_account', 22: 'faq_collect', 23: 'wish_white', 24: 'customer', 25: 'home', 26: 'locator', 27: 'message', 28: 'message_open', 29: 'arrow'));
}