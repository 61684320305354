form#formCandidat {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 98%;
    max-width: 689px;
    margin: 0 auto;
    padding-bottom: 0;

    .input_container {
        width: 49%;
        height: 48px;
        margin-bottom: 25px;

        &:first-child {
            width: 100%;
        }

        input {
            width: 100%;
            height: 100%;
            border: 1px solid #e8e8e8;
            padding: 0 1rem;
            box-sizing: border-box;

            &#candidatPrenom {
                text-transform: capitalize;
            }

            &#candidatNom {
                text-transform: uppercase;

                &::placeholder {
                    text-transform: initial;
                }
            }

            &#candidatTel {
                padding: 0 1rem 0 3.5rem;
            }

            &.inputErr {
                border-color: red;
            }
        }

        .intl-tel-input {
            height: 100%;
        }
    }

    .bloc_file {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 10px;

        .bloc_cv,
        .bloc_motivation {
            width: 49%;
        }

        .upload {
            position: relative;
            width: 100%;
            height: 48px;
            margin-bottom: .5rem;
        }

        label.ajout_pj_label {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            border: 1px solid $primaryColor;
            font-size: 18px;
            font-weight: 500;
            text-transform: uppercase;
            box-sizing: border-box;
            cursor: pointer;
            text-align: center;
            @media screen and(max-width: 1400px) {
                font-size: 16px;
            }

            &+input {
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
            }
        }

        .file_msg {
            font-size: 14px;
            color: #919191;
        }

        .inputErr {
            label.ajout_pj_label {
                border: 1px solid red;
                color: red;
            }

            +.file_msg {
                color: red;
            }
        }

        .recruitmentMessage {
            width: 100%;
            margin: 35px 0;
            font-size: 15px;
            line-height: 1.2;
        }

        .form_submit {
            width: 260px;
            height: 50px;

            input,
            .loader {
                width: 100%;
                height: 100%;
            }
        }
    }
}

.wrapperContentFaqForm .wrap_content_right.recruitmentRight {
    background: transparent url(../img/bg_recrutement.jpg) no-repeat left top;
    background-size: 100% auto;

    @media screen and(max-width: 1400px) {
        background-size: cover;
        background-position: center;
    }
}