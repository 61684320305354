body.cart,
body.checkout {
    .main_ariane {
        display: none;
    }

    &:not(.step_1) {
        padding-top: 0;

        header {
            display: none;
        }

        #cartLogo {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 118px;
            font-size: 0;

            div {
                width: 269px;
                height: 37px;
                background: transparent url(../svg/manoush.svg) no-repeat center;
                background-size: contain;
            }
        }
    }

    &.step_1 nav.tunnel_track {
        margin: 32px auto 74px;
    }
    &.step_1 .elem_tunnel.right_side #cart_total {
        .bill_line.total {
            margin-top: 0;
            padding-top: 0;
            border-top: none;
        }
    }
}

nav.tunnel_track {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 655px;
    margin: 0 auto 74px;
    height: 45px;

    .tunnel_step {
        font-size: 17px;
        font-weight: 500;
        color: $bgPrimaryBtn;
        text-transform: uppercase;
        text-decoration: none;
        letter-spacing: .02rem;

        &.on {
            opacity: .5;
        }
    }
}

.wrapper_tunnel {
    #outsite_ue_notice {
        max-width: 1209px;
        width: 91.6%;
        margin: 0 auto 2rem;
        font-size: 14px;
        font-weight: 500;
        text-align: left;
    }
}

.wrapper_panier {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 98%;
    max-width: 1280px;
    margin: 0 auto;

    .left_side,
    .bank_left_side {
        position: relative;
        width: 65%;
        max-width: 824px;
    }

    .right_side {
        width: 34%;
        max-width: 369px;

        .reinsuranceMarquee  {
            padding: 28px 0 40px;
            @media screen and(max-width: 1024px) {
                padding: 45px 0 50px;
            }

            .swiper-slide {
                width: fit-content !important;
            }
        }
    }
}

.precoBasket {
    position: absolute;
    top: -30px;
    font-size: 15px;

    p {
        display: flex;
        align-items: center;
        height: 19px;

        &:before {
            display: flex;
            justify-content: center;
            align-items: center;
            content: 'i';
            font-family: sans-serif;
            font-size: 13px;
            color: white;
            width: 19px;
            height: 19px;
            background-color: red;
            border-radius: 50%;
            margin-right: .5rem;
        }
    }
}

.cart_main_title {
    font-size: 25px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: .04rem;

    &.basket {
        position: absolute;
        top: 22px;
        left: 27px;
    }

    &.wishlist {
        margin-bottom: 26px;

        &:before {
            content: '';
            display: inline-flex;
            width: 22px;
            height: 20px;
            background: transparent url(../img/wish_basket.gif) no-repeat center;
            background-size: contain;
            margin-right: 8px;
        }
    }

    span {
        font-size: 18px;
        font-weight: 400;
        text-transform: initial;
        letter-spacing: 0;
    }
}

.dropdown_cart {
    display: none;
    width: 100%;
    margin-bottom: 27px;

    &.activPreorder {
        display: block;

        h3 {
            font-size: 18px;
            line-height: 15px;
            margin-bottom: 14px;
        }
    }

    a {
        font-size: 14px;
        color: $bgPrimaryBtn;
        text-decoration: none;
        line-height: 1.2;
        margin-bottom: 27px;
        cursor: default;
    }

    h3 {
        width: 100%;
        font-size: 15px;
        font-weight: 500;
        margin-bottom: .4rem;
    }

    .precoSubtitle {
        font-style: italic;
    }
}

.cart_main_table {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;

    .empty_cart {
        padding-bottom: 27px;
    }

    .line_ssTotal {
        display: none;
    }

    &:not(.wishlist) {
        background-color: $pink_light;
        padding: 70px 27px 0;
        margin-bottom: 27px;
        box-sizing: border-box;
    }

    &.wishlist {
        .wrapper_cart_product_desc {
            margin-bottom: 0;

            .wrap_titre_prix {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 50px;
            }

            .cart_product_title,
            .wrapper_cart_product_price {
                width: 100%;
            }
        }
    }
}

.cart_product_line {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    width: 50%;
    margin-bottom: 27px;
    position: relative;

    @media screen and(max-width: 1280px) {
        width: 100%;
    }

    .dragHandle,
    .dragOverlay,
    .product_towish,
    .cart_product_sizecol.quantity,
    .cart_product_brand,
    .product_quantity_change,
    .line_product_desc_preorder {
        display: none;
    }

    .cart_product_pic {
        position: relative;
        width: 171px;

        .eclat_rect {
            position: absolute;
            top: 0;
            left:0;
            display: none;
        }

        img.cart_product_img {
            width: 100%;
            height: auto;
            vertical-align: top;
        }
    }

    .cart_product_desc {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        width: calc(100% - 171px);
        padding-left: 27px;
        box-sizing: border-box;
    }

    .eclat_preorder {
        display: flex;
        position: absolute;
        top: 0;
        left: auto;
    }
}

.wrapper_cart_product_desc {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 50px;

    a.product_mod {
        color: $bgPrimaryBtn;
        text-decoration: none;
    }

    .wrap_titre_prix {
        width: 100%;
    }

    .cart_product_title,
    .cart_product_subtitle {
        font-size: 15px;
    }

    .cart_product_subtitle {
        display: none;
    }

    .wrapper_cart_product_price {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        margin: 7px 0 10px;

        .pricetag {
            font-size: 14px;

        }

        .eclat_rond {
            width: 100%;
            margin-top: 10px;
            display: none;
        }
    }

    .cart_product_sizecol {
        label {
            display: none;
        }

        &.size,
        &.color  {
            display: flex;
            align-items: center;
        }

        &.size {
            justify-content: center;
            width: 38px;
            height: 19px;
            background-color: $bgPrimaryBtn;
            color: white;
            font-size: 14px;
            font-weight: 300;
            letter-spacing: .02rem;
            white-space: nowrap;
        }

        &.color  {
            justify-content: flex-start;
            font-size: 15px;
            margin-left: 15px;
            max-width: 130px;
            .color_bullet {
                display: flex !important;
                justify-content: center;
                align-items: center;
                width: 18px;
                min-width: 18px;
                height: 18px;
                border-radius: 50%;
                border: 1px solid $bgPrimaryBtn;
                box-shadow: inset 0 0 0 2px $pink_light;
                margin-right: .5rem;
                background-size: cover!important;

                &.wish {
                    box-shadow: inset 0 0 0 2px white;
                }
            }
        }
    }
}

#tunnel_right_col .wrapper_cart_product_desc .cart_product_sizecol.color {
    @media screen and(orientation: portrait) {
        width: 100%;
        margin-left: 0;
        white-space: nowrap;
        margin-top: .5rem;
    }
}

.wrapper_content_cmd_detail .wrapper_cart_product_desc .cart_product_sizecol.color .color_bullet {
    box-shadow: inset 0 0 0 2px white;
}

.cart_product_modify {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    a {
        font-size: 15px;
        font-weight: 500;
        letter-spacing: .02rem;
        color: $bgPrimaryBtn;

        &.product_mod,
        &.wishToBasket {
            order: 2;
            text-transform: initial;
            border: 0;
            text-decoration: underline;
            text-underline-position: from-font;

            &:hover {
                text-decoration: none;
            }
        }

        &.product_del,
        &.remove_wish {
            order: 1;
            font-size: 0;
            text-decoration: none;
            display: flex;
            width: 24px;
            height: 24px;
            @include bgIconPath('../svg/spritesheet.svg');
            @include bgIconCoord(6, 6, 6);
            margin: 0 0 .5rem -5px;
        }
    }
}

#cart_total {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 22px 22px 0px 22px;
    background-color: $blue_light;

    .recap_cart_title {
        order: 1;
        font-size: 23px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: .04rem;
        margin-bottom: 28px;
    }

    .bill_line {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 15px;
        margin-bottom: 15px;

        &.total_produit_dropdown {
            display: none;
            flex-direction: column;
            order: 3;

            .cart_product_line {
                width: 100%;
                align-items: center;
                margin-bottom: 10px;

                .cart_product_pic {
                    width: 72px;
                }

                .cart_product_desc {
                    width: calc(100% - 72px);
                    justify-content: center;
                }

                .wrapper_cart_product_desc {
                    margin-bottom: 0;
                }

                .cart_product_modify {
                    display: none;
                }
            }
        }

        &.total_produit {
            order: 2;
        }

        &.discount {
            order: 4;
            > label {
                font-weight: 600;
            }
            .error_text {
                display: none;
            }

            &.hide_shippingfees{
                display: none;
            }
        }

        &.total {
            order: 6;
            font-size: 22px;
            margin-bottom: 0;
            margin-top: 10px;
            padding-top: 20px;
            border-top: 1px solid #000b21;
        }

        &.coupon {
            order: 5;
            height: 48px;
            margin: 10px 0 20px;
            padding-bottom: 25px;
            border-bottom: 1px solid $bgPrimaryBtn;
        }

        &.promo {
            order: 3;
        }

        &.used_cp {
            order: 7;
            margin: 2rem 0 0;
        }
        #fdp_txt {
            font-weight: 600;
        }
    }

    .total_produit.toggle {
        cursor: pointer;

        label {
            cursor: pointer;

            &:after {
                content: '+';
                margin-left: .5rem;
            }
        }

        &.open label:after {
            content: '-';
        }
    }

    .formPanier_container {
        order: 10;
        margin-left: -22px;
        margin-top: 20px;
        width: calc(100% + 44px);
        .form_submit {
            height: 50px;
        }
        .button,
        .loader {
            width: 100%;
            height: 50px;
        }
    }

    .upselling_alma {
        display: flex !important;
        flex-wrap: wrap;
        justify-content: center;
        order: 7;
        background: #68e2ff;
        margin-left: -22px;
        width: calc(100% + 44px);
        box-sizing: border-box;
        padding: 1rem .5rem;
        position: relative;
        top: 20px;
        opacity: 0;

        p {
            font-size: 13px;
            letter-spacing: 0.015em;
            font-family: $primaryFont;
            font-weight: 500;
            text-align: center;
            width: 100%;

            strong {
                font-weight: 600;
                font-size: 15px;
            }
        }

    }

    progress {
        width: calc(100% - 12px);
        max-width: 323px;
        margin: 1rem auto 0;
        height: 3px;
        position: relative;
        color: #000;
        background-color: rgba(0,0,0,.2);

        &::-webkit-progress-bar {background-color:  rgba(0,0,0,0); width: 100%;}
        &::-webkit-progress-value {background-color:  #000 !important;}
        &::-moz-progress-bar {background-color:  #000 !important;}
    }

    #formPanier {
        display: flex;
        flex-direction: column;

        div {
            order: 1;
        }

        .upselling_alma {
            order: 2;
            width: 100%;
            margin: 0 !important;
            top: 0;
        }

        .form_submit {
            order: 3;
        }
    }
}

body.cart.step_2 {
    .w-input-container.checkCgv {
        padding-bottom: 5%;
        background: $blue_light;
        .shipment_cgv_link {
            width: 90%;
            margin: 0 auto;
        }
        .w-checkbox-input {
            height: auto;

            .w-input-element+.w-input-label {
                align-items: flex-start;
                color: #333;

                a {
                    color: #333;
                }

                span {
                    flex: 1;
                }
            }
        }
    }

    .upselling_alma {
        margin-bottom: 1rem;
    }
}

div#err_cgv_compulsory {
    color: red;
    font-family: Arial, sans-serif;
    font-size: .75rem;
    padding: .5rem 1.5rem;
    margin-bottom: .5rem;
    @media screen and(max-width: 1024px) {
        font-size: 12px;
    }
}

/**
 * DELIVERY
 */
.wrapper_mes_adresses {
    width: 100%;
    .w-input-group.addrAutoComplete > legend {
        font-weight: 600;
    }
}

.edit_adresse {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .itm_back_btn {
        display: none;
    }

    .w-group-label {
        font-family: $primaryFont;
        font-size: 20px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: .04rem;
    }

    .w-checkbox-input .w-input-element,
    .w-radio-input .w-input-element {
        &+.w-input-label,
        &+.w-input-label {
            font-size: 14px;
            color: $bgPrimaryBtn;

            &:before {
                border: 1px solid #c5c5c5;
                box-shadow: inset 0 0 0 3px #fff;
            }
        }

        &:checked .w-input-label:before {
            box-shadow: inset 0 0 0 3px #fff;
        }
    }

    .w-input-element:not([type=radio]):not([type=checkbox]),
    .w-nonempty:not(.no-label) .w-input-element:not([type=radio]):not([type=checkbox]):not(textarea),
    .w-nonempty:not(.no-label) input[type=text].w-input-element,
    input[type=email].w-input-element {
        height: 48px;
        box-sizing: border-box;
        border-color: #c5c5c5;
    }

    .w-input.w-tel-input .selected-flag {
        border: 0;
        background-color: white;
        left: 1px;

        &:after {
            border-left: 0.3em solid transparent;
        }
    }

    .w-tel-input .w-input-element:not([type=radio]):not([type=checkbox]),
    .w-tel-input.w-nonempty:not(.no-label) .w-input-element:not([type=radio]):not([type=checkbox]):not(textarea){
        padding-left: 3.8rem;
    }

    .w-input-label {
        color: #777;
    }

    .w-form-line .noaddr {
        font-size: 13px;
        text-underline-position: under;
    }
}

.choix_type_adresse {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 12px;

    .type_adresse {
        font-size: 13px;
        text-transform: uppercase;
        letter-spacing: .01rem;
        color: $bgPrimaryBtn;
        cursor: pointer;

        &.ami {
            display: none;
        }

        &+.type_adresse {
            margin-left: 40px;
        }

        &.actif {
            opacity: .5;
            cursor: default;
        }
    }
}

/**
 * DELIVERY CHOICE
 */
.bloc_livraison {
    position: relative;
    border: 1px solid #c5c5c5;
    width: calc(100% - 33px);
    margin: 0 0 56px 33px;
    padding: 0 27px 40px;
    box-sizing: border-box;
    transition: all .1s ease-in-out;

    input[type="radio"] {
        display: none;
    }

    .checkbox {
        position: absolute;
        top: 50%;
        left: -33px;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        background-color: $bgPrimaryBtn;
        box-shadow: inset 0 0 0 10px white;
        border-radius: 50%;
        border: 1px solid #c5c5c5;
        transition: all .1s ease-in-out;
        cursor: pointer;
    }

    &:hover,
    &.active {
        border-color: $bgPrimaryBtn;

        .checkbox {
            box-shadow: inset 0 0 0 2px white;
        }
    }

    .title_type_livraison {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 316px;
        height: 25px;
        background-color: white;
        font-size: 20px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: .02rem;
        margin: -15px auto 23px;

        span.innerSpan {
            display: block;
            width: 100%;
            font-size: 14px;
            font-weight: 400;
            text-align: center;
            letter-spacing: 0;
            text-transform: initial;
            margin-top: 5px;
        }
    }

    .transporteur_info {
        span {
            font-size: 0;
            margin-bottom: 24px;
        }
    }

    &.transporteur_colissimo .transporteur_info span {
        display: none;
        width: 117px;
        height: 30px;
        background: transparent url(../img/transporteurs/colissimo.png) no-repeat center;
        background-size: contain;
    }

    &.transporteur_UPS .transporteur_info span {
        display: none;
        width: 25px;
        height: 30px;
        background: transparent url(../img/transporteurs/ups.png) no-repeat center;
        background-size: contain;
    }

    &.transporteur_Retrait .title_type_livraison span.innerSpan,
    &.transporteur_Colissimo.relais .title_type_livraison span.innerSpan {
        display: none;
    }

    .choix_delivery {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }


    .left_delivery {
        font-size: 15px;
        line-height: 1.4;

        .civilite,
        .shop_name {
            font-weight: 500;
            text-transform: uppercase;
        }

        .shop_name > i {
            display: none;
        }

        .adresse {
            margin-bottom: 10px;
        }
    }

    a.show_modif_info {
        display: initial;
        border: 0;
        font-size: 15px;
        font-weight: 500;
        text-transform: initial;
        text-decoration: underline;
        text-underline-position: from-font;
    }

    .transporteur_delay {
        font-weight: 600;
        margin-top: 10px;
    }

    .right_delivery span {
        font-size: 1.375rem;
    }

    .delivery_form_title {
        font-size: 14px;
        text-transform: uppercase;
        margin: 30px 0 10px;
    }

    .hidden_elem_liv {
        .w-dropdown {
            border-radius: 0;
            box-sizing: border-box;

            &:after {
                right: 1rem;
            }
        }

        >.wrapper_select_adr {
            margin-bottom: 0;

            .w-input-element:not([type=radio]):not([type=checkbox]) {
                height: 48px;
                box-sizing: border-box;
            }
        }
    }

    .w-input.w-tel-input {
        .selected-flag {
            background-color: white;
            border: 0;
            left: 1px;
        }

        .w-input-element:not([type=radio]):not([type=checkbox]) {
            padding-left: 3.875rem;
        }
    }

    .w-input-element:not([type=radio]):not([type=checkbox]).autocomplete_addr_map {
        height: 48px;
        box-sizing: border-box;

        background: transparent url(../img/maps/marker-12.png) no-repeat 98% center;
        background-size: 16px auto;
    }

    .displayed_result {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 18px;

        #map_canvas {
            width: 69%;
            max-width: 505px;
            height: 390px;

            .button {
                font-size: 12px;
                text-transform: initial;
                text-decoration: underline;
                text-underline-position: under;
                border: 0;
                margin: 1rem 0;
            }
        }

        .sidebar-right {
            width: 30%;
            max-height: 390px;

            table.details {
                width: 160px;
                margin-left: 8%;

                tr {
                    td {
                        padding: 20px 0 24px;
                        font-size: 15px;
                        line-height: 1.2;
                        border-bottom: 1px solid #e5e6e8;

                        a {
                            color: $bgPrimaryBtn;
                            text-decoration: none;
                        }
                    }

                    &:last-of-type td {
                        border-bottom: 0;
                    }
                }

                .distance {
                    display: none;
                }

                .name {
                    font-weight: 500;
                    text-transform: uppercase;
                    margin-bottom: 5px;
                }

                .address {
                    margin-bottom: 10px;
                }

                a.relayLink {
                    text-decoration: underline;
                    text-underline-position: from-font;
                }
            }
        }
    }
}

/**
 * CHECkOUT
 */
body.checkout {
    .reinsuranceMarquee,
    .bloc_question {
        display: none;
    }

    .wrapper_bloc_banque {
        position: relative;
        border: 1px solid #c5c5c5;
        width: calc(100% - 33px);
        margin: 0 0 56px 33px;
        padding: 0 27px 40px;
        box-sizing: border-box;
        transition: all .1s ease-in-out;
        cursor: pointer;

        .radio {
            position: absolute;
            top: 50%;
            left: -33px;
            transform: translateY(-50%);
            width: 20px;
            height: 20px;
            background-color: $bgPrimaryBtn;
            box-shadow: inset 0 0 0 10px white;
            border-radius: 50%;
            border: 1px solid #c5c5c5;
            transition: all .1s ease-in-out;
            cursor: pointer;
        }

        &:hover,
        &.actif {
            border-color: $bgPrimaryBtn;

            .radio {
                box-shadow: inset 0 0 0 2px white;
            }
        }

        &.actif {
            cursor: default;
        }
    }

    .bank_title {
        position: absolute;
        top: -18px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 460px;
        height: 25px;
        background-color: white;
        font-size: 20px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: .02rem;
    }

    .bank_subtitle {
        font-size: 14px;

        img {
            height: 12px;
            width: auto;
            vertical-align: baseline;
        }
    }

    .wrapper_bloc_banque {
        &.paypal {
            .bloc_bank_contents {
                justify-content: center;
            }

            .bank_subtitle {
                text-align: center;

                    &.loader_paypal {
                        &::before {
                            background-size: auto 30% !important;
                            margin-left: 0!important;
                        }

                        span {
                            display: none;
                        }
                    }

                strong {
                    display: none;
                }

                span {
                    display: inline-flex;
                    justify-content: flex-start;
                    align-items: center;
                    height: 50px;
                    padding: 0 37px;
                    font-size: 18px;
                    font-weight: 500;
                    text-transform: uppercase;
                    letter-spacing: .02rem;
                    background-color: #f7c64c;

                    &:after {
                        content: '';
                        display: block;
                        width: 70px;
                        height: 20px;
                        background: transparent url(../img/paypal.png) no-repeat center;
                        background-size: contain;
                        margin-left: .5rem;
                    }
                }
            }
        }

        &.alma .bloc_bank_contents {
            width: fit-content;
            height: 50px;
            margin: 0 auto;
            padding: 0 37px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #FFF9F1;

            &:after {
                content: '';
                display: block;
                width: 67px;
                height: 40px;
                background: transparent url(../banque/logo/picto_alma.png) no-repeat center;
                background-size: contain;
                margin-left: .5rem;
            }

            .bank_subtitle {
                strong {
                    display: none;
                }

                span {
                    font-size: 18px;
                    font-weight: 500;
                    text-transform: uppercase;
                    letter-spacing: .02rem;
                }
            }
        }

        input#inputPAYPAL,
        input#inputAlma {
            cursor: pointer;
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
        }
    }

    .total_transaction {
        font-size: 16px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: .04rem;
        margin: 18px 0 25px;
    }

    .cards-wrapper {
        margin-bottom: 22px;

        img {
            height: 25px;
            width: auto;
            vertical-align: bottom;
        }
    }

    .wrapper_payment {
        display: flex;
        flex-direction: column;
        padding: 0 40px 28px 40px;
        margin-bottom: 22px;
        background-color: $blue_high;

        .title {
            font-size: 20px;
            font-weight: 500;
            text-transform: uppercase;
            letter-spacing: .02rem;
            margin: 30px 0 15px;
        }

        .address {
            position: relative;
            font-size: 14px;
            line-height: 1.3;
        }

        .payment_addr_wrapper {
            position: relative;
        }

        a.address_mod {
            position: absolute;
            right: 0;
            bottom: 0;
            font-size: 14px;
            color: $bgPrimaryBtn;
            text-decoration: underline;
            text-underline-position: from-font;
            line-height: 1.3;

            &:hover {
                text-decoration: none;
            }
        }
    }

    #wrapper_payement_secur {
        margin-top: 50px;
        h3 {
            font-size: 18px;
            font-weight: 500;
            text-transform: uppercase;
            letter-spacing: .02rem;
            margin-bottom: 12px;
        }

        p {
            font-size: 14px;
            line-height: 1.3;
            text-align: justify;

            img {
                height: 12px;
                width: auto;
                vertical-align: baseline;
            }
        }
    }
    #cart_total {
        padding-bottom: 1rem;

        .upselling_alma {
            display: none !important;
        }
    }
}

.wrapper_basket_content .bank_left_side .wrapper_bloc_banque {
    margin: 0 0 56px 33px;
    padding: 45px 27px;

    &.credit_card {
        padding: 60px 27px 45px;
    }

    #flex {
        min-height: 342px;
    }
}

.banque_erreur_txt {
    width: calc(100% - 33px);
    margin: 0 0 20px 33px;
    font-size: 15px;
    line-height: 1.4;

    p.rose {
        font-size: 16px;
        font-weight: 500;
    }

    a {
        color: $bgPrimaryBtn;
        text-decoration: underline;
        text-underline-position: from-font;

        &:hover {
            text-decoration: none;
        }
    }
}

.txt_recommencer_paiement {
    width: calc(100% - 33px);
    margin: 0 0 40px 33px;
    font-size: 15px;
    font-weight: 500;
}

/**
 * CHECKOUT VALIDATED
 */
body.bankThanks {
    nav.tunnel_track {
        margin: 0 auto 60px;
    }
}

@keyframes cycle {
    0% {
        color: $pink;
    }

    24% {
        color: $pink;
    }

    25% {
        color: $green;
    }

    49% {
        color: $green;
    }

    50% {
        color: $yellow;
    }

    74% {
        color: $yellow;
    }

    75% {
        color: $blue;
    }

    99% {
        color: $blue;
    }
}
.thanksWrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    width: 98%;
    max-width: 1280px;
    margin: 0 auto;
    padding-top: 155px;

    .bankThanksTop {
        width: 65%;
        max-width: 824px;
        margin: 0;

        h1 {
            position: absolute;
            top: 0;
            left: 0;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            text-align: left;
            font-size: 90px;
            color: $pink;
            font-weight: 500;
            text-transform: uppercase;
            letter-spacing: .05rem;
            margin-left: -.2rem;
            animation: cycle 2s infinite;

            &::before {
                display: none;
            }

            span {
                font-size: 14px;
                font-weight: 400;
                color: $bgPrimaryBtn;
                line-height: 1.4;
                text-transform: initial;
                letter-spacing: .02rem;
                margin: 5px 0 0 .3rem;
            }
        }

        #around_me_map {
            height: 614px;
            margin: 0;
        }
    }

    .thanksRecap {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        width: 34%;
        max-width: 369px;

        .thanksLeft,
        .thanksRight {
            width: 100%;
        }

        .thanksLeft {
            position: relative;
            order: 2;

            .thanksLeftWrapper {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
            }

            .seeOrderEvo,
            .createPwdTxt {
                display: inline;
                font-size: 15px;
                line-height: 1.2;
                margin-bottom: 0;

                a {
                    color: $bgPrimaryBtn;
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                        text-underline-position: from-font;
                    }
                }
            }

            .seeOrderEvo {
                order: 1;
                margin-bottom: 1.5rem;
            }

            .createPwdTxt {
                order: 2;
            }

            .thanksTrust {
                order: 4;
                width: 100%;
                font-size: 15px;
                text-align: center;
                margin: 1.5rem 0 0 0;
            }

            .bankThanksBtn {
                order: 3;
            }
        }

        .thanksRight {
            order: 1;
            background-color: $blue_high;
            padding: 35px 42px;

            p {
                display: flex;
                flex-wrap: wrap;
                font-size: 15px;
                white-space: nowrap;
                line-height: 1.2;
                margin-bottom: 5px;
                letter-spacing: .03rem;

                label {
                    font-weight: 500;
                    margin-right: .2rem;

                    &:after {
                        content: ' :';
                    }
                }

                &.txt_thanks_transaction {
                    font-size: 14px;
                    letter-spacing: .01rem;
                    text-transform: initial;
                    margin-top: 24px;
                }
            }
        }
    }

    .w-newpass-form {
        margin-top: 20px;

        .w-form-line {
            margin-bottom: 25px;
        }
    }

    .w-input-element:not([type=radio]):not([type=checkbox]),
    .w-nonempty:not(.no-label) .w-input-element:not([type=radio]):not([type=checkbox]):not(textarea),
    .w-nonempty:not(.no-label) input[type=text].w-input-element,
    input[type=email].w-input-element {
        height: 48px;
        box-sizing: border-box;
        border-color: #c5c5c5;
    }

    .w-input-label {
        color: #777;
    }

    .bankThanksBtn {
        width: 100%;
        height: 50px;
    }
}

/**
 * BASKET FAQ
 */

#title_question_basket,
#title_other_question {
    font-size: 17px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: .03rem;
    margin-bottom: 15px;
}

article.faq {
    .intitule_question {
        font-size: 15px;
        margin-bottom: 10px;
    }

    .texte_question {
        font-size: 13px;
        padding: 0 0 1rem .5rem;
        line-height: 1.2;
    }
}

.another_question {
    #title_other_question {
        margin-top: 40px;
    }

    .other_question {
        font-size: 15px;
        line-height: 1.2;
        margin-bottom: 10px;
        a {
            text-decoration: none;
            color: $bgPrimaryBtn;
        }
        span {
            display: block;
        }
    }
}

/**
 * BASKET FAQ -- TEMPORARY STYLE UNTIL TRAD ARE AVAILABLE
 */

#title_question_basket {
    display: none;
}

 article.faq {
    display: none;
}

.another_question {
    #title_other_question {
        margin-top: 0;
    }
}