body.footer_contact,
body.contact_faq {
    .champs_requis {
        display: none;
    }

    #recaptcha {
        margin-bottom: 1.5rem;
    }

    #abox {
        .header_lightbox,
        .txt_alert {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
        }
    }
}

.wrapperContentFaqForm {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
    width: 98%;
    margin: 0 auto;

    .wrap_content_right {
        position: relative;
        width: 54%;
        max-width: 1006px;
        background: transparent url(../img/bg_contact.jpg) no-repeat left top;
        background-size: 100% auto;
        overflow: hidden;
        background-size: cover;
        background-position: center;

        @media screen and(max-width: 1600px) {
            width: 60%;
        }

        @media screen and(max-width: 1400px) {
            width: 100%;
            order: 1;
            margin: 0 auto;
        }

        .hiddenBg {
            width: auto;
            height: auto;
            vertical-align: top;
            opacity: 0;
        }

        .contact_text {
            font-family: $primaryFont;
            top: 0;
            bottom: 0;
            margin: auto;
            height: 100%;
            display: flex;
            align-items: center;

            span {
                font-size: 30px;
                font-weight: 400;
            }
        }

        div {
            position: absolute;
            top: 14%;
            left: 5%;
            width: 50%;
            box-sizing: border-box;
            font-size: 29px;
            letter-spacing: 0.02rem;
            font-style: italic;
            color: $white;
            line-height: 1.3;

            a {
                color: $white;
                text-decoration: none;
                white-space: nowrap;
            }

            span {
                display: block;
                font-size: 70px;
                text-transform: uppercase;
                font-weight: 500;
                letter-spacing: 0.08rem;
                font-style: normal;
                line-height: 1;
                margin-bottom: 4%;

                @media screen and(max-width: 1200px) {
                    font-size: 50px;
                }
            }
        }
    }

    .wrap_content_left {
        width: 46%;

        @media screen and(max-width: 1600px) {
            width: 38%;
        }

        @media screen and(max-width: 1400px) {
            width: 100%;
            order: 2;
        }

        #msg_success.success {
            span {
                color: $green;
                margin-left: 1rem;
                font-size: 1.5rem;

                @media only screen and (max-width: 1600px) {
                    margin-left:0;
                }
            }
        }

        .w-form-line {
            margin-bottom: 1.6rem;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        .w-lines-group .w-form-line {
            margin-bottom: 0;
        }

        .w-input-element:not([type='radio']):not([type='checkbox']),
        .w-nonempty:not(.no-label) .w-input-element:not([type='radio']):not([type='checkbox']):not(textarea),
        .w-nonempty:not(.no-label) input[type='text'].w-input-element,
        input[type='email'].w-input-element {
            height: 48px;
            border-radius: 0;
            box-sizing: border-box;
        }

        .w-dropdown {
            height: 48px;
            border-radius: 0;
            box-sizing: border-box;
        }

        textarea.w-input-element:not([type='radio']):not([type='checkbox']) {
            height: 96px;
            padding-top: 2rem;
        }

        .w-textarea.w-nonempty:not(.no-label) textarea.w-input-element {
            padding-top: 2rem;
        }

        .w-input.w-textarea .w-input-element:focus + .w-input-label {
            transform: translateY(100%);
        }

        .w-contact-form .file-input-row label {
            height: 50px !important;
        }

        .w-lines-group {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-wrap: wrap;
            margin: 0.5rem 0 1.5rem;

            .w-form-line {
                width: auto;

                + .w-form-line {
                    margin-left: 3rem;
                }
            }

            .w-line-label {
                width: auto;
                font-family: $primaryFont;
                font-size: 14px;
                color: $bgPrimaryBtn;
                margin-right: 0.5rem;
            }

            .w-input-label {
                font-family: $primaryFont;
                font-size: 14px;
                color: $bgPrimaryBtn;
            }

            a.optinLightboxContact {
                font-size: 14px;
                color: $primaryColor;
                text-decoration: underline;
                margin-left: 1rem;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        .w-fieldset-label {
            font-family: $primaryFont;
            font-size: 14px;
            color: $primaryColor;
            letter-spacing: 0;
            width: 100%;
        }
    }

    .titre_contact {
        width: 98%;
        max-width: 689px;
        margin: 0 auto;
        font-size: 90px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 0.1rem;
        padding-right: 0.5rem;
        margin-top: 1rem;
        margin-bottom: 2rem;

        @media screen and(max-width: 1600px) {
            font-size: 75px;
        }

        @media screen and(max-width: 1400px) {
            font-size: 90px;
        }
    }

    .w-contact-form,
    .wrapperErrors {
        width: 98%;
        max-width: 689px;
        margin: 0 auto;
        padding-bottom: 0;
    }

    .wrapperErrors {
        margin: 0 auto 50%;

        #message_mail .valid {
            color: $green;
        }
    }

    #mes_err_contact {
        .red {
            color: red;
            font-weight: 500;
            width: 98%;
            max-width: 689px;
            margin: 0 auto 1rem;
        }
    }

    .w-contact-form .file-list .rowList {
        margin-top: 0.5rem;

        span.uploadedFile {
            font-family: $primaryFont;
        }

        button.closePj {
            border: 0;
            background: transparent;

            &::before,
            &::after {
                width: 1px;
            }
        }
    }

    @media screen and(max-width: 1400px) {
        flex-direction: row;
        align-items: center;

        .wrap_content_left,
        .wrap_content_right {
            width: 50%;
        }

        .wrap_content_right {
            div {
                width: 100%;
                left: 0;
                padding: 1rem;
            }
        }

        .wrap_content_left {
            padding: 0 2rem;
        }

        .titre_contact {
            font-size: 60px;
        }
    }
}