.shad {
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0;
    transition: all .3s ease-in-out;

    &.actif {
        z-index: 100;
        opacity: .4;
        transition: all .3s ease-in-out;
    }

    &#shad_menu.actif {
        z-index: 15;
    }
}

.lightbox {
    position: fixed;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    //transition: all .3s ease-in-out;

    &.actif {
        opacity: 1;
        z-index: 105;
    }
}

.close_pop {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 14px;
    height: 14px;
    cursor: pointer;

    &:before,
    &:after {
        position: absolute;
        left: 7px;
        content: ' ';
        height: 14px;
        width: 1px;
        background-color: $bgPrimaryBtn;
    }

    &:before {
        transform: rotate(45deg);
    }

    &:after {
        transform: rotate(-45deg);
    }
}

/**
 * SUBSCRIPTION LIGHTBOX
 */
#exit_overlay_OVI {
    width: 392px;
    top: auto;
    right: .5rem;
    left: auto;
    bottom: .5rem;
    transform: none;
    background-color: $purple;

    .lightbox-text-only {
        width: 263px;
        margin: 0 auto;
        padding: 6rem 0 3rem;
    }

    .title_popup_inscription_home {
        font-size: 35px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: .04rem;
        line-height: 1;
    }

    .pop_txt_listing {
        font-size: 15px;
        font-style: italic;
        line-height: 1.2;
        margin: .5rem 0 1.2rem 0;

        &.pop_cgv_listing {
            font-size: 14px;
            font-style: normal;
            line-height: 1.2;
            margin-bottom: 0 !important;

            a {
                font-weight: 500;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    .w-input-element:not([type=radio]):not([type=checkbox]) {
        height: 48px;
        background-color: white;
        border-color: white;
        box-sizing: border-box;
    }

    .w-form-line-container {
        .w-form-line:nth-child(2) {
            //margin-top: -1.875rem;
        }
    }

    .w-form-line.w-submit {
        max-width: 100%;
    }

    .w-input-note.w-input-error {
        color: red;
    }

    #conf_inscription_news {
        .pop_txt_listing {
            font-style: normal;
        }

        button {
            width: 100%;
            height: 48px;
        }
    }
}

/**
 * COOKIES
 */
#cookiesLightbox {
    background: $blue_high url(../img/bg_cookies.png) no-repeat 82% center;
    
    @media screen and(max-width: 1440px) {
        background: $blue_high url(../img/bg_cookies.png) no-repeat 96% center;
    }

    section,
    .w-form-line.w-submit {
        width: 98%;
        max-width: 1286px;
        box-sizing: border-box;
    }

    section {
        padding-right: 295px;

        &:first-child {
            line-height: 1.2;

            a {
                color: $primaryColor;
            }
        }
    }

    .w-form-line.w-submit {
        padding-right: 145px;
        flex-direction: row-reverse;
        justify-content: flex-start;

        .w-btn-wrapper+.w-btn-wrapper {
            margin-left: 0;
            margin-right: 2rem;
        }
    }

    .w-checkbox-input .w-input-element:checked+.w-input-label::before {
        border: 1px solid $primaryColor;
        background-color: $primaryColor;
    }

    .w-submit-button#js_cookie_refuse {
        background-color: white;
        color: $primaryColor;

        +button.w-submit-button.w-loader {
            background: white url(../svg/three_dots_b.svg) center no-repeat;
            background-size: auto 25%;
        }
    }

    #conf_inscription_news {
        .pop_txt_listing {
            font-style: normal;
        }

        button {
            width: 100%;
            height: 48px;
        }
    }

    .cookiesLightbox_container {
        display: grid;
        grid-template-columns: repeat(7, 200px);
        grid-template-rows: 80px 40px auto 50px;
        justify-content: center;

        @media only screen and (max-width: 1670px) {
            grid-template-columns: repeat(7, auto);
            grid-template-rows: repeat(4, auto);
        }

        .cookiesLightbox_content {
            grid-column: 1 / 8;
        }

        .cookiesLightbox_preferences {
            grid-column: 1 / 8;
            grid-row: 3;

            .cookiesInfos {
                margin: 0 0 1rem 0;
            }
        }

        .cookiesLightbox_accept_btn {
            grid-column: 5;
            grid-row: 4;
            right: 40px;
            width: 200px;
        }

        .cookiesLightbox_refuse_btn {
            grid-column: 6;
            grid-row: 4;
            right: 10px;
            margin: 0;
            width: 200px;

            @media only screen and (max-width: 1670px) {
                margin-right: 50px;
            }
        }

        .cookiesLightbox_custom_btn {
            margin-left: 58px;

            .toggleCookiePrefs {
                background: none;
                border: none;
                color: $black;
                font-family: Arial, Helvetica, sans-serif;
                text-transform: none;
                font-size: 13px;
                justify-content: flex-start;
                letter-spacing: 0;
                height: auto;
            }

            .w-loader {
                display: none;
            }

            @media only screen and (max-width: 1670px) {
                margin-left: 10px;
            }
        }
    }
}

#modboxpromo,
#popup_savoir_plus,
.box_wishlist {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $purple;
}

/**
 * ALERT BOX
 */
#abox {
    width: 600px;
    height: 260px;
    background: $purple;

    .header_lightbox .txt_alert {
        font-size: 16px;
    }
}


#modboxpromo {
    width: 450px;
    height: 200px;

    .texte {
        margin-bottom: 20px;
    }

    .button {
        width: 100px;
        height: 50px;
    }
}

#popup_savoir_plus {
    width: 720px;
    height: 200px;

    #title_popup_savoir_plus,
    .light_title {
        font-size: 45px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: .02rem;
        line-height: 1;
        margin-bottom: 22px;
    }

    #content_savoir_plus,
    #bottom_savoir_plus {
        line-height: 1.2;
    }
}

//Ajout à la wishlist si pas connecté
.box_wishlist {
    background-color: $purple;
    text-align: center;
    font-weight: 500;
    color: $primaryColor;
    .txt_alert {
        font-size: 15px;
        line-height: 1.2;
    }
    #title_popup_savoir_plus,
    .light_title {
        font-size: 45px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: .02rem;
        line-height: 1;
        margin-bottom: 22px;
    }
    .btn_container {
        margin-top: 22px;
        button {
            width: 250px;
            height: 50px;
        }
    }
}

/**
 * SEND STORE INFOS
 */
 #ligthbox_send_store_information {
    background-color: white;
    width: 35rem;
    padding: 1.5rem;
    .header_lightbox {
        text-align: center;
    }
    .form_item {
        margin-top: 1rem;
        input {
            height: 3rem;
            margin-top: 0.25rem;
            margin-bottom: 1.5rem;
        }
    }
    .popup_title {
        font-size: 25px;
        text-transform: uppercase;
        font-weight: 500;
    }
    .form_submit .button {
        width: 50%;
        margin: 0 auto;
    }
    p.input_label {
        font-size: 14px;
        margin: 1rem 0 .5rem;
    }
    .w-form-line.w-submit {
        max-width: 100%;
        width: 50%;
        margin: 0 auto;
        .w-btn-wrapper {
            .w-submit-button {
                background: $bgPrimaryBtn;
                border: 1px solid $bgPrimaryBtn;
                color: white;
                cursor: pointer;
                transition: all .2s ease-out;
                &:hover {
                    background-color: white;
                    color: $bgPrimaryBtn;
                }
                &.w-loader {
                    background: white url(../svg/three_dots.svg) no-repeat center;
                    background-size: auto 28%;
                }
            }
        }
    }
    .w-sendshop-form p:not(.w-input-label) {
        font-size: 14px;
        margin: 1rem 0 1.5rem;
    }
    .w-input.w-email-input .w-input-element {
        width: 94%;
    }
}

/**
 * ORDER RETURN LIGHTBOX
 */
.popup_numero_retour {
    width: 820px;
    height: 670px;
    background-color: white;
    overflow-y: auto;
    padding: 0 1rem;

    .texte_num_retour,
    .recommandation {
        text-align: center;
    }

    .texte_num_retour {
        font-size: 45px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: .05rem;
        margin-top: 40px;
    }

    .recommandation {
        font-size: 14px;
        line-height: 1.2;
        margin: 20px 0 24px;
    }

    form#return_products_form {
        display: block;
        width: 640px;
        max-height: 340px;
        margin: 0 auto;
    }

    .prodWrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
    }

    .wrapper_cart_product_desc {
        width: calc(100% - 125px);
        margin-bottom: 0;
    }

    .productReturnListWrapper,
    .js-select-motif {
        display: flex;
        height: 145px;
        margin-bottom: 25px;
        box-sizing: border-box;
    }

    .productReturnListWrapper {
        justify-content: flex-start;
        align-items: center;
        width: 52%;
        border: 1px solid #e8e8e8;
    }

    .imgWrapper {
        width: 78px;
        margin: 0 20px 0 15px;

        img {
            width: 100%;
            height: auto;
            vertical-align: top;
        }
    }

    .quantite {
        display: none;
        width: 100%;
        font-size: 15px;
        margin-top: .5rem;
    }

    .js-select-motif {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 45%;
    }

    .qty_dropdown,
    .motif_dropdown {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;

        span {
            font-size: 13px;
            font-weight: 500;
            text-transform: uppercase;
        }

        .date_select {
            position: relative;
            width: 90%;
            border-bottom: 1px solid #777;

            &:after {
                content: '';
                border: solid #777;
                border-width: 0 1px 1px 0;
                display: inline-block;
                padding: .15rem;
                position: absolute;
                top: 50%;
                right: .5rem;
                transform: translateY(-50%) rotate(45deg);
            }
        }
    }

    .qty_dropdown {
        margin-bottom: 1rem;

        .date_select {
            width: auto;
            margin-left: .5rem;

            select {
                padding-right: 2rem;
            }
        }
    }

    .motif_dropdown {
        .date_select {
            margin-top: .8rem;
        }
    }

    select {
        appearance: none;
        border: 0;
        color: #777;
    }

    .divBtnPrintPdf {
        width: 260px;
        height: 50px;
        margin: 10px auto 0;

        .button {
            width: 100%;
            height: 100%;
        }
    }

    .wrapper_cart_product_desc .cart_product_sizecol.color .color_bullet {
        box-shadow: inset 0 0 0 2px white;
    }

    form + .btn_container{
        margin-bottom: 3rem;
    }
}

#zoomBox {
    width: 100%;
    height: 100%;
    transform: none;
    top: 0;
    left: 0;
    overflow-y: auto;

    &.actif {
        display: block !important;
    }
}


//RESERVATION EN MAGASIN
#e-resaBox {
    background: $white;
    width: 821px;
    height: 527px;
    padding-bottom: 20px;
    .close_pop {
        top: 25px;
        right: 25px;
    }
    #content_e-resaBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        .popup_title {
            font-size: 45px;
            color: $bgPrimaryBtn;
            margin: 40px 0px 30px 0px;
            text-transform: uppercase;
            font-weight: 500;
        }
        .popup_desc {
            display: none;
        }
        .steps_num {
            display: flex;
            align-items: center;
            .step_num {
                font-weight: 500;
                font-size: 1rem;
                line-height: 1;
                color: $bgPrimaryBtn;
                text-transform: uppercase;
                margin-right: 80px;
                font-size: 15px;
                &:last-child {
                    margin-right: 0;
                }
                &.actif{
                    opacity: .5;
                }
            }
        }
        .step {
            display: none;
            margin: 0 auto;
            overflow: auto;
            &.loading {
                .step {
                    opacity: 0;
                }
            }
            &.actif {
                display: block;
                .step_content {
                    opacity: 1;
                }
            }
            .step_content {
                opacity: 0;
                transition: opacity 0.2s ease-in;
                overflow: auto;
                scrollbar-color: #000b21 white;
                scrollbar-width: thin;

                &::-webkit-scrollbar {
                    width: 3px;
                    height: 5px;
                    background-color: white;
                    padding-left: 5px;
                }

                &::-webkit-scrollbar-thumb {
                    background:#000b21 ;
                }
                .eresa_container_img {
                    display: flex;
                    justify-content: center;
                    width: 90px;
                    min-width: 80px;
                    height: auto;
                    overflow: hidden;
                    img {
                        width: 120%;
                        height: auto;
                    }
                }
                .eresa_pdt_description {
                    margin-left: 2rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    .product_name,
                    .product_subname {
                        text-transform: uppercase;
                    }
                    .product_name {
                        font-size: 24 / 16 * 1rem;
                        line-height: 1.3;
                        font-weight: 600;
                    }
                    .product_subname {
                        font-size: 14 / 16 * 1rem;
                        line-height: 1.3;
                        margin: 0.25rem 0;
                    }
                    .item_price_wrapper {
                        margin: 0.25rem 0;
                        .item_price {
                            display: block;
                        }
                        .pricetag {
                            font-size: 18 / 16 * 1rem;
                            line-height: 1.3;
                            color: #272525;
                            & + .pricetag {
                                font-size: 13 / 16 * 1rem;
                                color: #acabab;
                                margin-left: 0.5rem;
                                &:after{
                                    background: #acabab;
                                }
                            }
                        }
                    }
                }
                .product_info {
                    .size-color-wrap {
                        display: flex;
                        flex-direction: column-reverse;
                    }
                    .ligne_form {
                        width: 100%;
                        margin: 0.25rem 0;
                        .prod_listes {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            width: 100%;
                            .titre_couleur,
                            .titre_taille {
                                font-size: 1rem;
                                line-height: 1.3;
                                text-transform: none;
                                margin-right: 10px;
                                font-weight: 300;
                                &:after {
                                    content: ":";
                                }
                            }
                            .choices_list{
                                display: flex;
                                align-items: center;
                                justify-content: flex-end;
                                .form_itm {
                                    label {
                                        font-weight: 600;
                                        font-size: 1rem;
                                        line-height: 1;
                                        &:before,
                                        &:after{
                                            display: none;
                                        }
                                    }
                                    [type=radio]:checked+label,
                                    [type=radio]:not(:checked)+label{
                                        margin: 0;
                                    }
                                    [type=radio]:checked+label:after,
                                    [type=radio]:not(:checked)+label:after{
                                        display: none;
                                    }
                                    &.color {
                                        margin-right: 0.5rem;
                                        label {
                                            display: none;
                                        }
                                        &:after {
                                            content: attr(data-color);
                                            text-transform: capitalize;
                                        }
                                    }
                                    &.size {
                                        margin-right: .5rem;
                                        label {
                                            color: #242424;
                                            margin-left: 0 0 0 15px;
                                            position: relative;
                                        }
                                        [type=radio]:checked,
                                        [type=radio]:not(:checked) {
                                            &.disabled{
                                                & + label{
                                                    color: #acabab;
                                                }
                                            }
                                            & + .no_stock{
                                                color: #acabab;
                                            }
                                        }
                                        [type=radio]+label {
                                            position: relative;
                                            border: 1px solid transparent;
                                            padding: 1px;
                                            min-width: 20px;
                                            height: 20px;
                                            span {
                                                width: 100%;
                                                text-align: center;
                                            }
                                        }
                                        [type=radio]:not(:checked)+label {
                                            opacity: 0.6;
                                        }
                                        [type=radio]:checked+label {
                                            opacity: 1;
                                            /* &:before {
                                                opacity: 1;
                                            } */
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .store {
                .store_info {
                    line-height: 1.2;
                    .distance_store {
                        display: none;
                    }
                    .name_store {
                        font-size: 15px;
                        text-transform: uppercase;
                        font-weight: 500;
                        margin-bottom: 3px;
                    }
                    .adresse_store {
                        font-size: 15px;
                        font-weight: 400;
                    }
                    .phone_store {
                        display: none;
                    }
                    .js_have_horaire_store {
                        font-weight: 400;
                        font-size: 15px;
                        text-decoration: underline;
                        text-underline-position: from-font;
                        color: $bgPrimaryBtn;
                        position: relative;
                        display: block;
                        margin-top: 10px;
                    }
                    .schedule_store {
                        font-weight: 400;
                        max-height: 0;
                        margin-top: 10px;
                        overflow: hidden;
                        font-size: 15px;
                        transition: max-height 0.3s ease-in;
                        &.actif {
                            max-height: 50px;
                        }
                    }
                    .opening_until {
                        font-size: 15px;
                        position: relative;
                        color: $bgPrimaryBtn;
                        display: flex;
                        span {
                            margin-right: 5px;
                            color: #1ca82c;
                            display: flex;
                            align-items: center;
                            font-weight: 500;
                            &:before {
                                content: "";
                                background-color: #1ca82c;
                                margin-right: 6px;
                                width: 8px;
                                height: 8px;
                                border-radius: 50%;
                                margin-top: 1px;
                            }
                        }
                        .txt_store_closed {
                            color: #ff0000;
                            &:before {
                                background-color: #ff0000;
                            }
                        }
                    }
                }
            }
            .js_store_report {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                padding: 0 15px;
                font-weight: 400;
                font-size: 1rem;
                text-align: center;
            }
            &#step1 {
                .step_content {
                    display: flex;
                    max-width: 90%;
                    padding: 2% 10%;
                    margin: 0 auto;
                    background: #f5f5f5;
                }
                .infos {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    margin: 2rem 0;
                    .form_submit {
                        width: 100%;
                        max-width: initial;
                        .button {
                            max-width: 290px;
                            margin: 2rem auto 0;
                        }
                    }
                }
            }
            &#step2 {
                .step_content {
                    margin-top: 2rem;
                }
                .w-input-container.country {
                    width: 35%;
                    .w-dropdown {
                        border-radius: 0;
                        height: 48px;
                        .w-input-element.inputForm {
                            height: unset;
                        }
                        &::after {
                            border: unset;
                            border: solid #d1d0d0;
                            border-width: 0 2px 2px 0;
                            transform: rotate(45deg);
                            width: 6px;
                            height: 6px;
                            top: 41%;
                            right: 1rem;
                        }
                    }
                }
                .w-input-container.adresse {
                    width: 65%;
                    .locator::before {
                        width: 25px;
                        height: 25px;
                        position: absolute;
                        right: 12px;
                    }
                    .w-address-input {
                        position: relative;
                        input {
                            box-sizing: border-box;
                            height: 48px;
                            padding: unset;
                            padding: 0 .85714em;
                            &::placeholder {
                                text-transform: capitalize;
                            }
                        }
                    }
                }
                .store_locator {
                    height: 272px;
                    width: 675px;
                    transition: opacity 0.3s ease-in;
                    opacity: 0;
                    visibility: hidden;

                    &.loaded {
                        opacity: 1;
                        visibility: visible;
                    }
                    .store {
                        display: flex;
                        justify-content: space-between;
                        padding: 20px 0px 15px 0;
                        margin-right: 30px;
                        border-bottom: 1px solid #eeeeee;
                    }
                    .form_submit {
                        max-width: unset;
                        margin: unset;
                        height: fit-content;
                        button {
                            background-color: $bgPrimaryBtn;
                            transition: background-color .2s linear;
                            width: 150px;
                            height: 50px;
                            span {
                                color: #FFF;
                            }
                        }
                        &:hover {
                            button {
                                background-color: #FFF;
                                span {
                                    color: $bgPrimaryBtn;
                                }
                            }
                        }
                        .quantity_store {
                            font-size: 12px;
                            margin-top: 12px;
                            text-align: center;
                            .article_disponible {
                                color: #f7499f;
                            }
                            &.no_stock {
                                .article_non_disponible {
                                    color: #ff0000;
                                }
                            }
                        }
                    }
                    .os-scrollbar-track {
                        width: 2px;
                        background: rgba(0, 11, 33, .19);
                        .os-scrollbar-handle {
                            width: 2px;
                            background: $bgPrimaryBtn;
                        }
                    }
                }
            }
            &#step3 {
                overflow: hidden;
                .step_content {
                    margin-top: 2rem;
                }
                #form_eresa {
                    .form-eresa-civility .w-input-label {
                        color: $bgPrimaryBtn;
                        font-size: 14px;
                    }
                    .form-eresa-civility + .w-form-line + .w-form-line {
                        z-index: 10;
                        align-items: flex-start;

                        .w-input-container:nth-child(2) {
                            text-align: left;

                            span.errormsgadd {
                                color: $leadbelcherMetal;
                                font-size: 12px;
                            }
                        }
                    }
                    .w-form-line {
                        text-align: center;
                        margin: .5rem auto 1.25rem;
                        .w-input-element {
                            box-sizing: border-box;
                            height: 45px;
                        }
                        .w-input-element[type=tel] {
                            padding-left: 65px;
                        }

                        .w-input-container {
                            +.w-input-container {
                                margin-left: 25px;
                            }
                            .w-tel-input {
                                .country-list {
                                    background-color: unset;
                                }
                                .selected-flag {
                                    background-color: #FFF;
                                    margin-left: 2px;
                                    border: none;
                                    border-right: solid 1px lightgray;
                                }
                            }
                        }
                        .w-input-label::before {
                            height: 15px;
                            width: 15px;
                        }
                        .w-input-note{
                            font-size: .65rem;
                            margin: .0333em 0 .66667em;
                            text-align: left;
                        }
                        &.optin > label{
                            color: $bgPrimaryBtn;
                            text-align: center;
                            justify-content: center;
                            font-size: .925rem;
                        }
                        &.optin {
                            display: flex;
                            margin-bottom: 0;
                            margin-top: 15px;
                            > label {
                                margin-right: 20px;
                            }
                            .checkbox-group {
                                margin-top: 0;
                                .w-input-element {
                                    height: unset;
                                }
                                .w-input-label {
                                    &::before {
                                        border-radius: 50%;
                                        height: 17px;
                                        width: 17px;
                                    }
                                    span {
                                        white-space: nowrap;
                                        color: $bgPrimaryBtn;
                                    }
                                }
                                .w-form-line {
                                    margin: 0.25rem auto;
                                    width: 100%;
                                    max-width: 199px;
                                }
                            }
                        }
                    }
                    .w-form-line.utilisation_condition {
                        justify-content: left;
                        font-family: "Arial";
                        margin-top: 20px;
                        span {
                            color: $bgPrimaryBtn;
                        }
                        .w-input-label::before {
                            border-radius: 0;
                        }
                        .w-radio-group span {
                            display: block;
                        }
                    }
                    .w-form-line.w-submit {
                        justify-content: center;
                        .w-submit-button {
                            width: 150px;
                        }
                    }
                    .mandatory {
                        font-size: 11px;
                        font-family: "Arial";
                    }
                    .infos {
                        margin-top: -5px;
                        font-size: 11px;
                        color: $bgPrimaryBtn;
                    }
                }
            }
            &#step4 {
                //LEFT PART STEP 4
                .eresa_recap {
                    display: flex;
                    align-items: center;
                    padding: 0 75px;
                    margin-top: 40px;
                    .eresa_choices {
                        display: flex;
                        width: 50%;
                        flex-direction: column-reverse;
                        border-right: solid 1px lightgray;
                        .eresa_prod {
                            display: flex;
                            align-items: center;
                            .eresa_prod_detail {
                                font-size: 15px;
                                .prod_title {
                                    margin-bottom: 5px;
                                }
                                .item_price_wrapper {
                                    margin-bottom: 10px;
                                }
                                .item_taille_color_container {
                                    display: flex;
                                    align-items: center;
                                    .titre_taille {
                                        justify-content: center;
                                        width: 38px;
                                        height: 19px;
                                        background-color: $bgPrimaryBtn;
                                        color: #fff;
                                        font-size: 14px;
                                        font-weight: 300;
                                        letter-spacing: .02rem;
                                        display: flex;
                                        align-items: center;
                                    }
                                    .titre_couleur .cart_product_sizecol{
                                        margin-left: 15px;
                                        display: flex;
                                        align-items: center;
                                        span {
                                            display: flex!important;
                                            justify-content: center;
                                            align-items: center;
                                            width: 18px;
                                            height: 18px;
                                            border-radius: 50%;
                                            border: 1px solid #000b21;
                                            box-shadow: inset 0 0 0 2px white;
                                            margin-right: .5rem;
                                        }
                                    }
                                }
                            }
                            #js_product_bookinstore_img {
                                width: 95px;
                                margin-right: 15px;
                            }
                        }
                        #js_eresa_store_choice {
                            .store_info {
                                .opening_until {
                                    display: none;
                                }
                                .schedule_store {
                                    margin-bottom: 10px;
                                }
                            }
                            .form_submit {
                                display: none;
                            }
                        }
                    }
                    //RIGHT PART STEP 4
                    .eresa_procedure {
                        width: 50%;
                        padding-left: 35px;
                        .eresa_procedure_title {
                            font-size: 14px;
                            font-weight: 500;
                            text-transform: uppercase;
                        }
                        .eresa_procedure_content {
                            font-size: 15px;
                            font-weight: 400;
                            margin: 10px 0px 10px 0px;
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
                .form_submit {
                    margin-top: 50px;
                    display: flex;
                    justify-content: center;
                    button {
                        width: 150px;
                        height: 50px;
                    }
                }
            }
        }
        &.step4_actif {
            .popup_header {
                margin-bottom: 1rem;
            }
            .popup_desc {
                position: absolute;
                text-align: center;
                width: 100%;
                left: 0;
                top: 95px;
            }
        }
    }
    #form_eresa{
        max-width: 675px;
        margin: 0 auto;
        .infos{
            font-family: "Arial";
            font-weight: 400;
            font-size: 11px;
            line-height: 14px;
            color: #242424;
            width: 100%;
        }
        .w-form-line{
            &.optin{
                display: block;
                margin-top: 15px;
                margin-bottom: 8px;
                > label{
                    display: flex;
                    font-family: $primaryFont;
                    font-weight: 400;
                    font-size: 0.8rem;
                    line-height: 1.3;
                    color: #242424;
                    margin: 0 10px 0 0;
                    position: relative;
                }
                .checkbox-group {
                    margin-top: 12px;
                    display: flex;
                    .w-form-line{
                        margin-bottom: 1rem;
                        width: 260px;
                        max-width: 100%;
                        > span {
                            font-family: $primaryFont;
                            font-weight: 400;
                            font-size: .8rem;
                            line-height: 1.3;
                            color: #242424;
                            margin: 0 1.3rem 0 0;
                            &:after {
                                content: ":";
                            }
                            .w-input-label{
                                &:before{
                                    border-radius: 0;
                                }
                                span {
                                    font-weight: 400;
                                    font-size: 14 / 16 * 1rem;
                                    white-space: nowrap;
                                }
                            }
                        }
                        &:first-child {
                            > span {
                                margin: 0 .75rem 0 0;
                            }
                        }
                    }
                }
            }
            &.utilisation_condition{
                justify-content: center;
                .w-input-label{
                    span{
                        font-weight: 400;
                        font-size: 13 / 16 * 1rem;
                        line-height: 1.3;
                        color: #242424;
                        margin-left: 15px;
                    }
                }
                span {
                    position: relative;
                }
                a {
                    font-weight: 400;
                    font-size: .875rem;
                    color: #272525;
                    white-space: nowrap;
                    line-height: 1.3;
                    text-decoration: underline;
                }
                .w-radio-input .w-input-element+.w-input-label::before,
                .w-radio-input .w-input-element+.w-input-label::after {
                    border-radius: 0;
                }
                .w-input-note {
                    text-align: center !important;
                }
            }
            &.w-submit{
                max-width: 240px;
                height: 50px;
                margin: 0 auto;
            }
        }
    }
}

/**
 * LOOKBOOK EXPRESS BUY
 */
#lightbox_achat_express {
    top: 0;
    right: 0;
    left: auto;
    transform: none;
    width: 86%;
    max-width: 1275px;
    background-color: white;

    .main_ariane,
    #btn_retour_product,
    .txt_content_container,
    .block_sizeguide,
    #bloc_reserve_instore,
    .reinsuranceMarquee,
    #gondole_derniers_articles_vus {
        display: none !important;
    }

    .product_main_wrapper {
        width: 100%;
        justify-content: space-between;
        align-items: stretch;
    }

    .product_left_image {
        width: 46.3%;
        margin-right: 0;
    }

    .product_info_wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 42%;
        margin: 0 auto;
        padding-top: 0;
    }

    .main_vis {
        opacity: 1 !important;
        width: 590px !important;
        height: auto !important;

        img {
            vertical-align: top;
        }
    }

    .swiper-button-prev,
    .swiper-button-next {
        display: none;
    }

    .product_info {
        position: relative;
        width: 100%;
    }

    .price_tag {span {
        margin-left: .4rem;
    }}

    #addToWishlistButtonContainer {
        height: 50px;
    }

    .more_details {
        position: absolute;
        bottom: -1rem;
        left: 50%;
        transform: translateX(-50%);
        font-size: 15px;
        font-weight: 500;
        color: $primaryColor;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}

//STORE LOC - Adresse non trouvée
.header_lightbox {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    .txt_alert {
        font-size: 21px;
    }
}

// Précommande Tunnel
#lightbox_preco {
    background: $white;
    width: 821px;
    height: 538px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

    .close_pop {
        background: transparent;
        border: none;
        top: 25px;
        right: 25px;

        span {
            display: none;
        }
    }

    .preco_title {
        font-weight: 500;
        font-size: 45px;
        text-transform: uppercase;
        margin-top: 35px;
        letter-spacing: 0.9px;
    }

    .t_lightbox_preco {
        font-size: 15px;
        margin: 14px 0 39px;
        letter-spacing: -0.35px;
    }

    .blocPrecoWrapper {
        display: flex;
        align-items: center;
        justify-self: center;
        margin-bottom: 28px;

        .bloc_lightbox_preco {
            display: flex;
            flex-direction: column;
            width: 260px;
            height: 245px;
            padding: 32px 23px;
            position: relative;
            box-sizing: border-box;
            border: solid 1px transparent;
            cursor: pointer;
            transition: all 0.3s ease-in;

            &:first-child {
                margin-right: 58px;
            }

            &:last-child {
                padding-right: 42px;
            }
            
            &.actif,
            &:hover {
                border: solid 1px $primaryColor;

                .ico {
                    background-color: $primaryColor;
                    border: solid 1px transparent;
                }
            }

            .ico {
                display: block;
                background: transparent;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                position: absolute;
                top: -11px;
                left: 50%;
                margin-left: -8px;
                border: solid 1px $primaryColor;
                transition: all 0.3s ease-in;
            }

            p {
                font-size: 15px;
                line-height: 20px;

                .title_liv {
                    display: block;
                    font-weight: 500;
                    font-size: 17px;
                    text-transform: uppercase;
                    letter-spacing: 0.4px;
                    margin-bottom: 10px;
                }

                .stock_from,
                .preco_from {
                    display: block;
                }
            }
        }
    }

    .btn_container {
        .button {
            display: block;
            background-color: $primaryColor;
            width: 370px;
            height: 50px;
            font-size: 18px;
            color: $white;
        }
    }

    .preco_fees {
        font-style: italic;
        font-size: 14px;
        margin-top: 20px;
        letter-spacing: -0.25px;
    }
}

// Version EN
body.en {
    // Précommande
    #lightbox_preco {
        .blocPrecoWrapper {
            .bloc_lightbox_preco {
                &:last-child {
                    padding-right: 23px;
                }
            }
        }
    }
}