.footer {
    max-width: 1920px;
    margin: 0 auto;

    //Barre de reassurance - Pictogrammes
    .reinsurance {
        display: flex;
        justify-content: space-between;
        padding: 70px 0 65px 0;
        max-width: 1280px;
        width: 98%;
        margin: 0 auto;

        section {
            text-align: center;
            display: flex;
            flex-direction: column;

            &::before {
                height: 75px;
                width: 75px;
            }

            a {
                color: $bgPrimaryBtn;
                text-decoration: none;
            }

            strong {
                text-transform: uppercase;
                font-weight: 600;
                margin: .5rem 0 .5rem 0;
                font-size: 18px;
            }

            span {
                font-size: 15px;
            }
        }
    }

    //Footer - Newsletter et liens
    .footer_content {
        height: 225px;

        @media screen and(max-width: 1280px) {
            height: unset;
        }

        .topWrapper {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            height: 100%;
            width: 100%;

            @media screen and(max-width: 1280px) {
                flex-direction: column-reverse;
            }
        }

        .bot_links {
            display: flex;
            flex-wrap: wrap;
            width: 55%;
            background: $footer_bgrose;
            height: 100%;
            box-sizing: border-box;
            justify-content: center;
            padding-top: 45px;
            color: $white;

            @media screen and(max-width: 1280px) {
                width: 100%;
            }

            .list_links {
                a {
                    display: block;
                    font-weight: 300;
                    text-decoration: none;
                    line-height: 25px;
                    white-space: nowrap;
                    color: $white;
                    font-size: 15px;
                }

                .footer_link_title {
                    text-transform: uppercase;
                    font-weight: 500;
                    line-height: 25px;
                    white-space: nowrap;
                    font-size: 18px;
                }

                &+.list_links {
                    margin-left: 7%;
                }
            }
        }

        .lang_switch {
            display: none;
        }

        .bot_news {
            width: 45%;
            height: 100%;
            background: $footer_bgviolet;
            box-sizing: border-box;
            padding-top: 45px;
            color: white;

            @media screen and(max-width: 1280px) {
                width: 100%;
                padding-bottom: 45px;
            }

            .bot_news_title {
                font-size: 25px;
                font-weight: 400;
                margin-bottom: 15px;
                text-transform: uppercase;
                width: 545px;
                margin: 0 auto;
                text-align: left;

                strong {
                    font-weight: 500;
                }
            }

            .w-newsletter-form {
                width: 545px;
                margin: 0 auto;
                overflow: hidden;

                .w-form-line.w-submit {
                    button {
                        background: none;
                        background-color: transparent;
                        border-color: transparent;

                        span {
                            text-transform: capitalize;
                            opacity: 0;
                            transition: opacity .2s linear;
                        }
                    }
                }

                &:hover .w-form-line.w-submit button span {
                    opacity: 1;
                }
            }

            .w-newsletter-form .w-input-element:not([type=radio]):not([type=checkbox]),
            .w-newsletter-form input[type=email].w-input-element {
                border-radius: 0;
                border: 1px solid white;
                padding-right: 0;
                background: transparent;
                border-top: none;
                border-left: none;
                border-right: none;
                box-sizing: border-box;
                height: 50px;
                padding-left: 0;

                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }

            input[type=email].w-input-element {
                border: 1px solid white;
                color: white;

                + .w-input-label {
                    color: white;
                    left: 0;
                }
            }

            .w-newsletter-form .w-has-error:not(.w-dropdown) {
                .w-input-element:not([type=radio]):not([type=checkbox]),
                input[type=email].w-input-element,
                input[type=password].w-input-element,
                input[type=text].w-input-element,
                textarea.w-input-element {
                    border: 1px solid darkred;
                    border-top: none;
                    border-left: none;
                    border-right: none;
                }
            }

            .w-input-note.w-input-error {
                color: darkred;
            }

            .socials {
                display: flex;
                justify-content: center;
                align-items: center;

                li+li {
                    margin-left: .5rem;
                }

                li a::before {
                    width: 25px;
                    height: 25px;
                }
            }

            .w-input-note.w-input-valid {
                color: #5cff51;
            }
        }

        small.wshopCopy {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            align-self: flex-end;
            padding-right: 1%;
            margin-bottom: 2%;
            font-size: 12px;
            font-weight: 400;
            color: $white;
            flex-basis: 100%;
            text-transform: lowercase;

            a {
                display: inline-block;
                width: 43px;
                height: 26px;
                background: transparent url(../svg/wshop_white_logo.svg) no-repeat center;
                background-size: contain;
                margin-left: .5rem;
            }
        }
    }
}