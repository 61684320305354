button,
.button {
    appearance: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $bgPrimaryBtn;
    font-family: $primaryFont;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: .04rem;
    text-transform: uppercase;
    text-decoration: none;
    box-sizing: border-box;
    cursor: pointer;

    &.primary,
    &.dark {
        background: $bgPrimaryBtn;
        color: white;
    }

    &.secondary {
        background: white;
        color: $bgPrimaryBtn;
    }
}

.loader {
    display: none;
    cursor: default;
    font-size: 0;
    box-sizing: border-box;

    &.dark {
        background: $bgPrimaryBtn url(../svg/three_dots_w.svg) no-repeat center;
        background-size: auto 25%;
        border: 1px solid black;
        color: white;
    }

    &.light {
        background: transparent url(../svg/three_dots.svg) no-repeat center;
        background-size: auto 25%;
        border: 1px solid black;
        color: black;
    }
}

.btn_container,
.form_submit {
    position: relative;

    .loader {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
    }

    &.loading {
        .loader {
            display: block;
        }
    }

    button.dark,
    .button.dark {
        &+.loader {
            background: $bgPrimaryBtn url(../svg/three_dots_w.svg) no-repeat center;
            background-size: auto 28%;
        }
    }
}

img.valid_form {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
}

.newlogin_page .w-newlogin-form .optin_container .w-input-note.w-input-error {
    text-align: left;
}

.w-tel-input.w-has-error {
    .img_error {
        display: none;
    }
}