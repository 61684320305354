.body_login {
    .bandeau_container {
        display: none;
    }
}

.wrapper_bandeau_connexion {
    width: 100%;
    max-width: 1344px;

    span.ill_img {
        display: block;
        position: relative;
        width: 100%;
        padding-bottom: calc(792 / 1344 * 100%);

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: auto;
            vertical-align: top;
        }

        @media screen and(max-width: 1200px) {
            display: none;
            padding-bottom: 0;
        }
    }
}

.login_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    left: 53%;
    width: 620px;
    min-height: 490px;
    background: $bg_connexion;
    padding: 3rem 0 !important;
    @media screen and(max-width: 1500px) {
        left: auto;
        right: 0;
    }

    @media screen and(max-width: 1400px) {
        left: auto;
        right: 4%;
    }
}

.newlogin_page {
    position: relative;
    padding-top: 0;
    flex-direction: row;
    width: 98%;
    margin: 0 auto;

    &:not(.bandeau_connexion_style) {
        .container_txt_vp {
            display: none;
        }

        .wrapper_bandeau_connexion {
            @media screen and(max-width: 1200px) {

                &> img {
                    display: none;
                }

                width: 100%;
                max-width: 1049px;
                padding-bottom: calc(653 / 858 * 100%);
                background: transparent url(../img/bandeau_connexion_tab.jpg) no-repeat left center;
                background-size: contain;
            }
        }
    }

    @media screen and(max-width: 1200px) {
        width: 100%;
    }

    .login_bloc {
        width: 90%;
        max-width: 475px;
        height: auto;
        margin: 0 auto;
        align-items: flex-start;

        &:first-of-type {
            border-color: white;
            padding-bottom: 0;
        }

        &:last-of-type {
            padding-top: 1.5rem;
            padding-bottom: 0;
        }

        .title {
            text-align: left;

            h2,
            h3,
            h4,
            &.my_account .subtitle {
                font-family: $primaryFont;
                font-weight: 500;
                letter-spacing: .05rem;
                line-height: 1;
            }

            &.my_account .subtitle {
                display: none;
            }

            h2 {
                font-size: 45px;
                font-weight: 600;
                margin: 0 0 0 -.1rem;
            }

            h3 {
                font-size: 25px;
                margin: 10px 0;
            }

            h4 {
                font-size: 15px;
            }

            &:not(.my_account) .subtitle {
                line-height: 1.2;

                span {
                    display: block;
                }
            }
        }

        .form-msg.error {
            background-color: transparent;
            color: red;
        }
    }

    .w-newlogin-form {
        width: 100%;

        .w-form-line {
            margin-bottom: 25px;

            &.w-submit {
                max-width: none;
            }
        }

        .w-pwd-reset {
            margin: 0;
            color: $bgPrimaryBtn;
            letter-spacing: .02rem;
            border-bottom: 0;
            text-decoration: underline;
            text-underline-position: from-font;
        }

        .w-fieldset-label {
            margin: 0 0 1rem;
            font-family: $primaryFont;
            font-size: 15px;
        }

        .optin_container.column {
            flex-direction: row;
            justify-content: left;
            align-items: flex-start;
            margin-bottom: 0;

            >.w-form-line {
                width: auto;
                margin-bottom: 0;

                &:nth-child(2) {
                    margin-left: 2rem;
                }

                .w-line-label {
                    min-width: auto;
                    margin-right: .5rem;
                }
            }
        }

        .w-line-label {
            font-family: $primaryFont;
            font-size: 15px;
            color: $bgPrimaryBtn;
        }

        .w-checkbox-input .w-input-element+.w-input-label,
        .w-radio-input .w-input-element+.w-input-label {
            font-size: 14px;
            color: $bgPrimaryBtn;
        }

        .knowMoreOptin {
            display: none;
            .info_site a {
                color: $bgPrimaryBtn;
                border-bottom: 0;
                text-decoration: underline;
                text-underline-position: from-font;
            }
        }

        .intro {
            font-size: 13px;
            color: $bgPrimaryBtn;
        }
    }

    .w-input-element:not([type=radio]):not([type=checkbox]),
    .w-nonempty:not(.no-label) .w-input-element:not([type=radio]):not([type=checkbox]):not(textarea),
    .w-nonempty:not(.no-label) input[type=text].w-input-element,
    input[type=email].w-input-element {
        height: 48px;
        box-sizing: border-box;
        background-color: white;
        border-color: white;
    }

    .w-input:not(.w-dropdown) .w-input-element:not([type=radio]):not([type=checkbox]):focus,
    .w-input:not(.w-dropdown) input[type=email].w-input-element:focus,
    .w-input:not(.w-dropdown) input[type=password].w-input-element:focus,
    .w-input:not(.w-dropdown) input[type=text].w-input-element:focus {
        border-color: white;
    }
}

.w-nonempty .w-visibility-toggle {
    border: 0;
    opacity: .6;
}

.w-lostpass-form .w-form-line.w-submit {
    max-width: none;
}

.w-oneclick-connect {
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;

    .w-social-btn {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: calc(50% - 7px);
        height: 36px;
        padding-left: 40px;
        box-sizing: border-box;
        background-color: white;
        border: 0;

        &.fb-connect {
            background-color: #1877f2;
            color: white;
        }

        >div {
            width: 18px;
            height: 18px;
            margin: 0 .6rem 0 0;
        }

        span {
            display: block;
            font-size: 16px;
            font-weight: 400;
            text-transform: uppercase;
            letter-spacing: .02rem;
        }

        &:nth-child(2n+2) {
            margin-left: 14px;
        }

        &:nth-child(1n+3) {
            margin-top: 12px;
        }

        &:nth-child(3) {
            margin-left: 0;
        }
    }

    #googleConnect {
        width: calc(50% - 7px);
        height: 36px;
        margin: 0;
        background: linear-gradient(180deg,$white 0,$white 100%);
        
        #googleConnectLabel {
            font-size: 16px;
            font-weight: 400;
            text-transform: uppercase;
            letter-spacing: .02rem;

            &:before{
                width: 9%;
                margin: 3px 0.6rem 1px 2px;
            }
        }

        .g_id_signin {
            div[aria-labelledby="button-label"] {
                width: 100%;
            }

            iframe {
                width: 100%!important;
                margin: 0!important;
            }
        }
    }
}

/**
 * FROM BASKET
 */
body.in_tunnel {
    .wrapper_bandeau_connexion {
        width: 100%;
        max-width: 1049px;

        span.ill_img {
            display: none;
            padding-bottom: 0;
        }

        @media screen and(max-width: 1200px) {
            width: 100%;
            max-width: 1049px;
            padding-bottom: calc(653 / 858 * 100%);
            background: transparent url(../img/bandeau_connexion_tab.jpg) no-repeat left center;
            background-size: contain;
        }
    }

    .newlogin_page {
        max-width: 1312px;
        padding-top: 0;

        .login_bloc .title h3 {
            margin: 0 0 10px -.1rem;
        }
    }

    .login_wrapper {
        left: auto;
        right: 0;
        padding: 2rem 0;
    }
}

/**
 * VENTES PRIVILEGES
 */
 .newlogin_page.bandeau_connexion_style {
     .login_wrapper {
         min-height: 410px !important;
         padding: 2rem 0 !important;
     }
     .title.my_account {
         display: none;
     }
     .wrapper_bandeau_connexion {
        max-width: 1920px;
        height: 648px;

        > img {
            height: 100%;
            object-fit: cover;
        }

         > span {
             padding-bottom: unset;
         }
         img {
            width: 100%;
            position: inherit;
         }
     }
     .login_bloc {
         border-color: rgba(0, 11, 33, .89);
    }
    .container_txt_vp {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: absolute;
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);
        left: 20%;
        font-size: 4.2rem;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: .12rem;

        @media screen and(max-width: 1500px) {
            left: 5%;
        }

        @media screen and(max-width: 1280px) {
            font-size: 3.2rem;
        }

        h2 {
            font-size: 1.8rem;
            text-transform: initial;
            line-height: 1.2;
            font-style: italic;
            font-weight: 400;
            letter-spacing: 0;
            margin-top: 10px;

            @media screen and(max-width: 1280px) {
                font-size: 1.3rem;
            }
        }
    }
     #auth .w-input.w-email-input {
        > .w-input-label {
             color: $primaryColor;
             opacity: .6;
         }
        > input {
            background-color: $bg_connexion;
            border: solid 1px $primaryColor;
        }
     }

     .login_wrapper {
        height: fit-content;
        padding: 3rem 0;

        @media screen and(max-width: 1280px) {
            width: 552px;
        }
     }
 }