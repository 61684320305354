#cartLogo {
    display: none;
}

.ge-free-shipping-container{
    position: relative;
    z-index: 10!important;
}


#site_head_wrap {
    position: fixed;
    z-index: 50;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;

    #top_wrapper {
        height: 53px;

        .logo {
            padding-left: 1%;
        }

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            max-width: 1920px;
            height: 100%;
            margin: 0 auto;

            .main_menu_wrapper {
                flex: 1;
                height: 100%;
                margin-left: 42px;

                .bloc_top_low_res {
                    display: none;
                }
            }

            &.nav_burger_open {
                box-shadow: inset 0 0 0 1000px rgba(0,0,0,.3);

                .lang_itm:not(.low_res).active {
                    box-shadow: inset 0 0 0 1000px rgba(0,0,0,.3);
                }
            }
        }
    }

    #header_mention_id {
        height: 30px;

        &.nav_burger_open {
            box-shadow: inset 0 0 0 1000px rgba(0,0,0,.3);
        }

        .wrap_preheadertxt {
            height: 30px;
            line-height: 30px;
            
            p:first-child,
            p span {
                display: block;
                height: 30px;
                line-height: 30px;
            }

            em {
                font-style: italic;
            }

            strong {
                font-weight: 600;
            }
        }

        .close_delais {
            display: none;
        }
    }

    a.logo {
        h1,
        div,
        span {
            font-size: 0;
            display: block;
            width: 175px;
            height: 25px;
            background: transparent url(../svg/manoush.svg) no-repeat center;
            background-size: contain;
        }
    }

    .block_top_links {
        height: 100%;
        margin-left: auto;
        display: flex;
        align-items: center;
        
        .wrap_lang::before  {
            opacity: 1;
            transition: opacity .2s linear;
        }

        .wrap_lang:hover::before  {
            opacity: .5;
        }

        .block_top_item {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 50px;
            cursor: pointer;

            .icon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                cursor: pointer;
                font-size: 0;

                &::before {
                    width: 20px;
                    height: 20px;
                }
            }

            //Display data cart
            .cart.basket .rolloverTxt {
                position: relative;

                span {
                    position: absolute;
                    width: 10px;
                    height: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 10px;
                    background-color: #fcd55f;
                    border-radius: 50%;
                    left: -10px;
                    bottom: -12px;
                    font-weight: 600;
                }
            }

            &.search {
                .search::before {
                    opacity: 1;
                    transition: opacity .2s linear;
                }

                &:hover .search::before {
                    opacity: 1;
                }

                label {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                    box-sizing: border-box;
                    padding: 0 14px;

                    &::before {
                        width: 20px;
                        height: 20px;
                    }
                }
            }

            /**
            * ACCOUNT ROLLOVER
            */
            &.my_account {
                .account::before {
                    opacity: 1;
                    transition: opacity .2s linear;
                }

                &:hover .account::before {
                    opacity: .5;
                }

                .wrapper_menu_compte {
                    cursor: initial;
                }

                .connexion_top {
                    position: absolute;
                    z-index: -1;
                    top: 100%;
                    right: 0;
                    width: 415px;
                    padding: 25px;
                    box-sizing: border-box;
                    background-color: white;
                    transition: all .3s ease-in-out;
                    display: none;
                    opacity: 0;
                }

                &.is_logged:hover {
                    .connexion_top {
                        z-index: 55;
                        opacity: 1;
                        display: block;
                    }
                }

                .wrapper_menu_compte_container {
                    height: 77vh;
                    max-height: 650px;
                    display: flex;
                    flex-direction: column;
                    cursor: initial;

                    @media only screen and (max-height: 500px) {
                        overflow: hidden;

                        .btn_container {
                            position: absolute;
                            padding-bottom: 20px;
                            bottom: 40px;
                            width: 100%;
                            background: white;
                        }
                    }
                }

                .account_name {
                    font-size: 25px;
                    font-weight: 500;
                    margin-bottom: 20px;
                    text-transform: uppercase;
                    cursor: initial;
                }

                .item {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    width: fit-content;
                    min-height: 25px;
                    margin-bottom: 20px;
                    cursor: pointer;
                    text-transform: capitalize;
                    
                    a {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        width: 100%;
                        height: 100%;
                        font-size: 15px;
                        font-weight: 400;
                        color: $bgPrimaryBtn;
                        text-decoration: none;
                        position: relative;

                        #number_wishlist_top, .highlight {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 12px;
                            color: white;
                            background-color: $bgPrimaryBtn;
                            margin-left: 1rem;
                            width: 1rem;
                            height: 1rem;
                            text-align: center;
                        }
                    }
                }

                .btn_container {
                    margin-top: auto;
                    height: 50px;
                    font-size: 18px;

                    .button,
                    .loader {
                        width: 100%;
                        height: 100%;
                        font-family: $primaryFont;
                        font-weight: 400;
                    }
                }
            }
            /**
            * BASKET ROLLOVER
            */
            &.my_basket, &.wishlist {
                .basket::before {
                    opacity: 1;
                    transition: opacity .2s linear;
                }

                &:hover .basket::before {
                    opacity: .5;
                }
                
                #show_top_cart {
                    position: absolute;
                    top: 100%;
                    right: 0;
                    width: 415px;
                    height: 700px;
                    @media only screen and (max-height: 750px) {
                        height: calc(100vh - 81px);
                    }

                    @media only screen and (max-height: 575px) {
                        justify-content: unset;
                    }

                    padding: 25px 0;
                    box-sizing: border-box;
                    background-color: #fff;
                    display: none;
                    cursor: initial;

                    &.noprod {
                        height: 250px;

                        .no_product {
                            flex-direction: column;
                            text-align: center;
                            align-items: unset;
                            height: 100%;
                        }
                    }

                    .title_panier {
                        padding: 0 25px;

                        .title_panier_rollover {
                            font-size: 25px;
                            font-weight: 500;
                            text-transform: uppercase;
                            letter-spacing: .04rem;
                            margin-bottom: 25px;
                        }

                        .panier_subtitle {
                            font-size: 18px;
                            font-weight: 400;
                            text-transform: lowercase;
                        }

                        .title_wishlist_rollover {
                            display: none;
                        }

                        a {
                            color: $bgPrimaryBtn;
                            text-decoration: none;
                        }
                    }

                    ul#cart_list {
                        height: 395px;

                        @media only screen and (max-height: 575px) {
                            height: 30vh;
                        }

                        cursor: initial;
                        padding: 0 25px;

                        li {
                            display: flex;
                            padding: 0 0 25px;

                            &+li {
                                padding: 0 0 18px;
                            }
                        }

                        .cart_thumb_holder {
                            width: 120px;

                            img {
                                width: 100%;
                                height: auto;
                                vertical-align: top;
                            }
                        }

                        .cart_detail_box {
                            width: calc(100% - 140px);
                            margin-left: 20px;

                            .cart_product {
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-end;
                                align-items: flex-start;
                                width: 100%;
                                height: 100%;
                            }

                            .sub_title {
                                display: none;
                            }

                            .title {
                                font-size: 15px;
                            }

                            .wrapper_price {
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                margin: 7px 0 10px;

                                .pricetag {
                                    font-size: 14px;
                                }
                            }

                            .wrapper_size_color {
                                display: flex;
                                margin-bottom: 15px;

                                .sub_taille {
                                    justify-content: center;
                                    width: 38px;
                                    height: 19px;
                                    background-color: $bgPrimaryBtn;
                                    color: #fff;
                                    font-size: 14px;
                                    font-weight: 300;
                                    letter-spacing: .02rem;
                                    display: flex;
                                    align-items: center;
                                    white-space: nowrap;
                                }

                                .sub_couleur {
                                    justify-content: flex-start;
                                    font-size: 15px;
                                    display: flex;
                                    align-items: center;
                                    margin-left: 15px;
                                }

                                .color_bullet {
                                    display: flex !important;
                                    justify-content: center;
                                    align-items: center;
                                    width: 18px;
                                    height: 18px;
                                    border-radius: 50%;
                                    border: 1px solid #000b21;
                                    box-shadow: inset 0 0 0 2px $white;
                                    margin-right: .5rem;
                                    background-size: cover !important;
                                }
                            }
                        }

                        .remove_prod {
                            height: fit-content;
                            margin-bottom: .5rem;
                            cursor: pointer;
                            margin-left: -5px;

                            &:before {
                                height: 25px;
                                width: 25px;
                            }
                        }
                        
                        .modify_prod {
                            text-decoration: underline;
                            text-underline-position: from-font;
                            font-size: 15px;
                            font-weight: 500;
                            letter-spacing: .02rem;
                            color: $bgPrimaryBtn;
                            cursor: pointer;
                        }
                    }

                    .wrap_tot_panier {
                        border-top: solid 1px $bgPrimaryBtn;
                        margin-top: 35px;
                        @media only screen and (max-height: 575px) {
                            position: absolute;
                            bottom: 0;
                            padding-bottom: 25px;
                            width: 100%;
                            background: white;
                            z-index: 5;
                        }
                    }

                    .cart_panier_total {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        height: 65px;
                        font-size: 22px;
                        font-weight: 500;
                        padding: 0 25px;

                        .pricetag {
                            font-size: 22px;
                            font-weight: 300;
                        }
                    }

                    .form_submit {
                        height: 50px;
                        margin: 0 25px;
                        margin-bottom: 30px;

                        .loader {
                            background-color: $bgPrimaryBtn !important;
                        }

                        .button {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            box-sizing: border-box;
                            text-decoration: none;
                        }

                        .button,
                        .loader {
                            width: 100%;
                            height: 100%;
                        }

                        .loader {
                            background-color: white;
                        }
                    }

                    .no_product {
                        display: flex;
                        justify-content: center;
                        align-items: flex-start;
                        height: 40px;
                        font-size: 14px;
                    }

                    /* Effet fluide et continu du swiper en autoplay */
                    .rollover_reassurance_swiper .swiper-wrapper {
                        -webkit-transition-timing-function:linear!important;
                        -o-transition-timing-function:linear!important;
                        transition-timing-function:linear!important;
                        .swiper-slide {
                            width: fit-content !important;
                            text-transform: uppercase;
                            font-size: 18px;
                            font-weight: 500;

                            &:nth-child(3n+1) {
                                color: $pink;
                            }

                            &:nth-child(3n+2) {
                                color: $blue;
                            }

                            &:nth-child(3n+3) {
                                color: $yellow;
                            }
                        }
                    }
                }

                &:hover,
                &.hover {
                    #show_top_cart {
                        display: flex;
                        flex-direction: column;
                        z-index: 55;
                        opacity: 1;
                    }
                }
            }
            /**
            * WISHLIST ROLLOVER
            */
            &.wishlist {
                position: relative;

                .wish::before {
                    opacity: 1;
                    transition: opacity .2s linear;
                }

                &:hover .wish::before {
                    opacity: .5;
                }

                #show_top_cart {
                    right: -50px;
                }

                .title_panier {
                    .title_panier_rollover {
                        display: none;
                    }

                    .title_wishlist_rollover {
                        display: block !important;
                        font-size: 25px;
                        font-weight: 500;
                        text-transform: uppercase;
                        letter-spacing: .04rem;
                        margin-bottom: 25px;

                        span {
                            font-size: 18px;
                            font-weight: 400;
                        }
                    }
                }

                .sub_wishlist > p {
                    text-decoration: underline;
                    text-underline-position: from-font;
                    font-size: 15px;
                    font-weight: 500;
                    letter-spacing: .02rem;
                    color: #000b21;
                    cursor: pointer;
                }
            }
        }
    }
    /**
    * RECHERCHE
    */
    .wrap_search_field {
        display: none;
        flex-direction: column;
        align-items: center;
        background: $pink_light;
        width: 98%;
        height: 79vh;
        max-height: 745px;
        position: absolute;
        top: 31px;
        left: 1%;
        cursor: initial;
        z-index: 10;

        .wrap_field {
            width: 92%;
            height: unset;
            margin-top: 4%;
            padding-bottom: 15px;
            border-bottom: 1px solid $bgPrimaryBtn;

            @media screen and (max-device-width: 1280px) {
                width: 95%;
            }

            &::before {
                width: 90px;
                height: 90px;
                margin-right: 1rem;
            }

            #search_home {
                border: none;
                flex: 1;
                width: 90%;
                font-size: 90px;
                font-weight: 500;
                text-transform: uppercase;
                background-color: $pink_light;
                font-family: $primaryFont;
                color: $bgPrimaryBtn;

                &:focus {
                    outline: none;
                }

                &::placeholder {
                    font-size: 90px;
                    font-family: $primaryFont;
                    font-weight: 500;
                    color: $bgPrimaryBtn;
                    text-transform: uppercase;
                    opacity: .35;
                    font-family: $primaryFont;
                }
            }

            .close_search {
                position: absolute;
                right: 1rem;
                top: 1rem;
                width: 12px;
                height: 12px;
                cursor: pointer;

                &:before,
                &:after {
                    position: absolute;
                    left: 7px;
                    content: ' ';
                    height: 14px;
                    width: 1px;
                    background-color: $bgPrimaryBtn;
                }

                &:before {
                    transform: rotate(45deg);
                }

                &:after {
                    transform: rotate(-45deg);
                }
            }
        }

        .results_list {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            width: 100%;
            max-width: 500px;

            li {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                height: 2rem;
                cursor: pointer;
                &.loader {
                    background: transparent url(../svg/three_dots.svg) no-repeat center;
                    background-size: 6% auto;
                }
            }
        }
    }
    //AFFICHAGE DU MENU BURGER EN FAIBLE RESOLUTION
    @media screen and(max-width: 1280px) {
        //ICONE BURGER
        .burger_menu_container {
            display: flex;
            width: 30px;
            height: 20px;
            padding-left: 3%;
            cursor: pointer;
        }

        .burger_menu {
            position: relative;
            width: 30px;
            height: 2px;
            margin-top: 8px;
            background-color: $bgPrimaryBtn;

            &::before, 
            &::after {
                content: "";
                position: absolute;
                left: 0;
                width: 30px;
                height: 2px;
                background-color: $bgPrimaryBtn;
                transition: all .2s ease-out;
            }

            &::before {
                top: 10px;
            }

            &::after {
                bottom: 10px;
            }

            &.is_clicked {
                margin-top: 0;
                background-color: transparent;

                &::before {
                    transform: rotate(45deg);
                }

                &::after {
                    bottom: -10px;
                    transform: rotate(-50deg);
                }
            }
        }

        .logo {
            position: absolute;
            left: 50%;
            transform: translate(-50%);
            padding-left: 0 !important;
        }

        .main_menu_wrapper {
            position: fixed;
            background-color: white;
            z-index: 100;
            opacity: 0;
            transform: translateX(-100%);
            margin-left: 0 !important;
            top: 0;
            transition: transform .4s ease-in-out;

            &.is_open {
                transform: translateX(0);
                opacity: 1;
            }

            #show_top_cart {
                display: none !important;
            }

            .bloc_top_low_res {
                display: flex !important;
                flex-direction: column;
                padding-top: 25px;
                font-size: 19px;
                text-transform: uppercase;
                font-weight: 500;

                .container_lang_close {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0 25px;
                    margin-bottom: 25px;

                    .burger_menu_container {
                        padding-left: 0;

                       .burger_menu::after, .burger_menu::before {
                            width: 18px;
                            height: 1px;
                       }

                       .burger_menu::after {
                            bottom: -9px;
                       }
                    }

                    .wrap_lang {
                        margin-right: unset;

                        &::before {
                            width: 23px;
                            height: 23px;
                        }

                        .lang_itm {
                            font-size: 14.5px;
                            padding-top: 3px;
                        }
                    }
                }

                .home, .account , .customer, .wish {
                    justify-content: unset;
                    padding: 0 20px;
                    background-color: $footer_bgrose;
                    text-decoration: none;
                    color: $bgPrimaryBtn;
                    height: 60px;
                    &::before {
                        margin-right: 20px;
                        height: 19px;
                        width: 19px;
                    }
                }

                .home {
                    background-color: $footer_bgviolet;
                    color: white;

                    &::before {
                        height: 17px;
                        width: 17px;
                    }
                }

                .account {
                    padding-left: 17px;

                    &::before {
                        height: 25px;
                        width: 25px;
                    }
                }

                .wishlist_low_res .rolloverTxt span {
                    display: inline-flex;

                    &::before {
                        content: '(';
                    }

                    &::after {
                        content: ')';
                    }
                }
            }

            .main_menu_contents {
                height: 100%;

                .container_menu_items {
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: unset;
                    width: 375px;
                    overflow: hidden;
                    position: relative;
                }

                .main_menu_itm {
                    height: unset;
                    justify-content: unset;
                    height: 55px;
                    padding-right: 0;
                    padding-left: 0;
                    border-bottom: solid 1px #eeeeee;
                    cursor: initial;
                    position: unset;

                    .has_submenu, .no_submenu {
                        font-size: 19px;
                        text-transform: uppercase;
                        font-weight: 500;
                        text-indent: 60px;
                        width: 100%;
                        justify-content: left;
                        opacity: 1;
                        transition: opacity .2s linear;

                        a {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            align-items: center;
                        }

                        &:hover {
                            opacity: .75;
                        }

                        &::after {
                            content: '';
                            position: absolute;
                            border: solid $bgPrimaryBtn;
                            border-width: 0 1px 1px 0;
                            display: inline-block;
                            padding: 4px;
                            transform: rotate(-45deg);
                            right: 25px;
                            height: 0px;
                            width: 0px;
                        }
                    }

                    .submenu_wrapper {
                        width: 100%;
                        z-index: 5;
                        top: 0;
                        left: 50%;
                        transform: translate(50%);
                        transition: transform .1s linear;
                        display: block !important;
                        height: 100%;
                        overflow: unset;
                        opacity: 1;

                        .menu_categ_wrapper, .ss_menu_categ_wrapper {
                            height: 100%;
                        }

                        .retour_low_res {
                            display: block;
                            cursor: pointer;
                            height: 55px;
                            display: flex;
                            align-items: center;
                            padding-left: 60px;
                            font-size: 16px;
                            letter-spacing: 0.05rem;
                            border-bottom: solid 1px #eeeeee;

                            &::before {
                                content: '';
                                position: absolute;
                                border: solid $bgPrimaryBtn;
                                border-width: 0 1px 1px 0;
                                display: inline-block;
                                padding: 4px;
                                transform: rotate(135deg);
                                left: 40px;
                            }
                        }

                        .ss_cat {
                            height: 100%;
                            max-height: 385px !important;
                            overflow-y: scroll;
                            padding: unset;
                            flex-direction: column;
                            flex-wrap: unset !important;
                            width: unset !important;
                            max-width: unset;
                            min-width: unset;
                            -ms-overflow-style: none;  /* IE and Edge */
                            scrollbar-width: none;  /* Firefox */
                            margin-top: 0;

                            &::-webkit-scrollbar {
                                display: none;
                            }

                            .ss_cat_item  {
                                margin-right: 0;
                                display: flex;
                                align-items: center;
                                text-indent: 60px;
                                border-bottom: solid 1px #eeeeee;
                                opacity: 1;
                                transition: opacity .2s linear;
                                
                                &.see_all_item {
                                    display: flex !important;
                                }

                                &:hover {
                                    opacity: .5;
                                }

                                .ss_cat_name {
                                    height: 55px;
                                    font-size: 19px;
                                    width: 100%;

                                    span {
                                        text-decoration: none;
                                        color: $primaryColor !important;
                                        max-width: 90%;
                                        text-overflow: ellipsis;
                                        overflow: hidden;
                                    }

                                    &::after {
                                        content: '';
                                        position: absolute;
                                        border: solid $bgPrimaryBtn;
                                        border-width: 0 1px 1px 0;
                                        display: inline-block;
                                        padding: 4px;
                                        transform: rotate(-45deg);
                                        right: 25px;
                                        //HIDE PREVIOUS 1920's STYLE 
                                        width: unset !important;
                                        height: unset !important;
                                        background-color: unset !important;
                                        opacity: unset !important;
                                        left: unset !important;
                                        bottom: 25px;
                                        height: 0 !important;
                                        width: 0 !important;
                                    }
                                }
                            }
                        }
                    }

                    &.next_menu {
                        .submenu_wrapper {
                            left: 0%;
                            transform: translate(0%);
                        }
                    }
                }
            }
        }
    }

    #menu_collections + .submenu_wrapper .see_all_item.ss_cat_item {
        display: none !important;
    }
}

.site_title_burger {
    display: none;
}

.wrap_lang {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row-reverse;
    margin-right: 1rem;

    &::before {
        width: 20px;
        height: 20px;
    }

    .lang_switch{
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .lang_itm{
            display: flex;
            align-items: center;
            justify-content: center;
            background: #272525;
            width: 23px;
            height: 23px;
            font-weight: 500;
            font-size: 12px;
            line-height: 23px;
            color: $white;
            text-transform: uppercase;
            text-decoration: none;

            &.active{
                background: white;
                font-size: 12px;
                color: $bgPrimaryBtn;
                position: relative;
                z-index: 2;
                padding-top: 3px;
            }

            &:not(.active){
                position: absolute;
                left: 0;
                top: 0;
                z-index: 1;
                transition: all 0.3s ease-in-out;
            }
            
            &.show{
                top: 100%;
            }
        }
    }
}