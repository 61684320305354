/**
* GOLBAL SATYLES
*/
.satelliteWrapper {
    h1 {
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 170px;
        font-size: 90px;
        font-weight: 500;
        letter-spacing: 0.08rem;
    }

    .satIntro {
        width: 98%;
        max-width: 1450px;
        margin: -15px auto 48px;
        text-align: center;
        font-size: 16px;
        line-height: 1.2;
        letter-spacing: 0.01rem;
    }

    section {
        width: 100%;
        max-width: 1920px;
        margin: 0 auto;

        &.designerWrapper {
            width: 510px;
            margin: 60px auto;
        }
    }

    .storyBloc,
    .philoBloc {
        .ill_img {
            position: relative;
            display: block;
            width: 100%;
            padding-bottom: calc(471 / 471 * 100%);

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: auto;
                vertical-align: top;
            }
        }
    }
}

/**
* HISTOIRE
*/
.storyWrapper {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;

    @media screen and(max-width: 1300px) {
        max-width: 952px;
    }

    .storyBloc {
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(25% - 0.2%);
        max-width: 471px;
        margin-bottom: 0.3%;
        font-size: 16px;
        font-weight: 300;
        line-height: 1.3;
        letter-spacing: 0.05rem;
        text-align: justify;

        @media screen and(max-width: 1400px) {
            font-size: 15px;
        }

        @media screen and(max-width: 1300px) {
            width: calc(50% - 6px);
            max-width: unset;
            margin-bottom: 10px;
            font-size: 16px;
        }

        strong,
        h2 {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            font-size: 24px;
            font-weight: 400;
            text-transform: uppercase;
            letter-spacing: 0;
            padding: 10px 0 20px;

            @media screen and(max-width: 1700px) {
                font-size: 21px;
            }

            @media screen and(max-width: 1500px) {
                font-size: 19px;
            }

            @media screen and(max-width: 1400px) {
                font-size: 18px;
            }

            @media screen and(max-width: 1300px) {
                font-size: 24px;
            }
        }

        &.txt {
            flex-direction: column;
            color: white;
            padding: 0 2%;
            box-sizing: border-box;

            @media screen and(max-width: 1300px) {
                padding: 0 4%;
            }

            &.pink {
                background-color: $pink;
            }

            &.green {
                background-color: $green;
                letter-spacing: 0.07rem;
            }
        }
    }
}

/**
* PHILOSOPHIE
*/
.philosophieWrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    column-gap: 12px;
    row-gap: 12px;

    &.alternativ {
        display: none;

        @media screen and(max-width: 1600px) {
            display: block;
        }

        .row {
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            flex-wrap: wrap;
            margin-bottom: 12px;
        }

        .philoBloc {
            &:not(.txt) {
                width: 471px;
                margin-bottom: 12px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &.txt {
                line-height: 1.4;

                @media screen and(max-width: 1200px) {
                    line-height: 1.2;
                }

                @media screen and(max-width: 1200px) {
                    line-height: 1.1;
                }

                &.pink,
                &.green,
                &.blue {
                    width: calc(100% - 483px);
                }

                &.pink {
                    padding: 0 6%;

                    @media screen and(max-width: 1200px) {
                        padding: 0 32px;
                    }
                }

                &.green {
                    padding: 0 10%;

                    @media screen and(max-width: 1200px) {
                        padding: 0 9 0px;
                    }
                }

                &.blue {
                    padding: 0 12%;

                    @media screen and(max-width: 1200px) {
                        padding: 0 70px;
                    }
                }
            }

            &.max {
                width: calc(50% - 6px);
            }
        }
    }

    @media screen and(max-width: 1600px) {
        display: none;
    }

    .philoBloc {
        &.txt {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: justify;
            font-size: 16px;
            font-weight: 300;
            color: white;
            line-height: 1.1;
            letter-spacing: 0.055rem;
            padding: 0 56px;
            box-sizing: border-box;
            grid-auto-flow: column dense;

            small {
                font-size: 14px;
                align-self: flex-start;
            }

            h2,
            span {
                font-size: 40px;
                font-weight: 600;
                text-transform: uppercase;
                text-align: center;
                margin-bottom: 20px;
            }

            &.pink {
                grid-column: 2 / 5;
                background-color: $pink;
            }

            &.green {
                grid-column: 2 / 3;
                background-color: $green;
            }

            &.blue {
                grid-column: 1 / 3;
                background-color: $blue;
            }
        }
    }
}

/**
* DESIGNER
**/
#designerBanner {
    position: relative;
    max-width: 1920px;
    margin: 0 auto;

    .ill_img {
        display: block;
        position: relative;
        width: 100%;
        padding-top: calc(720 / 1920 * 100%);

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: auto;
            vertical-align: top;
        }
    }

    img {
        width: 100%;
        height: auto;
    }

    h1 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
    }
}

.designerWrapper {
    position: relative;
    text-align: center;
    font-size: 17px;
    line-height: 1.1;

    &:before,
    &:after {
        content: "";
        display: block;
        width: 75px;
        height: 72px;
        position: absolute;
    }

    &:before {
        background: transparent url(../img/satellites/designer/quote1.png) no-repeat center;
        background-size: contain;
        top: -83px;
        left: -62px;
    }

    &:after {
        background: transparent url(../img/satellites/designer/quote2.png) no-repeat center;
        background-size: contain;
        bottom: -48px;
        right: -60px;
    }

    h2,
    h3 {
        display: block;
        font-size: 26px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 0.04rem;
        margin: 0.3rem 0 1rem;

        &.pink {
            font-size: 25px;
            color: $pink;
            font-weight: 600;
            letter-spacing: 0.03rem;
            margin: 1rem 0 0.2rem;
        }

        &.withMargins {
            margin: 1rem 0;
        }
    }
}

/**
* WALLPAPAER
*/
.wallpaperWrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    column-gap: 12px;
    row-gap: 12px;

    @media screen and(max-width: 1400px) {
        grid-template-columns: repeat(3, 1fr);
    }

    .item {
        position: relative;
        text-decoration: none;

        .ill_img {
            position: relative;
            display: block;
            width: 100%;
            padding-bottom: calc(620 / 473 * 100%);
            overflow: hidden;

            img {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: auto;
                vertical-align: top;
            }
        }

        .hover {
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.4);
            opacity: 0;
            transition: opacity 0.3s ease-in-out;

            .button {
                width: 260px;
                height: 50px;
                position: absolute;
                top: 54%;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        h2 {
            width: fit-content;
            position: absolute;
            z-index: 1;
            top: 46%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 45px;
            font-weight: 500;
            color: $primaryColor;
            text-transform: uppercase;
            text-align: center;
            letter-spacing: 0.04rem;
        }

        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(9),
        &:nth-child(10),
        &:nth-child(11),
        &:nth-child(12),
        &:nth-child(16),
        &:nth-child(17) {
            .ill_img img {
                width: auto;
                height: 100%;
            }
        }

        &:nth-child(2),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(10),
        &:nth-child(13),
        &:nth-child(14),
        &:nth-child(15) {
            h2 {
                color: white;
            }
        }

        &:hover .hover {
            opacity: 1;
        }
    }
}