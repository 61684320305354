nav.main_menu_wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    .main_menu_contents {

        .container_menu_items {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
        }
    }
    .main_menu_itm {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding-right: 50px;
        cursor: pointer;
        position: relative;
        @media screen and(max-width: 1440px) {
            padding-right: 25px;
        }
        .no_submenu {
            transition: opacity .2s linear;
            opacity: 1;
            &:hover {
                opacity: .5;
            }
        }
        &:hover {
            .has_submenu a {
                opacity: .5;
            }
        }
        &:first-child {
            padding-left: 0;
        }
        &:last-child {
            padding-right: 0;
        }
        .has_submenu, .no_submenu {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            font-size: 15px;
            font-weight: 500;
            text-transform: uppercase;
            color: $bgPrimaryBtn;
            text-decoration: none;
            @media screen and(max-width: 1440px) {
                font-size: 14px;
            }
            a {
                text-decoration: none;
                opacity: 1;
                transition: all .2s linear;
                color: $bgPrimaryBtn;
                letter-spacing: 0.01rem;
            }
        }
    }
    .submenu_wrapper {
        height: 0;
        overflow: hidden;
        opacity: 0;
        transition: opacity .3s linear;
        &.menu_visible {
            opacity: 1;
            height: initial;
            overflow: visible;
        }
        position: absolute;
        top: 100%;
        left: -20px;
        background-color: white;
        .menu_categ_wrapper {
            .ss_menu_categ_wrapper {
                cursor: initial;
                .retour_low_res {
                    display: none;
                }
                .ss_cat {
                    border: 1px solid rgba(17, 17, 17, 0.5);
                    display: flex;
                    max-height: 160px;
                    min-width: 250px;
                    padding: 15px 25px 15px 20px;
                    margin-top: 20px;
                    
                    @media screen and (max-width: 1280px) {
                        border: none;
                    }
                    
                    .ss_cat_item {
                        margin-right: 30px;
                    }
                    a {
                        color: $bgPrimaryBtn;
                        text-decoration: none;
                        white-space: nowrap;
                        min-height: 40px;
                        font-weight: 900;
                        text-transform: uppercase;
                        font-size: 25px;
                        position: relative;
                        display: flex;
                        align-items: center;
                        width: fit-content;
                        span {
                            text-decoration: none;
                            text-underline-position: from-font;
                        }
                        &:hover span {
                            text-decoration: underline;
                        }
                    }
                }
                + .menu_img {
                    display: none;
                }
            }
        }
    }
}