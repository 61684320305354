body.customer,
body.order {
    .main_ariane,
    .initiales_welcome,
    .nav_moncompte {
        display: none;
    }
}

.container_welcome {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 139px;
    background: rgb(255,192,255);
    background: radial-gradient(circle, rgba(255,192,255,1) 0%, rgba(249,191,199,1) 85%);
    font-size: 90px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: .1rem;

    .welcome {
        margin-top: 23px;
        margin-bottom: 23px;
        text-align: center;
    }
}

.accountNav {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    margin-bottom: 28px;

    .item {
        opacity: 1;
        transition: opacity .2s linear;
        a {
            font-size: 17px;
            font-weight: 500;
            color: $bgPrimaryBtn;
            text-transform: uppercase;
            text-decoration: none;
            letter-spacing: .04rem;
        }

        &.actif a {
            opacity: .5;
            cursor: default;
        }

        &+.item {
            margin-left: 68px;
        }
        &:not(.actif):hover {
            opacity: .5;
        }
    }
}

.wrapper_moncompte_main,
.container_adresses {
    width: 98%;
    max-width: 1280px;
    margin: 0 auto;

    &.content_newsletter {
        position: relative;
        width: 92%;
    }

    &.wrapper_content_password {
        width: 450px;
        margin: 110px auto 40px;

        .container_title {
            font-size: 30px;
            font-weight: 500;
            text-transform: uppercase;
            letter-spacing: .04rem;
            margin-bottom: 1rem;
        }

        .w-form-line {
            margin-bottom: 1.5rem;

            &.w-submit {
                width: 290px;
                margin-bottom: 0;
            }
        }
    }

    .w-checkbox-input .w-input-element,
    .w-radio-input .w-input-element {
        &+.w-input-label,
        &+.w-input-label {
            font-size: 14px;
            color: $bgPrimaryBtn;

            &:before {
                border: 1px solid #e8e8e8;
                box-shadow: inset 0 0 0 2px #fff;
            }
        }

        &:checked .w-input-label:before {
            box-shadow: inset 0 0 0 2px #fff;
        }
    }

    .w-input-element:not([type=radio]):not([type=checkbox]),
    .w-nonempty:not(.no-label) .w-input-element:not([type=radio]):not([type=checkbox]):not(textarea),
    .w-nonempty:not(.no-label) input[type=text].w-input-element,
    input[type=email].w-input-element {
        height: 48px;
        border-color: #e8e8e8;
        box-sizing: border-box;
    }

    .w-input.w-tel-input .selected-flag {
        border: 0;
        border-right: 1px solid #e8e8e8;
        background-color: white;
        left: 1px;

        &:after {
            border-left: 0.3em solid transparent;
            margin-left: .5rem;
        }
    }

    .w-tel-input .w-input-element:not([type=radio]):not([type=checkbox]),
    .w-tel-input.w-nonempty:not(.no-label) .w-input-element:not([type=radio]):not([type=checkbox]):not(textarea){
        padding-left: 4.1rem;
    }

    .w-input-label {
        color: #919191;
    }
}

/**
 * CLIENT INFOS
 */
.wrapper_content_mes_infos .wrapper_form {
    position: relative;

    .w-input.w-tel-input .selected-flag {
        width: auto;
        padding: 0 .5rem;

        &:after {
            margin-left: .5rem;
        }
    }

    .update_success {
        position: absolute;
        top: -2em;
        font-size: 15px;
        color: green;
    }
}

body.customer div.wrapper_moncompte_main.wrapper_content_mes_infos.right_container .wrapper_form form {
    div.w-form-line.w-has-tel:nth-child(7) {
        .w-input-container:nth-child(2) {
            label.w-input.w-tel-input img#iconErrclientTel {
                display: none;
            }
        }
    }
}

/**
 * ADDRESSES
 */
.conteneur_adresse {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;

    .adresse {
        position: relative;
        width: 32%;
        padding: 30px 0 35px;
        margin-bottom: 2%;

        .relativePos {
            position: relative;
            width: 80%;
            margin: 0 auto;
        }

        &:nth-child(4n + 1) {
            background-color: $blue_high;
        }

        &:nth-child(4n + 2) {
            background-color: $light_green;
        }

        &:nth-child(4n + 3) {
            background-color: $yellow_news;
        }

        &:nth-child(4n + 4) {
            background-color: $pink_light;
        }

        &:nth-child(3n + 2),
        &:nth-child(3n + 3) {
            margin-left: 2%;
        }

        .addr_label {
            font-size: 14px;
            text-align: center;

            .addr_titre {
                font-size: 20px;
                font-weight: 500;
                text-transform: uppercase;
                letter-spacing: .02rem;
                margin-bottom: 5px;
            }
        }

        .infos {
            line-height: 1.4;
            font-size: 15px;
            margin-top: 32px;

            .full_name {
                text-transform: uppercase;
                font-weight: 500;
            }
        }

        .modlinks_wrapper {
            position: absolute;
            bottom: 0;
            right: 0;

            a {
                font-size: 14px;
                font-weight: 500;
                color: $bgPrimaryBtn;
                line-height: 1.4;
                text-decoration: underline;
                text-underline-position: from-font;
                margin-left: .5rem;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

form#adresseForm {
    .choix_type_adresse {
        margin-bottom: 28px;
    }

    .w-checkbox-input+.w-checkbox-input,
    .w-checkbox-input+.w-radio-input,
    .w-radio-input+.w-checkbox-input,
    .w-radio-input+.w-radio-input {
        margin-left: 2.5rem;
    }

    .addrFullDisplay {
        .w-group-label {
            font-family: $primaryFont;
            font-size: 20px;
            font-weight: 500;
            text-transform: uppercase;
            letter-spacing: .04rem;
        }
    }
}

.wrapper_mes_adresses a.button {
    display: inline-flex;
    height: 50px;
    padding: 0 42px;
}

/**
 * ORDERS
 */
.wrapper_mes_commandes {
    p.intro {
        font-size: 15px;
        margin-bottom: 34px;
    }

    .wrapper_menu_tab {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        margin-bottom: 30px;

        .date_select {
            position: relative;
            width: 260px;
            height: 50px;
            border: 1px solid $bgPrimaryBtn;
            box-sizing: border-box;

            select {
                appearance: none;
                border: 0;
                width: 100%;
                height: 100%;
                padding: 0 1.5rem;
                font-family: $primaryFont;
                font-size: 16px;
                font-weight: 300;
                letter-spacing: .02rem;
                text-transform: uppercase;
            }

            &:after {
                content: '';
                border: solid $bgPrimaryBtn;
                border-width: 0 1px 1px 0;
                display: inline-block;
                padding: .15rem;
                position: absolute;
                top: 50%;
                right: 1.5rem;
                transform: translateY(-50%) rotate(45deg);
            }
        }

        #com_onglet {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: calc(100% - 270px);
            max-width: 750px;
            height: 50px;
            background-color: $purple;

            li {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 25%;
                font-family: $primaryFont;
                font-size: 15px;
                font-weight: 500;
                letter-spacing: .02rem;
                text-transform: uppercase;

                &.actif a {
                    text-decoration: underline;
                    text-underline-position: under;
                }
            }

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 50px;
                color: $bgPrimaryBtn;
                text-decoration: none;
                text-underline-position: under;
            }
        }
    }

    table.table_mon_compte {
        thead th {
            height: 40px;
            font-size: 17px;
            font-weight: 500;
            letter-spacing: .02rem;
            border-bottom: 1px solid #c5c5c5;
            text-transform: capitalize;
            vertical-align: middle;
        }

        tbody tr {
            td {
                height: 58px;
                font-size: 15px;
                letter-spacing: .02rem;
                vertical-align: middle;

                &:last-child {
                    border-bottom: 0;
                }

                .link_fact {
                    .facture-bloc:nth-of-type(2) {
                        margin-top: 10px;
                    }
                }
            }

            a {
                color: $bgPrimaryBtn;
                text-decoration: underline;
                text-underline-position: under;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

/**
 * ORDER DETAIL
 */
.wrapper_content_cmd_detail {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 25px;

    .go_back {
        position: absolute;
        top: -2rem;
        left: 0;

        a {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 13px;
            font-weight: 300;
            letter-spacing: .05rem;
            color: #b0b0b0;
            text-decoration: none;

            &::before {
                content: '';
                border: solid #b0b0b0;
                border-width: 0 1px 1px 0;
                display: inline-block;
                padding: 3px;
                transform: rotate(135deg);
                margin-right: .5rem;
            }
        }
    }

    .wrapper_left {
        position: relative;
        width: calc(100% - 379px);
        max-width: 804px;
    }

    .wrapper_right {
        width: 369px;
    }

    .order_detail_head {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 30px;
    }

    .wrapper_infos_cmd,
    .wrapper_delivery_addr {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 48%;
        max-width: 369px;
        height: 199px;
        padding: 30px 32px;
        box-sizing: border-box;
        font-size: 15px;
        line-height: 1.6;

        .cmd_txt,
        .addr_title {
            width: 100%;
            font-size: 20px;
            font-weight: 500;
            text-transform: uppercase;
            letter-spacing: .04rem;
            text-align: center;
            line-height: 1;
            margin-bottom: 30px;

            span {
                display: block;
                width: 100%;
                font-size: 15px;
                font-weight: 400;
                text-transform: lowercase;
                letter-spacing: .01rem;
                margin-top: 5px;
            }
        }

        .num_suivi a {
            color: $bgPrimaryBtn;
            text-decoration: none;
        }

        .addr_name {
            font-weight: 500;
        }

        .cmd_txt span {
            text-transform: initial;
        }
    }

    .wrapper_infos_cmd {
        background-color: $blue_high;

        label {
            font-weight: 500;
            text-transform: uppercase;
        }
    }

    .cart_product_line {
        width: 100%;
        align-items: center;
        .cart_product_subtitle.line_product_desc {
            display: block;
        }
        .wrapper_cart_product_desc {
            margin-bottom: 0;
        }
    }

    .wrapper_delivery_addr {
        background-color: $light_green;
        margin-left: 23px;
    }

    #comment_retourner_cmd {
        width: 100%;
        min-height: 199px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-sizing: border-box;
        margin-bottom: 50px;
        font-size: 14px;
        text-align: center;

        #liste_motif_retour {
            margin: 25px 0;
        }

        a {
            color: $bgPrimaryBtn;
            text-decoration: underline;
            text-underline-position: under;

            &:hover {
                text-decoration: none;
            }

            &#pdfDIV {
                width: 260px;
                height: 50px;
                margin: 0 auto;
                text-decoration: none;
            }
        }

        #btnPrintPdf {
            a {
                width: 260px;
                height: 50px;
                margin: 20px auto 15px auto;
                text-decoration: none;
            }
        }
        .print-return > .btn_container a {
            width: 260px;
            height: 50px;
            margin: 20px auto 15px auto;
            text-decoration: none;
            span {
                color: $white;
            }
        }
    }

    .wrap_total_commande {
        background-color: $blue_light;
        width: 100%;
        height: auto;
        padding: 30px 32px;
        box-sizing: border-box;

        .titre {
            font-size: 20px;
            font-weight: 500;
            text-transform: uppercase;
            letter-spacing: .05rem;
            margin-bottom: 18px;
        }

        .wrapper_total {
            .clearfix {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 30px;

                &:nth-child(2) {
                    font-weight: 500;
                }

                &:last-child {
                    font-size: 22px;
                    margin-top: 10px;
                    padding-top: 10px;
                    border-top: 1px solid $bgPrimaryBtn;
                }
            }
        }
    }
}

.ge-free-shipping-container ~ #appContainer .wrap_total_commande{
    display: none;
}
.ge-free-shipping-container ~ #appContainer .cmd_items .pricetag{
    opacity: 0;
}

.ge-free-shipping-container ~ #appContainer .wrapper_mes_commandes .montant{
    opacity: 0;
}

#appContainer .wrap_total_commande{
    display: none;
}
#appContainer .cmd_items .pricetag{
    opacity: 0;
}

#appContainer .wrapper_mes_commandes .montant{
    opacity: 0;
}

/**
 * MESSAGES
 */
.content_messages {
    font-size: 15px;

    .intro {
        margin-bottom: 40px;

        p {
            margin-bottom: 22px;
        }

        .form_submit {
            width: 260px;
            height: 50px;

            .button {
                width: 100%;
                height: 100%;
            }
        }
    }

    table.table_mes_messages {
        width: 100%;

        thead td {
            height: 35px;
            border-bottom: 1px solid #919191;
            vertical-align: middle;
            font-size: 17px;
            font-weight: 500;
            text-transform: capitalize;
        }

        tbody {
            border-bottom: 1px solid #e8e8e8;

            td {
                height: 58px;
                vertical-align: middle;
            }

            tr.message_account_container {
                td {
                    height: auto;
                }
            }

            &:last-of-type {
                border-bottom: 0;
            }
        }

        .btn_ouvrir_message {
            text-decoration: underline;
            text-underline-position: under;
            cursor: pointer;

            &:hover {
                text-decoration: none;
            }
        }

        .details_msg_content {
            padding: 0 1.845rem 2rem;

            &.class_addline {
                margin-top: 1rem;
            }

            p {
                display: flex;
                height: 20px;
                align-items: center;

                &.objet.date {
                    padding-left: 60px;
                    margin-bottom: 1rem;
                }
            }

            label {
                width: 60px;
                font-weight: 500;
            }
        }

        .message_details_holder {
            margin-top: .5rem;
        }

        .w-form-line.w-submit {
            width: 260px;
            max-width: 260px;
            margin-top: 1.5rem;
        }
    }

    .file-input-row {
        .ajout_pj_label {
            background-color: white;
            border: 1px solid $bgPrimaryBtn;
            color: $bgPrimaryBtn;
            width: 260px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            height: 50px;
            font-weight: 500;
            letter-spacing: .05rem;
            cursor: pointer;
            text-transform: uppercase;
            box-sizing: border-box;
        }

        .filepj1 {
            display: none;
        }
    }

    .file-list {
        display: flex;
        flex-direction: column;

        .rowList {
            display: flex;
            align-items: center;
            margin-top: .5rem;

            .closePj {
                position: relative;
                appearance: none;
                border: 0;
                background: white;
                width: 14px;
                height: 14px;
                cursor: pointer;
                margin-left: 1rem;

                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 7px;
                    height: 14px;
                    width: 1px;
                    background-color: $bgPrimaryBtn;
                }

                &:before {
                    transform: rotate(45deg);
                }

                &:after {
                    transform: rotate(-45deg);
                }
            }
        }
    }
}

.wrapper_moncompte_main.content_messages {
    .w-input-element:not([type=radio]):not([type=checkbox]) {
        height: 100px;
        padding-top: 2rem;
        box-sizing: border-box;
    }

    .w-textarea.w-nonempty .w-input-element:not([type=radio]):not([type=checkbox]) {
        padding-top: 2rem;
        font-size: .9rem;
    }

    .w-input.w-textarea .w-input-element+.w-input-label {
        transform: none !important;
        top: .8rem;
    }
}

/**
 * NEWSLETTER
 */
body.newsletter {
    .wrapper_moncompte_main .w-checkbox-input .w-input-element{
        &[name="subscribe_general"]+.w-input-label {
            background-color: $blue_high;
        }

        &[name="subscribe_ope"]+.w-input-label {
            background-color: $light_green;
        }

        &[name="subscribe_sms"]+.w-input-label {
            background-color: $yellow_news;
        }

        &[name="subscribe_ecrm"]+.w-input-label {
            background-color: $pink_light;
        }

        +.w-input-label {
            width: 100%;
            padding: .8rem;
            box-sizing: border-box;

            &:before {
                position: absolute;
                left: -36px;
                width: 26px;
                height: 13px;
                border-radius: 13px;
                background-color: #ececec;
                box-shadow: inset 0 1px 1px 0 #ccc;
            }

            &:after {
                content: '';
                position: absolute;
                left: -35px;
                width: 13px;
                height: 13px;
                background-color: #fff;
                box-shadow: inset 0 0 1px 1px #ccc;
                border-radius: 50%;
            }
        }

        &:checked+.w-input-label {
            &:before {
                background-color: #909090;
            }

            &:after {
                left: -22px;
                background-color: #fff;
            }
        }
    }

    .w-form-line.optin-select-line {
        margin-bottom: 23px;

        .w-input-label {
            font-family: $primaryFont;
            font-size: 15px;
            color: $bgPrimaryBtn;
        }

        strong {
            font-weight: 500;
            text-transform: uppercase;
            margin-bottom: .5rem;
        }
    }

    .w-checkbox-input {
        height: auto;
    }

    .update_failed,
    .update_success {
        margin-top: 1rem;
        font-size: 15px;
        font-weight: 500;
    }

    .update_success {
        color: green;
    }

    .update_failed {
        color: red;
    }
}

body.customer,
body.in_tunnel {
    .w-has-tel {
        #iconValidclientTel,
        #iconValidtelephone,
        #iconValidtelephone {
            display: none;
        }
    }
}