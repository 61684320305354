.w-form-line * {
    box-shadow: none !important;
    outline: none;
}

//Style général des champs
.w-input-element:not([type=radio]):not([type=checkbox]),
input[type=email].w-input-element,
.w-nonempty:not(.no-label) .w-input-element:not([type=radio]):not([type=checkbox]):not(textarea),
.w-newuser-form .w-nonempty:not(.no-label) .w-input-element:not([type=radio]):not([type=checkbox]),
.w-nonempty:not(.no-label) input[type=text].w-input-element {
    height: 1.225rem;
    padding: 1.5rem .8rem .4rem;
    font-size: .813rem;
    border-radius: 0;
    transition: background-color 5000s ease-in-out 0s;
}

//Texte en italique quand l'input est focus
.w-input:not(.w-dropdown) .w-input-element:not([type="radio"]):not([type="checkbox"]):focus,
.w-input:not(.w-dropdown) input[type="email"].w-input-element:focus {
    +.w-input-label {
        font-style: italic;
        transform: translateY(-115%);
        font-size: .75rem;
        transition: transform .2s ease-out, font-size .2s ease-out, opacity .2s linear, -webkit-transform .2s ease-out;
    }
}

.w-tel-input .w-input-element:focus ~ .w-input-label {
    font-style: italic;
    transform: translateY(-115%);
    font-size: .75rem;
    transition: transform .2s ease-out, font-size .2s ease-out, opacity .2s linear, -webkit-transform .2s ease-out;
}

.w-input.w-nonempty {
    .w-input-label {
        font-style: italic !important;
        font-size: .75rem !important;
    }
}

.w-input.w-tel-input .w-input-label {
    left: 4.2rem;
}

.w-has-error:not(.w-dropdown) .w-input-element {
    border-color: #ff6d6d !important;
}

// Basket coupon code
.w-coupon-form {
    .w-form-line .w-input-element:not([type=radio]):not([type=checkbox]),
    .w-form-line input[type=text].w-input-element {
        border-radius: 0;
        box-sizing: border-box;
        height: 48px;
        background-color: white;
        border-color: white;

        &:focus {
            border-color: white;
        }
    }

    .w-nonempty:not(.no-label) .w-input-element:not([type=radio]):not([type=checkbox]):not(textarea),
    .w-nonempty:not(.no-label) input[type=text].w-input-element {
        height: 48px;
    }

    .w-form-line.w-submit {
        right: 1px;

        .w-submit-button {
            background-color: white;
            border-color: white;
            color: $bgPrimaryBtn;
            width: 2.8rem;
            height: 2.8rem;

            &.w-loader {
                background: white url(../svg/three_dots.svg) no-repeat center;
                background-size: auto 16%;
            }
        }
    }

    .w-input-label {
        font-size: 15px;
        color: $primaryColor;
        font-family: $primaryFont;
    }
}

.w-submit-button,
button.w-submit-button {
    background-color: $bgPrimaryBtn;
    border-color: $bgPrimaryBtn;
    font-family: $primaryFont;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: .05rem;
    height: 50px;

    &.w-secondary-btn {
        background-color: white;
        color: $bgPrimaryBtn;
    }
}

.w-submit-button:not(.w-loader):focus,
.w-submit-button:not(.w-loader):hover,
button.w-submit-button:not(.w-loader):focus,
button.w-submit-button:not(.w-loader):hover {
    background-color: $bgPrimaryBtn;
    color: white;
}

.w-submit-button.w-secondary-btn:not(.w-loader):focus,
.w-submit-button.w-secondary-btn:not(.w-loader):hover,
button.w-submit-button.w-secondary-btn:not(.w-loader):focus,
button.w-submit-button.w-secondary-btn:not(.w-loader):hover {
    background-color: white;
    color: $bgPrimaryBtn;
}

.w-contact-form .file-input-row label.w-loader,
.w-submit-button.w-loader,
button.w-submit-button.w-loader {
    background: $bgPrimaryBtn url(../svg/three_dots_w.svg) no-repeat center;
    background-size: auto 25%;
}


.selected-flag {
    border-right: 1px solid #e8e8e8;
}

.w-input-note {
    @media screen and(max-width: 800px) {
        font-size: .7rem;
        line-height: 1;
    }
}

.w-radio-group {
    display: flex;

    &.w-has-error .w-input-element + .w-input-label {
        color: $error_red!important;

        &::before {
            border-color:  $error_red!important;
            box-shadow: 0 0 0 1px  $error_red!important;
        }
    }
}