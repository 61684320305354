.wishlist_intro {
    font-size: 15px;
}

#nrArticlesSelected {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 54px;
    font-size: 25px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: .02rem;
    margin-bottom: 15px;

    &:before {
        content: '';
        display: block;
        width: 22px;
        height: 20px;
        background: transparent url(../img/wish_basket.gif) no-repeat center;
        background-size: contain;
        margin-right: 8px;
    }

    .bloc_price {
        margin-left: .5rem;
    }
}

.wishlist_container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;

    &.publicStyle {
        flex-direction: row;

        &.disconnected {
            margin-top: 50px;
        }
    }

    .w-right-bloc {
        width: 260px;
    }

    .w-left-bloc {
        width: calc(100% - 310px);
    }

    .wrapper_btn_add {
        .form_submit.input_container.loading {
            #bloc_btn_loader.loading {
                width: 100%;   
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%); 
            }
        }

        .form_submit:first-child {
            margin-bottom: 12px;
        }

        .button {
            height: 50px;
        }
    }
}

.wish_liste_product {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .product_ctn {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 48%;
        margin-bottom: 25px;

        @media screen and(max-width: 1200px) {
            width: 80%;
        }
    }

    .product_check {
        input[type="checkbox"] {
            appearance: none;
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin: 0 0 0 30px;
            width: calc(100% - 30px);
            height: 100%;
            border: 1px solid #c5c5c5;
            box-sizing: border-box;

            +label {
                display: block;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                border: 1px solid #c5c5c5;
                background: $bgPrimaryBtn;
                box-sizing: border-box;
                box-shadow: inset 0 0 0 20px white;
                cursor: pointer;
            }

            &:hover,
            &:checked {
                border: 1px solid $bgPrimaryBtn;

                +label {
                    border: 1px solid $bgPrimaryBtn;
                    box-shadow: inset 0 0 0 2px white;
                }
            }
        }
    }

    .cart_product_line {
        width: 100%;
        margin: 0 0 0 11px;
        padding: 1.5rem;

        .cart_product_pic {
            position: relative;

            img {
                width: 100%;
                height: auto;
                vertical-align: top;
            }

            .wish_noStock {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                font-size: 14px;
                font-weight: 500;
                text-align: center;
                background: rgba(255, 255, 255, .8);
                padding: 2.5rem 0;
            }
        }

        .cart_product_modify {
            position: relative;
        }

        .cart_product_subtitle.line_product_desc {
            display: block;
        }
    }

    .wrapper_cart_product_desc {
        .cart_product_sizecol.color .color_bullet {
            box-shadow: inset 0 0 0 2px white;
            background-size: cover !important;
        }

        a.wish_mod_prod {
            position: relative;
            width: 100%;
            margin-top: 1rem;
            font-size: 15px;
            color: $primaryColor;
            text-decoration: underline;
        }
    }
}

body.wishlist {
    #abox {
        .header_lightbox,
        .txt_alert {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
        }

        .txt_alert {
            font-size: 25px;
            font-weight: 500;
            text-transform: uppercase;
            letter-spacing: .02rem;
        }
    }
}