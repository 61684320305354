.publication_index {
    .publication_page {
        max-width: 1920px;
        margin: 0 auto;
    }
    //Module 1
    #bandeau_publication {
        position: relative;
        .wrapper_bandeau {
            width: 100%;
        }
        .wrapper_txt {
            position: absolute;
            bottom: 10%;
            left: 61.5%;
            max-width: 505px;
            p, h1 {
                color: white;
                font-size: 70px;
                text-transform: uppercase;
                margin-bottom: 20px;
                &:last-child {
                    font-size: 30px;
                    text-transform: initial;
                    font-style: italic;
                    line-height: 1.2;
                }
            }
            h1 {
                font-weight: 500;
            }
            p {
                width: 75%;
            }
            @media screen and(max-width: 1366px) {
                padding-right: 30px;
                h1 {
                    font-size: 50px !important;
                }
                p {
                    font-size: 25px !important;
                }
            }
        }
    }
    //Module 2
    #bandeau_publication_2 {
        margin: 45px 0;
        text-align: center;
        font-size: 21px;
        font-weight: 400;
        display: flex;
        flex-direction: column;
        .wrapper_bandeau {
            margin-bottom: 50px;
        }
        p {
            font-size: 21px;
            line-height: 1.2;
            font-weight: 400;
            width: 55%;
            margin: 0 auto;
            display: inline;
        }
        h2 {
            display: inline;
        }
        strong {
            font-weight: 600;
        }
    }

    .publication_detail {
        display: flex;
        flex-wrap: wrap;
    }
    //Module 3 {
    .bandeau_publication_article {
        position: relative;
        width: calc(25% - 10px);
        margin-right: 13px;
        margin-bottom: 13px;
        &:nth-child(4n) {
            margin-right: 0;
        }
        img {
            width: 100%;
        }
        span {
            position: absolute;
            left: 0;
            top: calc(50% - 20px);
            width: 100%;
            text-align: center;
            z-index: 5;
            a {
                width: 50%;
                display: block;
                margin: 0 auto;
                padding: 20px 0;
                color: transparent;
                background-color: transparent;
                opacity: 0;
                transition: all .2s linear;
                text-decoration: none;
                text-transform: uppercase;
                font-size: 18px;
                font-weight: 500;
            }
        }
        &::after {
            content: '';
            position: absolute;
            height: 100%;
            left: 0;
            top: 0;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.349);
            opacity: 0;
            transition: opacity .2s linear;
        }
        &.has_cta:hover {
            span a {
                background-color: $bgPrimaryBtn;
                color: white;
                opacity: 1;
            }
            &::after {
                opacity: 1;
            }
        }
    }
}