.product_page {
    #breadcrumbs {
        display: none;
    }
}

.page_produit:not(.look) {
    #btn_retour_product {
        opacity: .5;
        max-width: 1920px;
        width: 98%;
        margin: 0 auto;
        display: block;
        margin-bottom: 15px;
        margin-top: 15px;
        color: $bgPrimaryBtn;
        text-decoration: none;
        font-size: 13px;
        span::before {
            content: '';
            border: solid $bgPrimaryBtn;
            opacity: .5;
            border-width: 0 1px 1px 0;
            display: inline-block;
            padding: 3px;
            transform: rotate(135deg);
            margin-right: 5px;
        }
    }
    .product_main_wrapper {
        display: flex;
        justify-content: flex-end;
        column-gap: 10%;
        max-width: 1920px;
        width: 98%;
        margin: 0 auto;

        @media screen and(max-width: 1440px) {
            column-gap: 2rem;
        }

        @media screen and(max-width: 1280px) {
            justify-content: space-around;
        }
        //LEFT PART
        .product_left_image {
            display: flex;
            position: relative;
            column-gap: 2rem;

            .swiper-slide {
                display: flex;
                justify-content: flex-end;
            }

            @media screen and(max-width: 1440px) {
                width: 50%;
                height: unset;
                margin-right: 2%;
            }

            @media screen and(max-width: 1280px) {
                margin-right: 0;
                width: 50%;
            }

            //MAIN SLIDER
            .main_vis {
                opacity: 0;
                height: 838px;
                cursor: pointer;
                position: initial;
                display: flex;
                column-gap: 2rem;
                margin: 0;
                .swiper-pagination-progressbar {
                    position: relative;
                    z-index: 50;
                    width: 2px;
                    height: 100%;
                    right: unset;
                    left: unset;
                    top: 0;
                    span {
                        background: $bgPrimaryBtn;
                    }
                }
                img {
                    width: auto;
                    max-width: 556px; // PRODFP width no to be exceeded
                    height: 100%;

                }
                .swiper-slide.has_video .block_vid {
                    position: relative;
                    display: block;
                    width: 100%;
                    height: 0;
                    padding-bottom: calc((896 / 596) * 100%);
                    iframe {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                    }
                }
            }
            //NAVIGATION MINIATURE SWIPER
            #visual_scroller_wrapper {
                height: 835px;
                transition: opacity .2s linear;
                max-width: 73px;
                min-width: 73px;
                overflow: scroll;
                -ms-overflow-style: none;
                scrollbar-width: none;

                #btn_video_produit {
                    position: relative;
                    border: 1px solid $primaryColor;
                    height: 110px;
                    &::after {
                        content: "";
                        display: block;
                        width: 40px;
                        height: 40px;
                        background: transparent url(../svg/spritesheet.svg) no-repeat center;
                        @include bgIconCoord(32, 6, 6);
                            top: 34%;
                        left: 26%;
                        position: absolute;
                    }

                    img {
                        opacity: 0;
                    }
                }

                .has_video {
                    width: 100%;
                    height: 100%;
                }

                &::-webkit-scrollbar {
                    display: none;
                }
                @media only screen and (max-width: 1024px) {
                    opacity: 1;
                }
                .swiper-slide {
                    cursor: pointer;

                    background-repeat: no-repeat;
                    img {
                        width: 100%;
                    }
                }
            }
            #visual_scroller_wrapper, .main_vis {
                @media only screen and (max-width: 1680px) {
                    height: 760px;
                }
                @media only screen and (max-width: 1440px) {
                    height: 625px;
                }
                @media only screen and (max-width: 1370px) {
                    height: 760px;
                }
                @media only screen and (max-width: 1290px) {
                    height: 610px;
                }
                @media only screen and (max-width: 1024px) {
                    height: 514px;
                }
                @media only screen and (max-width: 800px) {
                    height: 368px;
                }
            }
        }
        //RIGHT PART
        .product_info_wrapper {
            width: 30%;
            margin-right: 7%;
            padding-top: 6%;

            @media only screen and (max-width: 1440px) {
                width: 45%;
                margin-right: 2%;
                padding-top: 0;
            }

            .product_info {
                position: relative;
            }
            #itm_name {
                font-size: 45px;
                text-transform: uppercase;
                font-weight: 500;
                margin-bottom: 25px;
            }
            .product_eclat_attributes {
                display: flex;
                margin-bottom: 15px;
            }
            .product_eclat_attributes > * {
                margin-left: 1rem;
                &:first-child {
                    margin-left: 0;
                }
            }
            .item_price_wrapper {
                margin-bottom: 30px;
                width: fit-content;
                .item_price {
                    display: flex;
                    flex-direction: row-reverse;
                    justify-content: flex-end;
                    font-size: 22px;
                    font-weight: 400;
                    .price_tag {
                        &.crossed {
                            position: relative;
                            opacity: .5;
                            margin-left: 1rem;
                            &:after {
                                content: '';
                                position: absolute;
                                top: 50%;
                                left: 0;
                                width: 100%;
                                height: 1px;
                                background-color: $primaryColor;
                                opacity: .5;
                            }
                        }
                    }
                }
            }
            // TOGGLE VOIR PLUS
            .txt_content_container {
                font-size: 15px;
                color: $bgPrimaryBtn;
                height: 71px;
                position: relative;
                overflow: hidden;
                line-height: 1.3;
                margin-bottom: 30px;
                &.minlenght {
                    height: unset;
                }
                .see_more_txt {
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    background: white;
                    font-weight: 500;
                    text-decoration: underline;
                    text-underline-position: from-font;
                    height: 30px;
                    display: flex;
                    justify-content: left;
                    align-items: flex-end;
                    .see_more_txt_cta {
                        padding-bottom: 5px;
                        cursor: pointer;
                        letter-spacing: .01rem;
                    }
                }
            }
            .disabledBtnTooltip {
                top: 90%;
                left: -20px;
                color: #FC55B0;
                font-size: .875rem;
                font-weight: 500;
            }
            //TAILLES + COULEURS
            #product_selects {
                margin-bottom: 30px;
                position: unset;
                #ligne_couleur {
                    position: absolute;
                    top: 5px;
                    opacity: 0;
                }
                #ligne_pointure {
                    #taunt_text {
                        position: absolute;
                        bottom: -20px;
                        font-size: 13px;
                        color: #f7499f;
                    }
                }
            }
            .choices_list {
                display: flex;
                align-items: flex-end;
                .block_sizeguide {
                    margin-left: auto;
                    padding-left: 30px;
                    text-align: right;
                    min-width: fit-content;
                    a {
                        font-size: 15px;
                        color: $bgPrimaryBtn;
                        text-decoration: underline;
                        text-underline-position: from-font;
                    }
                }
            }
            .form_itm {
                input[type=radio],
                input[type=checkbox] {
                    display: none;
                    &+label {
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                        &:after {
                            content: '';
                            display: block;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 14px;
                            height: 14px;
                            border: 1px solid transparent;
                            border-radius: 50%;
                            cursor: pointer;
                        }
                    }
                    &:hover+label:after,
                    &:checked+label:after {
                        border-color: $bgPrimaryBtn;
                    }
                }
                &.size {
                    width: 15%;
                    max-width: 70px;
                    height: 33px;
                    background-color: transparent;
                    border: solid 1px $bgPrimaryBtn;
                    transition: background-color .2s linear;
                    margin-left: 12px;
                    &:first-child {
                        margin-left: 0;
                    }
                    label {
                        height: 100%;
                        cursor: pointer;
                        &::after {
                            content: none !important;
                        }
                    }
                    &:hover {
                        color: white;
                        background-color: $bgPrimaryBtn;
                    }
                    &:first-child {
                        margin-left: 0;
                    }
                    input[type=radio] {
                        display: none;
                        &+label {
                            span {
                                position: relative;
                                z-index: 1;
                                cursor: pointer;
                            }
                        }
                        &:checked+label {
                            color: white;
                            background-color: $bgPrimaryBtn;
                            border-radius: 0;
                        }
                        &.disabled {
                            +label {
                                opacity: .3;
                            }
                        }
                    }
                }
                &.color {
                    margin-left: 20px;
                    &:first-child {
                        margin-left: 0 !important;
                    }
                    input[type=radio],
                    input[type=checkbox] {
                        &+label {
                            position: relative;
                            width: 10px;
                            height: 10px;
                            border: 1px solid rgba(0, 0, 0, .1);
                            background-size: cover !important;
                        }
                    }
                }
            }
            //CTA BASKET + WISHLIST + RESA MAG
            #product_addcart {
                display: flex;
                margin-bottom: 20px;
                .prod_btn_wrapper {
                    width: 75%;
                    margin-right: 10px;
                }
                #bloc_add_basket, #bloc_add_alert {
                    height: 50px;

                    input {
                        width: 100%;
                        height: 100%;
                    }

                    .display_select_size {
                        position: absolute;
                        width: 100%;
                        text-align: center;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: $bgPrimaryBtn;
                        cursor: initial;
                    }
                    #btn_add_cart {
                        opacity: 1 !important;
                    }
                    .loader, #btn_add_alert {
                        height: 100%;
                        width: 100%;
                    }
                }
                #bloc_add_alert #mail_alerte_stock {
                    height: 50px;
                }
                #addToWishlistButtonContainer {
                    width: 25%;
                    #addToWishlistButton {
                        height: 100%;
                        width: 100%;
                        font-size: 0;
                        .wish_white {
                            &::before {
                                width: 25px;
                                height: 25px;
                            }
                        }
                        &.existToWishlistButton .wish_white::before {
                            background: $bgPrimaryBtn url(../img/wish_basket_black.gif) no-repeat center;
                        }
                    }
                }

                // Précommande
                &.with_preco {
                    margin-bottom: 10px;

                    & + .roll_preco {
                        font-size: 13px;
                        margin-bottom: 10px;
                    }
                }
            }
            #bloc_reserve_instore {
                margin-bottom: 30px;
                #btn_resa_shop {
                    width: 100%;
                    height: 50px;
                }
            }
            // CARACTERISTIQUES DU PRODUIT
            .product_feats_wrapper {
                .itm_spec {
                    color: $bgPrimaryBtn;
                    .carac_container {
                        border-top: solid 1px rgba(0, 11, 33, .1);
                        &:last-child {
                            border-bottom: solid 1px rgba(0, 11, 33, .1);
                        }
                        strong {
                            font-size: 18px;
                            text-transform: uppercase;
                            cursor: pointer;
                            display: block;
                            padding-top: 20px;
                            padding-bottom: 20px;
                            position: relative;
                            font-size: 400;
                            &::after {
                                content: '';
                                border: solid #000b21;
                                border-width: 0 1px 1px 0;
                                display: inline-block;
                                padding: 3px;
                                transform: rotate(45deg);
                                position: absolute;
                                right: 0;
                                bottom: 25px;
                                transition: all .2s linear;
                            }
                            &.toggled::after {
                                transform: rotate(225deg);
                            }
                        }
                        .carac_container_detail {
                            display: none;
                            padding-bottom: 30px;
                            line-height: 1.2;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
    /* Effet fluide et continu du swiper en autoplay */
    .reinsuranceMarquee .swiper-wrapper {
        margin-bottom: 30px;
        .swiper-slide {
            font-size: 18px;
            width: fit-content !important;
            margin-right: 25px;
            white-space: nowrap;

            &:nth-child(3n+1) {
                color: $pink;
            }
            &:nth-child(3n+2) {
                color: $blue;
            }
            &:nth-child(3n+3) {
                color: $yellow;
            }
        }
    }
}


//PRODUCTS ASSOCIATION
.product_associations_wrapper {
    width: 98%;
    max-width: 1920px;
    margin: 0 auto;
    margin-bottom: 55px;
    margin-top: 75px;
    h3 {
        font-size: 35px;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 25px;
    }

    #associations_gondole, #productVisitedSwiper {
        display: flex;
        .item {
            width: calc(25% - 19px);
            position: relative;

            .block_lnk {
                text-decoration: none;
                //RECHERCHE IMG
                .imgProd {
                    width: 100%;
                    height: auto;
                }
            }
            .imgWrapper {
                position: relative;
                //Si le produit n'a pas de stock
                &.reminder_on {
                    .iframe_container, .rolloverimgContainer {
                        opacity: .5;
                    }
                }
                //Eclats
                .eclat_rect, .eclat_rond {
                    position: absolute;
                    z-index: 10;
                    left: 10px;
                    top: 10px;

                    img {
                        display: block;

                        +img {
                            margin-top: 7px;
                        }
                    }
                }
                .eclat_rond {
                    top: 40px;
                }
                //Visuels - rollover
                .rolloverimgContainer {
                    position: relative;
                    span img {
                        width: 100%;
                    }
                    span:nth-child(2) {
                        position: absolute;
                        opacity: 0;
                        top: 0;
                        left: 0;
                        width: 100%;
                        transition: opacity .2s linear;
                    }
                    &:hover span:nth-child(2) {
                        opacity: 1;
                    }
                }
            }
            .wrap_rolloverproduit {
                position: absolute;
                z-index: 1;
                opacity: 0;
                transition: opacity .3s ease-in-out;
            }
            &:hover {
                .wrap_rolloverproduit {
                    opacity: 1;
                }
            }
            .wrap_rolloverproduit {
                bottom: 2%;
                margin: 0 auto;
                width: 93%;
                left: 3.5%;
                .titre_choices_list {
                    display: none;
                }
                form {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    background: white;
                    .rollover_left {
                        flex-direction: column;
                        width: calc(100% - 35px);
                        padding-left: 20px;
                        height: 80px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-evenly;
                        @media screen and(max-width: 1280px) {
                            padding-left: 10px;
                            width: calc(85% - 35px);
                        }
                        .productErrorFieldset {
                            font-size: 12px;
                            color: red;
                        }
                    }
                    .choices_list {
                        display: flex;
                    }
                    .bloc_add_color {
                        position: relative;
                        display: block;
                        .w-submit-button {
                            font-size: 15px;
                            color: black;
                            padding-bottom: 15px;
                            padding-right: 20px;
                            margin-left: 10px;
                            background: transparent;
                            @media screen and(max-width: 1280px) {
                                font-size: 12px;
                                margin-left: 5px;
                            }
                            span {
                                text-decoration: underline;
                                text-transform: capitalize;
                                text-underline-position: from-font;
                                &::after {
                                    content: none;
                                }
                            }
                            &:hover {
                                background: none;
                            }
                        }
                        button.load_product {
                            display: none;
                        }
                        &.loading {
                            .w-submit-button,
                            button.w-submit-button {
                                background-color: transparent !important;
                                color: white !important;
                            }
                            .button.loader {
                                display: block;
                                background: transparent url(../svg/three_dots.svg) no-repeat center !important;
                                background-size: auto 28% !important;
                            }
                        }
                    }
                    .wishlistBtn {
                        position: absolute;
                        top: 11px;
                        right: 20px;
                        font-size: 0;
                        width: 17px;
                        height: 17px;
                        content: '';
                        background: transparent url(../svg/spritesheet.svg) no-repeat center;
                        background-size: contain;
                        @include bgIconCoord(4, 6, 6);

                        &.existToWishlistButton {
                            @include bgIconCoord(30, 6, 6);
                        }
                    }
                    .w-submit-button,
                    button.w-submit-button {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: transparent;
                        font-size: 0;
                        width: 100%;
                        height: 100%;
                        border: 0;
                        span:after {
                            content: '';
                            display: block;
                            width: 24px;
                            height: 24px;
                        }
                    }
                    .button.loader {
                        display: none;
                        position: absolute;
                        z-index: 1;
                        top: 0;
                        left: 0;
                        appearance: none;
                        border: 0;
                        width: 100%;
                        height: 100%;
                        background: transparent url(../svg/three_dots.svg) no-repeat center;
                        background-size: 80% auto;
                    }
                    //Box alerte stock - email
                    .expressAlertStock {
                        width: 100%;
                        padding: 1rem;
                        .alert_stock {
                            font-size: 14px;
                            font-weight: 500;
                            margin-bottom: .5rem;
                        }
                        .w-input-container {
                            width: calc(100% - 80px);
                            height: 40px;
                            .w-input-element:not([type=radio]):not([type=checkbox]) {
                                width: 100%;
                                height: 40px;
                                padding: 0 .5rem;
                                box-sizing: border-box;
                                &.inputErr {
                                    border-color: red;
                                }
                            }
                        }
                        .form_submit {
                            width: 80px;
                            height: 40px;
                            button {
                                width: 100%;
                                height: 100%;
                                appearance: none;
                                border: 1px solid black;
                                background-color: black;
                                color: white;
                                font-size: 12px;
                            }
                        }
                        a.alert_return {
                            position: absolute;
                            top: .5rem;
                            right: .5rem;
                            width: 14px;
                            height: 14px;
                            cursor: pointer;
                            font-size: 0;
                            &:before,
                            &:after {
                                position: absolute;
                                left: 7px;
                                content: ' ';
                                height: 14px;
                                width: 1px;
                                background-color: black;
                            }
                            &:before {
                                transform: rotate(45deg);
                            }
                            &:after {
                                transform: rotate(-45deg);
                            }
                        }
                        .bloc_add_alert_confirmation {
                            position: unset;
                            font-size: 14px;
                            > p {
                                position: unset;
                                width: unset;
                            }
                        }
                    }
                }
                .form_itm {
                    input[type=radio],
                    input[type=checkbox] {
                        display: none;
                        &+label {
                            position: relative;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 50%;
                            &:after {
                                content: '';
                                display: block;
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                width: 14px;
                                height: 14px;
                                border: 1px solid transparent;
                                border-radius: 50%;
                                cursor: pointer;
                            }
                        }
                        &:hover+label:after,
                        &:checked+label:after {
                            border-color: $bgPrimaryBtn;
                        }
                    }
                    &.color {
                        input[type=radio],
                        input[type=checkbox] {
                            &+label {
                                position: relative;
                                width: 10px;
                                height: 10px;
                                border: 1px solid rgba(0, 0, 0, .1);
                            }
                        }
                    }
                    &.size {
                        width: 15%;
                        height: 23px;
                        background-color: transparent;
                        border: solid 1px black;
                        transition: background-color .2s linear;
                        label {
                            height: 100%;
                            cursor: pointer;
                            &::after {
                                content: none !important;
                            }
                        }
                        &:hover {
                            color: white;
                            background-color: black;
                        }
                        &:first-child {
                            margin-left: 0;
                        }
                        input[type=radio] {
                            display: none;
                            &+label {
                                span {
                                    position: relative;
                                    z-index: 1;
                                    cursor: pointer;
                                }
                            }
                            &:checked+label {
                                color: white;
                                background-color: black;
                                border-radius: 0;
                            }
                        }
                    }
                    &+.form_itm {
                        margin-left: 4%;
                    }
                }
            }
            //Titre du produit + prix
            .wrapper_description {
                display: flex;
                font-family: $primaryFont;
                color: $bgPrimaryBtn;
                font-size: 15px;
                margin-top: 10px;
                margin-bottom: 35px;
                align-items: flex-start;
                .item_name {
                    max-width: 65%;
                }
                .item_subtitle {
                    display: inline-block;
                }
                .item_price {
                    margin-left: auto;
                    display: flex;
                    align-items: center;
                    .pricetag {
                        margin-left: 0;
                        text-decoration: none;
                        +.pricetag {
                            margin-left: 1rem;
                            position: relative;
                            margin-left: 1rem;
                            opacity: .5;
                            display: flex;
                            align-items: center;
                            &:after {
                                content: '';
                                position: absolute;
                                width: 100%;
                                height: 1px;
                                background-color: $primaryColor;
                                opacity: .5;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                            }
                        }
                    }
                }
            }
            //ECLATS DU PRODUIT
            .container_eclats {
                position: absolute;
                z-index: 1;
                left: 10px;
                top: 10px;
                img {
                    display: block;
                    &:first-child {
                        margin-bottom: 7px;
                    }
                }
            }

            // Précommande
            &:has(.add_cart_preco) {
                .wrap_rolloverproduit {
                    form {
                        .bloc_add_color {
                            .w-submit-button {
                                @media screen and(max-width: 1280px) {
                                    padding-left: 0;
                                }

                                span {
                                    @media screen and(max-width: 1280px) {
                                        font-size: 11px;
                                        letter-spacing: 0;
                                    }
                                }
                            }
                        }

                    }
                    .form_itm {
                        &.size {
                            input[type=radio] {
                                & + label {
                                    span {
                                        @media screen and(max-width: 1360px) {
                                            font-size: 12px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    #productVisitedSwiper .item {
        margin-right: 25px;
        &:nth-child(4n) {
            margin-right: 0;
        }
    }

    #associations_gondole + .swiper-pagination .swiper-pagination-bullet-active {
        background: black;
    }
}

//ZOOM
#zoom_box_wrapper {
    cursor: url('../img/cross_productpage.png'), auto;
    .productBigPictures {
        display: flex;
        flex-direction: column;
        > div {
            margin-bottom: -2px;
        }
        img {
            width: 100%;
        }
    }
    .pagination {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        height: 100%;
        top: 0;
        z-index: 40;
        width: 10%;
        .index_zoom {
            height: 75px;
            width: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            a {
                text-decoration: none;
                color: $primaryColor;
                font-weight: 500;
                font-size: 50px;
                &.active {
                    text-decoration: underline;
                    text-underline-position: from-font;
                }
            }
        }
    }
    .swiper-pagination {
        left: 5%;
        position: fixed;
        .swiper-pagination-bullet {
            font-size: 55px;
            width: fit-content;
            background: none;
            opacity: 1;
            margin: unset;
            width: fit-content;
            height: unset;
            position: relative;
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
            &.swiper-pagination-bullet-active::after {
                content: '';
                width: 100%;
                position: absolute;
                background: $bgPrimaryBtn;
                height: 3px;
                bottom: -8px;
                left: 0;
            }
        }
    }
}

//GUIDE DES TAILLES
#sizeguideBox {
    width: 823px;
    background-color: white;
    color: $bgPrimaryBtn;
    height: auto;

    .textguidetuto {
        .tutorialtxt {
            font-family : helveticacond,sans-serif;
            font-weight: 500;
            line-height: 20px;
            text-align : center;
            padding-bottom: 20px;
        }
    }

    .close_pop {
        border: none;
        background: white;
        top: 1.5rem;
        right: 1.5rem;
    }

    #tables_main_wrap {
        padding: 2rem 2rem 2rem 3rem;

        .guide_intro {
            text-align: center;
            margin-bottom: 30px;
            .title {
                font-size: 45px;
                text-transform: uppercase;
                font-weight: bold;
            }
        }

        .textguidetuto {
            padding-right: 35px;
        }
    }

    .tab_content {
        display: none;
        &.actif {
            display: block;
        }
    }

    #wrap_itable {
        min-height: 352px;
        max-height: 352px;
        height: 100%;
        padding-right: 35px;
    }

    .os-scrollbar-track {
        background: rgba(0, 11, 33, .3);
        width: 2px;
    }
    .os-scrollbar-handle {
        background: $bgPrimaryBtn;
        border-radius: 0;
        width: 2px;
    }

    .primary_navbar {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;

        li {
            padding: 0;
            margin: 0;
            margin-right: 70px;
            font-size: 19px;
            font-weight: 500;
            cursor: pointer;
            &:last-child {
                margin-right: 0;
            }
            &.active {
                opacity: .5;
            }
        }
    }

    .taille_content_wrapper,
    .tablesWrapper {
        height: 100%;
    }

    table {
        max-width: 100%;
        width: 100%;

        &:not(:last-of-type) {
            margin-bottom: 30px;
        }
        tr {
            td {
                padding: 15px;
                text-align: center;
                border-right: 1px solid $bgPrimaryBtn;
                border-bottom: 1px solid $bgPrimaryBtn;
                font-size: 16px;
                &:first-of-type {
                    border-left: 1px solid $bgPrimaryBtn;
                    width: 25%;
                }
            }
            &:first-of-type {
                td {
                    border-top: 1px solid $bgPrimaryBtn;
                    font-size: 22px;
                    font-weight: 500;
                    text-transform: uppercase;
                    width: 12%;

                    span {
                        font-weight: 300;
                        text-transform: uppercase;
                        font-size: 13px;
                    }
                }
            }
        }
    }
}

// ALERT STOCK
#keep_alive_stock {
    position: absolute;
    top: 0;
    width: 100%;
    background: white;
}
.w-alertestock-form {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 0;
    .w-form-line {
        margin-bottom: 0;
    }
    .w-input-element:not([type=radio]):not([type=checkbox]),
    .w-nonempty:not(.no-label) .w-input-element:not([type=radio]):not([type=checkbox]):not(textarea),
    .w-nonempty:not(.no-label) input[type=text].w-input-element {
        width: 100%;
        height: 50px;
        padding: 5px 45px 0 .8rem;
        box-sizing: border-box;
        border: solid 1px $bgPrimaryBtn;
    }
    .w-form-line.w-submit {
        position: absolute;
        right: 15px;
        top: 5px;
        width: fit-content;
        .w-submit-button {
            background: white;
            border-left: none;
            color: black;
            border: unset;
            height: 40px;
            span {
                text-transform: capitalize;
                font-weight: 500;
                font-size: 14px;
            }
        }
    }
    .w-nonempty .w-input-label,
    .w-input:not(.w-dropdown) .w-input-element:not([type=radio]):not([type=checkbox]):focus+.w-input-label {
        transform: translateY(-150%);
    }
    .w-input-note.w-input-error {
        top: 95%;
    }
}
.bloc_add_alert_confirmation {
    font-size: 12px;
    position: absolute;
    top: 0;
    width: 100%;
    @media screen and(max-width: 1024px) {
        font-size: 10px;
    }
    p {
        margin-bottom: 0!important;
        position: absolute;
        bottom: 102%;
        width: 140%;
    }
}

//
.product_associations_wrapper #gondole_aimerez {

	.wrapper_description {

        .item_price div, .item_price img {
            margin: 0 .4rem;
        }

        @media screen and (max-width: 1900px) {
            flex-direction: column;

            .item_title {
                margin: 0rem auto 0.4rem;
            }

            .item_price {
                margin: auto;
            }
        }
    }
}
