body.category {
    .innerWrapper {
        position: relative;
    }
}

.wrapper_bandeau.rayon.look {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 50%;
    max-width: 50%;
    height: 22.3vw;
    background-color: #f9ab34;
    margin: 0;
    text-align: left;

    h1 {
        font-size: 90px;
        font-weight: 500;
        color: white;
        text-transform: uppercase;
        letter-spacing: .05rem;
        line-height: 1;
        padding-left: 10%;
        text-align: left;
        margin-bottom: 0;

        @media screen and(max-width: 1300px) {
            font-size: 70px;
        }

        @media screen and(max-width: 1200px) {
            font-size: 55px;
        }

        @media (orientation: portrait) {
            font-size: 40px;
        }
    }

    &.bandoBottom {
        display: none;
    }
}

.rayon_lookbook {
    #scroll_items #list_item {
        width: 100%;
        overflow-x: hidden;
        justify-content: center;
        align-items: center;

        .item_look {
            width: min-content;

            &:first-of-type {
                width: 100%;

                .reveal-anim-elem {
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 50%;
                    height: 22.3vw;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: auto;
                        vertical-align: top;
                    }
                }

                .lookSlider {
                    height: 150px;
                    overflow-x: hidden;

                    .swiper-wrapper {
                        -webkit-transition-timing-function:linear!important;
                        -o-transition-timing-function:linear!important;
                        transition-timing-function:linear!important;
                    }

                    h2 {
                        font-size: 60px;
                        letter-spacing: .05rem;
                        line-height: 150px;
                    }
                }
            }

            .lookSlider {
                &.swiper-container-initialized .swiper-slide {
                    width: unset !important;
                }
                
                h2 {
                    span:first-of-type {
                        margin-right: 1rem;
                    }
                }
            }

            p {
                font-size: 17px;
                line-height: 1.2;


                @media (orientation: portrait) {
                    font-size: 15px;
                }
            }

            &#look2,
            &#look4 {
                width: 28%;
            }

            &#look3 {
                width: 43%; 
            }

            @media screen and(max-width: 1440px) {
                &#look2,
                &#look4 {
                    width: 23%;
                }
    
                &#look3 {
                    width: 54%; 
                }
            }

            &#look2,
            &#look4 {
                img {
                    width: 100%;
                    height: auto;
                    vertical-align: top;
                }
            }

            &#look2 {
                .reveal-anim-elem {
                    margin: 0;
                    float: right;
                    padding-right: 3vw;
                }
            }

            &#look4 {
                .reveal-anim-elem {
                    margin: 0;
                    float: left;
                    padding-left: 3vw;
                }
            }

            &#look3 {
                img {
                    width: 100%;
                    height: auto;
                    vertical-align: top;
                }

                h2,
                p {
                    text-align: center;
                }

                h2 {
                    display: flex;
                    flex-direction: column;
                    font-size: 90px;
                    font-weight: 500;
                    text-transform: uppercase;
                    letter-spacing: .05rem;
                    margin-top: 28px;

                    @media screen and(max-width: 1300px) {
                        font-size: 70px;
                    }

                    @media screen and(max-width: 1200px) {
                        font-size: 55px;
                    }

                    @media (orientation: portrait) {
                        font-size: 40px;
                    }
                }

                p {
                    margin: 28px 0 62px;
                }
            }

            &#look5,
            &#look6,
            &#look7 {
                width: 22%;
                max-width: fit-content;

                img {
                    width: 100%;
                    height: auto;
                }
            }

            &#look6 {
                margin: 0 1.2vw;
            }

            &#look8 {
                position: relative;
                width: 100%;
                margin: 70px 0;

                img {
                    width: 100%;
                    height: auto;
                    vertical-align: top;
                }

                .text_content {
                    position: absolute;
                    bottom: 10%;
                    right: 0;
                    width: 40%;
                    padding-right: 10%;
                    display: flex;
                    flex-direction: column;
                }

                .reveal-anim-elem {
                    padding-right: 18vw;
                }

                .lookSlider {
                    margin: 0 0 2rem;
                    font-size: 90px;
                    font-weight: 500;
                    text-transform: uppercase;
                    letter-spacing: .05rem;

                    @media screen and(max-width: 1300px) {
                        font-size: 70px;
                    }

                    @media screen and(max-width: 1200px) {
                        font-size: 55px;
                    }

                    @media (orientation: portrait) {
                        font-size: 40px;
                    }
                }
            }

            &#look9,
            &#look10,
            &#look11,
            &#look12 {
                position: relative;
                width: 24%;

                img {
                    width: 100%;
                    height: auto;
                    vertical-align: top;
                }
            }

            &#look10 {
                margin: 0 1%;
            }

            &#look11 {
                margin-right: 1%;
            }

            &#look13,
            &#look14,
            &#look15 {
                img {
                    width: 100%;
                    height: auto;
                    vertical-align: top;
                }
            }

            &#look13,
            &#look15 {
                width: 36.5%;
            }

            &#look14 {
                width: 27%;
                margin-top: 70px;
            }

            &#look13 {
                position: relative;
                margin-top: 1vw;

                .swiper-wrapper {
                    width: 200%;
                }

                .reveal-anim-elem {
                    width: 60%;
                    max-width: fit-content;
                    margin: 0;
                    float: right;
                    padding-right: 1.4vw;
                }

                .lookSlider,
                p {
                    position: absolute;
                }
                .lookSlider {
                    top: 104%;
                    left: 76%;
                    overflow: inherit;

                    @media (orientation: portrait) {
                        top: 107.5%;
                    }

                    h2 {
                        font-size: 90px;
                        font-weight: 500;
                        text-transform: uppercase;
                        letter-spacing: .05rem;

                        @media screen and(max-width: 1500px) {
                            font-size: 80px;
                        }

                        @media screen and(max-width: 1400px) {
                            font-size: 70px;
                        }

                        @media screen and(max-width: 1200px) {
                            font-size: 55px;
                        }

                        @media (orientation: portrait) {
                            font-size: 40px;
                        }

                        span:first-of-type {
                            display: block;
                            margin-left: -37%;
                        }
                    }
                }

                p {
                    top: 145%;
                    left: 80.5%;
                    width: 400px;

                    @media screen and(max-width: 1700px) {
                        top: 150%;
                    }

                    @media (orientation: portrait) {
                        top: 144%;
                        width: 300px;
                    }
                }
            }

            &#look14 {
                img {
                    width: 100%;
                    height: auto;
                    vertical-align: top;
                }
            }

            &#look15 {
                margin-top: -6vw;

                .reveal-anim-elem {
                    width: 45%;
                    max-width: fit-content;
                    margin: 0;
                    float: left;
                    padding-left: 1.4vw;
                }
            }

            &#look18,
            &#look19 {
                width: 50%;
                margin: 18.2vw 0 0;
                box-sizing: border-box;

                @media screen and(max-width: 1600px) {
                    margin: 20.5vw 0 0;
                }

                @media screen and(max-width: 1500px) {
                    margin: 19.8vw 0 0;
                }

                @media screen and(max-width: 1400px) {
                    margin: 21.2vw 0 0;
                }

                @media screen and(max-width: 1200px) {
                    margin: 25vw 0 0;
                }

                @media (orientation: portrait) {
                    margin: 33vw 0 0;
                }

                .reveal-anim-elem {
                    width: 51%;
                    max-width: fit-content;

                    img {
                        width: 100%;
                        height: auto;
                        vertical-align: top;
                    }
                }
            }

            &#look18 {
                padding-right: .5%;

                .reveal-anim-elem {
                    margin: 0;
                    float: right;
                }
            }

            &#look19 {
                padding-left: .5%;

                .reveal-anim-elem {
                    margin: 0;
                    float: left;
                }
            }

            &#look16,
            &#look17 {
                position: absolute;
            }

            &#look16 {
                bottom: 21.2vw;
                left: 0;
                width: 23%;
                max-width: fit-content;

                img {
                    width: 100%;
                    height: auto;
                    vertical-align: top;
                }
            }

            &#look17 {
                z-index: 2;
                bottom: 6.8%;
                right: 4.3%;
                width: 31%;
                max-width: fit-content;

                @media screen and(max-width: 1500px) {
                    bottom: 5.8%;
                }

                img {
                    width: 100%;
                    height: auto;
                    vertical-align: top;
                }
            }

            .reveal-anim-elem {
                position: relative;
                width: fit-content;
                margin: 0 auto;

                .tag {
                    position: absolute;
                    z-index: 5;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 25px;
                    height: 25px;
                    background-color: $pink;
                    border-radius: 50%;
                    font-size: 13px;
                    text-decoration: none;
                    color: white;

                    &:after {
                        content: '+';
                        font-family: 'Times New Roman', Times, serif;
                        font-size: 25px;
                        color: white;
                    }

                    &:hover .tag_tooltip {
                        display: block;
                    }
                }

                .tag_tooltip {
                    display: none;
                    position: absolute;
                    top: 50%;
                    left: 50%;

                    img {
                        width: 120px !important;
                        height: auto;
                        vertical-align: top;
                    }
                }

                .prodTitle {
                    white-space: nowrap;
                    margin-top: .5rem;
                }

                .price_wrapper {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin-top: .2rem;

                    .pricetag {
                        position: relative;
                    }

                    .pricetag+.pricetag:after {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        width: 100%;
                        height: 1px;
                        background-color: white;
                    }
                }
            }
        }
    }
}