.home-module {
    margin: 0 auto 68px;

    &.multiple-bloc-2-blocs {
        margin-bottom: 0 !important;
    }

    &:last-child {
        margin-bottom: 0;
    }

    span.ill_img {
        position: relative;
        display: block;
        width: 100%;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: auto;
            left: 50%;
            transform: translateX(-50%);
            height: 100%;
        }
    }

    .slide_video {
        position: relative;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .cta_video_redirect{
            display: block!important;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}

/**
 * COVER MODULE
 */
.coverSlider {
    width: 100%;
    max-width: 1920px;

    .cta_video_redirect {
        display: block !important;
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        max-height: 95vh;

        a {
            display: flex;
        }

        img {
            display: flex;
            width: 100%!important;
            height: auto;
            position: relative!important;
        }
    }

    .slide_video {
        padding-bottom: calc(923 / 1950 * 100%);
    }

    .swiper-lazy-preloader,
    .home-module-bg,
    .home-module-contents {
        position: relative;
        top: 0;
        left: 0;
        transform: unset;
    }

    .swiper-lazy-preloader {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        margin-left: 0;
        margin-top: 0;
        transform: none;
        animation: none;

        &:after {
            width: 150px;
            height: 150px;
        }
    }

    .home-module-bg {
        width: 100%;
        height: auto;
        vertical-align: top;
    }

    .home-module-contents {
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
        white-space: nowrap;
        font-size: 90px;
        font-weight: 500;
        text-transform: uppercase;
        text-align: center;
        letter-spacing: .05rem;
        position: absolute;

        @media screen and(max-width: 1300px) {
            font-size: 80px;
        }

        &.align-left {
            padding-left: 6rem;
            box-sizing: border-box;
            text-align: left;
        }

        &.align-center {
            text-align: center;
        }

        &.align-right {
            text-align: right;
            padding-right: 6rem;
            box-sizing: border-box;
        }

        &.align-top {
            top: 7rem;
        }

        &.align-middle {
            left: 50%;
        }

        &.align-bottom {
            bottom: 3rem;
            top: unset;
        }
    }
}

.cover-module {
    .swiper-slide {
        a {
            display: flex;
        }
    }
}

/**
 * 4 BLOCS
 */
.home-module.multiple-bloc-4-blocs {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 98%;

    .categ {
        width: 24%;
        max-width: 306px;

        & > a {
            display: block;
            width: 100%;

            img {
                width: 100%;
                height: auto;
            }
        }

        &+.categ {
            margin-left: 1%;
        }
    }

    .ill_img,
    .slide_video {
        padding-bottom: calc(346 / 306 * 100%);
    }

    h3 {
        text-align: center;
        margin-top: .8rem;
        font-size: 18px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: .04rem;

        a {
            color: $primaryColor;
            text-decoration: none;
        }
    }
}

/**
 * 2 IMAGES – 1 TEXTE
 */
.home-module.two-images-texte {
    width: 100%;
    max-width: 1920px;
    position: relative;
    overflow: hidden;
    margin-bottom: 0;
    article {
        width: 100%;
        max-width: 1920px;

        span.ill_img,
        .slide_video {
            padding-bottom: calc(737 / 1921 * 100%);
        }

        &+article {
            position: absolute;
            top: 50%;
            right: 12%;
            transform: translateY(-50%);
            width: 18.1%;
            max-width: 347px;

            a{
                display: flex;
            }

            span.ill_img {
                padding-bottom: calc(465 / 347 * 100%);
            }
        }
    }

    .title1,
    .title2 {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
        font-size: 90px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: .05rem;

        @media screen and(max-width: 1500px) {
            font-size: 70px;
        }

        @media screen and(max-width: 1300px) {
            font-size: 50px;
        }
    }

    .title1 {
        top: -1.1em;
    }

    .title2 {
        bottom: -1.1em;
    }
}

/**
 * 2 BLOCS
 */
.home-module.multiple-bloc-2-blocs {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    max-width: 1920px;

    @media screen and(max-width: 1200px) {
        flex-direction: column;
    }

    a {
        width: 50%;
        text-decoration: none;

        @media screen and(max-width: 1200px) {
            width: 100%;
        }
    }

    .categ {
        position: relative;
    }

    span.ill_img,
    .slide_video {
        padding-bottom: calc(736 / 959 * 100%);

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            left: 50%;
            transform: translateX(-50%);
            height: 100%;
        }

        img {
            box-sizing: border-box;
        }
    }

    p,
    h3,
    button {
        position: absolute;
    }

    h3 {
        bottom: 22%;
        width: 100%;
        font-size: 35px;
        font-weight: 500;
        text-transform: uppercase;
        text-align: center;
        letter-spacing: .05rem;

        @media screen and(max-width: 1300px) {
            bottom: 25%;
        }
    }

    button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 210px;
        height: 50px;
        bottom: 10%;
        left: 50%;
        transform: translateX(-50%);
        appearance: none;
        border: 0;
    }
}

/**
 *
 */
.home-module.social-module {
    position: relative;
    width: 98%;
    max-width: 1440px;

    @media screen and(max-width: 1200px) {
        width: 100%;
    }

    .home-module-link {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
    }

    .text_content {
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        font-size: 130px;
        font-weight: 500;
        text-transform: uppercase;
        text-decoration: none;
        letter-spacing: .05rem;

        @media screen and(max-width: 1200px) {
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .socialSwipers {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 72%;

        @media screen and(max-width: 1200px) {
            width: 100%;
        }

        .swiper-container {
            width: 47%;
            height: 690px;
            margin: 0;

            .swiper-wrapper {
                transition-timing-function: linear!important;
                height: 315px;
            }

            .swiper-slide {
                overflow: hidden;
            }

            img {
                width: 100%;
                height: auto;
                vertical-align: middle;
            }
        }
    }
}

/**
 * PAGE INACTIVE
 */
.images-signup-module {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    max-width: 1920px;

    @keyframes fadeIn {
        0% { opacity: 0; }
        50% { opacity: 0; }
        100% { opacity: 1; }
    }

    article {
        height: 80vh;

        &:first-child {
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            width: 55%;
            max-width: 1030px;
            animation-duration: 1s;
            animation-name: fadeIn;
        }

        &:last-child {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            width: 45%;
            padding: 0 0 65px 6%;
            box-sizing: border-box;
            height: auto;
        }

        strong {
            font-weight: 700;
        }
    }

    .leftCol,
    .rightCol {
        width: calc(50% - 35px);
        height: 100%;
        overflow: hidden;

        @media screen and(max-width: 1500px) {
            width: calc(50% - 20px);
        }
    }

    .swiper-container {
        height: 100%;
    }

    .swiper-wrapper {
        transition-timing-function: linear!important;

        .swiper-slide {
            background-size: cover !important;

            img {
                opacity: 0;
                width: auto;
                height: 100%;
                vertical-align: top;
            }
        }
    }

    .home-module-title {
        width: 90%;
        max-width: 480px;
        font-size: 90px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: .1rem;
        line-height: 1.2;
        margin-left: -.3rem;

        @media screen and(max-width: 1400px) {
            font-size: 80px;
        }

        @media screen and(max-width: 1300px) {
            font-size: 70px;
        }

        @media screen and(max-width: 1200px) {
            font-size: 60px;
        }
    }

    .home-module-text {
        width: 90%;
        font-size: 15px;
        line-height: 1.2;
        margin: 10px 0 30px;
    }

    .w-newsletter-form {
        width: 90%;
        max-width: 572px;

        .w-form-line {
            flex-direction: column;

            &.w-submit {
                max-width: 100%;

                .w-submit-button {
                    width: 100%;
                    height: 50px;
                }
            }
        }

        .w-input-element:not([type=radio]):not([type=checkbox]),
        input[type=email].w-input-element {
            height: 48px;
            padding: 0 1rem;
            border-radius: 0;
            box-sizing: border-box;
        }

        .w-nonempty:not(.no-label) .w-input-element:not([type=radio]):not([type=checkbox]):not(textarea) {
            height: 48px;
        }

        .w-input:not(.w-dropdown) .w-input-element:not([type=radio]):not([type=checkbox]):focus,
        .w-input:not(.w-dropdown) input[type=email].w-input-element:focus {
            padding: 1.5rem .8rem .4rem;
        }

        .w-form-line.w-submit {
            position: inherit;
            width: 100%;
            height: 50px;
        }
    }
}

#coverSlider {
    @media screen and (max-width: 1100px) {
        margin-bottom: 0rem;
    }
}

.home-module{
    @media screen and (max-width: 1100px) {
        margin-top: 0rem;
    }
}

#coverSlider .swiper-slide {
    @media screen and (max-width: 1440px) {
        height: 80vh;
    }
}