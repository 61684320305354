.category,
.search_in,
.dynasearchwrapper {
    .wrapper_bandeau {
        text-align: center;
        margin: 0 auto;
        margin-top: 35px;
        margin-bottom: 20px;

        @media screen and(max-width: 1024px) {
            max-width: unset;
            width: 85%;
        }

        .title {
            width: 100%;
            text-transform: uppercase;
            text-align: center;
            color: $bgPrimaryBtn;
            font-size: 90px;
            font-weight: 500;
            margin-bottom: 20px;
            letter-spacing: 0.1rem;
        }
    }

    //TXT SEO
    .txt_content_container {
        font-size: 15px;
        color: $bgPrimaryBtn;
        height: 71px;
        position: relative;
        max-width: 770px;
        margin: 0 auto;
        overflow: hidden;
        line-height: 1.3;

        .txt_content a {
            color: $bgPrimaryBtn;
        }

        &.minlenght {
            height: unset;
        }

        .see_more_txt {
            position: absolute;
            bottom: 0;
            width: 100%;
            background: white;
            font-weight: 500;
            text-decoration: underline;
            text-underline-position: from-font;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: flex-end;

            .see_more_txt_cta {
                padding-bottom: 5px;
                cursor: pointer;
                letter-spacing: 0.01rem;
            }
        }
    }

    //ROLLOVER - Taille, Couleur, Wishlist, Achat
    .wrap_rolloverproduit {
        bottom: 2%;
        margin: 0 auto;
        width: 93%;
        left: 3.5%;

        .titre_choices_list {
            display: none;
        }

        form {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            background: white;

            .rollover_left {
                flex-direction: column;
                width: calc(100% - 35px);
                padding-left: 20px;
                height: 80px;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;

                @media screen and(max-width: 1280px) {
                    padding-left: 10px;
                    width: calc(85% - 35px);
                }

                .productErrorFieldset {
                    font-size: 12px;
                    color: $pink_dark;
                }
            }

            .choices_list {
                display: flex;
                flex-direction: row;
            }

            .bloc_add_color {
                position: relative;
                display: block;

                .w-submit-button {
                    font-size: 15px;
                    color: black;
                    padding-bottom: 15px;
                    padding-right: 20px;
                    margin-left: 10px;
                    background: transparent;

                    @media screen and(max-width: 1280px) {
                        font-size: 12px;
                        margin-left: 5px;
                    }

                    span {
                        text-decoration: underline;
                        text-transform: capitalize;
                        text-underline-position: from-font;
                        color: $bgPrimaryBtn;

                        &::after {
                            content: none;
                        }
                    }

                    &:hover {
                        background: none;
                    }

                    &.add_cart_preco {
                        span {
                            text-wrap: nowrap;
                        }
                    }
                }

                button.load_product {
                    display: none;
                }

                &.loading {

                    .w-submit-button,
                    button.w-submit-button {
                        background-color: transparent !important;
                        color: white !important;
                    }

                    .button.loader {
                        display: block;
                    }
                }
            }

            .wishlistBtn {
                position: absolute;
                top: 11px;
                right: 15px;
                font-size: 0;
                width: 19px;
                height: 19px;
                content: '';
                background: transparent url(../svg/spritesheet.svg) no-repeat center;
                background-size: contain;
                @include bgIconCoord(4, 6, 6);

                &.existToWishlistButton {
                    @include bgIconCoord(30, 6, 6);
                }
            }

            .w-submit-button,
            button.w-submit-button {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: transparent;
                font-size: 0;
                width: 100%;
                height: 100%;
                border: 0;

                span:after {
                    content: '';
                    display: block;
                    width: 24px;
                    height: 24px;
                }
            }

            .button.loader {
                display: none;
                position: absolute;
                z-index: 1;
                top: -5px;
                left: 0;
                appearance: none;
                border: 0;
                width: 100%;
                height: 100%;
                background: $white url(../svg/three_dots.svg) no-repeat center;
                background-size: auto 28%;
            }

            //Box alerte stock - email
            .expressAlertStock {
                width: 100%;
                padding: 1rem;

                .alert_stock {
                    font-size: 14px;
                    font-weight: 500;
                    margin-bottom: 0.5rem;
                }

                .w-input-container {
                    width: calc(100% - 80px);
                    height: 40px;

                    .w-input-element:not([type='radio']):not([type='checkbox']) {
                        width: 100%;
                        height: 40px;
                        padding: 0 0.5rem;
                        box-sizing: border-box;

                        &.inputErr {
                            border-color: red;
                        }
                    }
                }

                .form_submit {
                    width: 80px;
                    height: 40px;

                    button {
                        width: 100%;
                        height: 100%;
                        appearance: none;
                        border: 1px solid black;
                        background-color: black;
                        color: white;
                        font-size: 12px;
                    }
                }

                a.alert_return {
                    position: absolute;
                    top: 0.5rem;
                    right: 0.5rem;
                    width: 14px;
                    height: 14px;
                    cursor: pointer;
                    font-size: 0;

                    &:before,
                    &:after {
                        position: absolute;
                        left: 7px;
                        content: ' ';
                        height: 14px;
                        width: 1px;
                        background-color: black;
                    }

                    &:before {
                        transform: rotate(45deg);
                    }

                    &:after {
                        transform: rotate(-45deg);
                    }
                }
            }

            .btnAddBasketWrapper {
                width: 100%;
                padding: 15px;

                .alert_stock {
                    font-size: 14px;
                    font-weight: 500;
                    margin-bottom: 0.5rem;
                }

                .w-input-container {
                    width: calc(100% - 80px);
                    height: 40px;

                    .w-input-element:not([type='radio']):not([type='checkbox']) {
                        width: 100%;
                        height: 40px;
                        padding: 0 0.5rem;
                        box-sizing: border-box;

                        &.inputErr {
                            border-color: red;
                        }
                    }
                }

                .form_submit {
                    width: 80px;
                    height: 40px;

                    button {
                        width: 100%;
                        height: 100%;
                        appearance: none;
                        border: 1px solid $primaryColor;
                        background-color: $primaryColor;
                        color: white;
                        font-size: 12px;
                    }
                }

                a.alert_return {
                    position: absolute;
                    top: 0.5rem;
                    right: 0.5rem;
                    width: 14px;
                    height: 14px;
                    cursor: pointer;
                    font-size: 0;

                    &:before,
                    &:after {
                        position: absolute;
                        left: 7px;
                        content: ' ';
                        height: 14px;
                        width: 1px;
                        background-color: black;
                    }

                    &:before {
                        transform: rotate(45deg);
                    }

                    &:after {
                        transform: rotate(-45deg);
                    }
                }

                .bloc_add_alert_confirmation {
                    font-size: unset;
                    position: unset;
                    width: unset;

                    >p {
                        width: unset;
                        position: unset;
                    }
                }
            }
        }

        .form_itm {

            input[type='radio'],
            input[type='checkbox'] {
                display: none;

                &+label {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;

                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 14px;
                        height: 14px;
                        border: 1px solid transparent;
                        border-radius: 50%;
                        cursor: pointer;
                    }
                }

                &:hover+label:after,
                &:checked+label:after {
                    border-color: $bgPrimaryBtn;
                }
            }

            &.color {

                input[type='radio'],
                input[type='checkbox'] {
                    &+label {
                        position: relative;
                        width: 10px;
                        height: 10px;
                        border: 1px solid rgba(0, 0, 0, 0.1);
                        background-size: cover !important;
                    }
                }
            }

            &.size {
                flex: 1;
                height: 22px;
                max-width: 65px;
                background-color: transparent;
                border: solid 1px $bgPrimaryBtn;
                transition: background-color 0.2s linear;

                label {
                    height: 100%;
                    cursor: pointer;

                    &::after {
                        content: none !important;
                    }
                }

                &:hover {
                    color: white;
                    background-color: $bgPrimaryBtn;
                }

                &:first-child {
                    margin-left: 0;
                }

                input[type='radio'] {
                    display: none;

                    &+label {
                        span {
                            position: relative;
                            z-index: 1;
                            cursor: pointer;
                        }
                    }

                    &:checked+label {
                        color: white;
                        background-color: $bgPrimaryBtn;
                        border-radius: 0;
                    }
                }

                .input_check.disabled+label span {
                    opacity: 0.5;
                }
            }

            &+.form_itm {
                margin-left: 4%;
            }
        }
    }
}

//PRODUITS
#scroll_items #list_item {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    overflow: hidden;
    background-color: white;
    margin-bottom: 55px;

    @media screen and(max-width: 1024px) {
        justify-content: space-between;
        width: 98%;
    }

    .item:not(.push) {
        width: calc(25% - 24px);
        margin-left: 12px;
        margin-right: 12px;
        position: relative;

        @media screen and(max-width: 1024px) {
            //On met 2 produits par ligne en format tablette
            width: calc(50% - 5px);
            position: relative;
            margin-right: 0px;
            margin-left: 0px;
        }

        @media screen and (max-width: 1023px) {

            //On masque le rollover en format tablette
            .wrap_rolloverproduit {
                display: none;
            }
        }

        .block_lnk {
            text-decoration: none;

            //RECHERCHE IMG
            .itm_vis {
                width: 100%;
                height: auto;
            }
        }

        .imgWrapper {
            position: relative;

            //Si le produit n'a pas de stock
            &.reminder_on {

                .iframe_container,
                .rolloverimgContainer {
                    opacity: 0.5;
                }
            }

            //Visuels - rollover
            .rolloverimgContainer {
                position: relative;

                &:hover {
                    .product_wrapper {
                        opacity: 1;

                        .productVisualMulti {
                            .rollover_rayonsPrev {
                                left: 1rem;
                            }

                            .rollover_rayonsNext {
                                right: 1rem;
                            }
                        }
                    }

                    // Hide the first image (When there is a swiper only)
                    .first_image:not(.no_swiper_visu) {
                        opacity: 0;
                        transition: all 1s;
                    }
                }

                span img {
                    width: 100%;
                }

                // Hide preloader if no swiper
                .first_image.no_swiper_visu+.product_wrapper .lazy-preloader {
                    display: none;
                }

                .product_wrapper {
                    position: absolute;
                    opacity: 0;
                    top: 0;
                    left: 0;
                    width: 100%;
                    transition: opacity 0.2s linear;

                    .lazy-preloader {
                        width: 70px;
                        height: 50px;
                        z-index: 0;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        background-image: url(../svg/three_dots.svg);
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 100%;
                    }

                    .productVisualMulti {
                        .swiper-slide {
                            img {
                                width: 100%;
                                height: auto;
                            }
                        }

                        .rollover_rayonsPrev,
                        .rollover_rayonsNext {
                            position: absolute;
                            z-index: 2;
                            top: 50%;
                            @include bgIconPath('../svg/spritesheet.svg');
                            @include bgIconCoord(29, $col, $row);
                            width: 1rem;
                            height: 1rem;
                            cursor: pointer;
                            user-select: none;
                            transition: all 0.3s ease-in-out, opacity 0s ease-in-out;

                            &:focus {
                                outline: 0;
                            }

                            &.swiper-button-disabled {
                                opacity: 0;
                            }
                        }

                        .rollover_rayonsPrev {
                            left: -1rem;
                            transform: translateY(-50%) rotate(90deg);
                        }

                        .rollover_rayonsNext {
                            right: -1rem;
                            transform: translateY(-50%) rotate(-90deg);
                        }
                    }
                }
            }

            //Video
            .iframe_container {
                position: relative;
                display: block;
                width: 100%;
                height: 0;
                padding-bottom: calc((896 / 596) * 100%);

                iframe {
                    position: absolute;
                    z-index: 0;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                }

                .link_product_iframe {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                }
            }
        }

        .wrap_rolloverproduit {
            position: absolute;
            z-index: 1;
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
        }

        &:hover {
            .wrap_rolloverproduit {
                opacity: 1;
            }
        }

        //Titre du produit + prix
        .wrapper_description {
            display: flex;
            font-family: $primaryFont;
            color: $bgPrimaryBtn;
            font-size: 15px;
            margin-top: 10px;
            margin-bottom: 35px;

            .item_name {
                max-width: 65%;
            }

            .item_price {
                margin-left: auto;
                display: flex;
                flex-direction: row-reverse;

                .pricetag {
                    margin-left: 0;
                    text-decoration: none;

                    &.crossed {
                        margin-left: 1rem;
                        text-decoration: line-through;
                        opacity: 0.5;
                    }
                }
            }
        }

        //ECLATS DU PRODUIT
        .container_eclats {
            position: absolute;
            z-index: 2;
            left: 10px;
            top: 10px;

            img {
                display: block;

                &:first-child {
                    margin-bottom: 7px;
                }
            }
        }
    }

    .push {
        text-decoration: none;
        width: 98%;
        margin: 0 auto;
        margin-bottom: 35px;

        @media screen and(max-width: 1024px) {
            width: 100%;
        }

        .wrapper_img img {
            width: 100%;
        }

        .infos {
            margin-top: 10px;

            .cta_content {
                font-family: $primaryFont;
                color: $bgPrimaryBtn;
                font-size: 15px;
            }

            .taunt_click {
                display: none;
            }
        }
    }

    .push_video {
        width: 98%;
        margin: 0 auto;
        margin-bottom: 35px;

        @media screen and(max-width: 1024px) {
            width: 100%;
        }

        .vimeo_video_ctn {
            padding-bottom: calc(555 / 1927 * 100%);
            position: relative;

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }

        .video_redirect_url {
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }
}

.wrap_flitre_pager {
    position: relative;

    .loader_scroll {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -30px;
        width: 150px;
        height: 30px;
        background-size: 40%;
        margin: 0 auto;

        &.loader {
            opacity: 0;
            z-index: 2;
            background: transparent;
            border: none;
            background: url(../svg/three_dots_b.svg) no-repeat center;
            background-size: auto 28%;
            margin: auto;

            &.loading {
                display: block;
                opacity: 1;
            }
        }
    }
}

.nbProdDisplayed {
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
}

.loadedGauge {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    height: 9px;
    width: 150px;
    margin: 0 auto;
    background-color: $white;
    border: 1px solid $black;
    margin-bottom: 40px;

    span {
        display: block;
        background-color: $black;
        margin: 0;
    }

    &.loading {
        overflow: hidden;
        background-color: $white;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 9px;
            width: 150px;
            background: $bgPrimaryBtn url(../svg/three_dots_w.svg) no-repeat center;
            background-size: auto 28%;
        }
    }
}

.loader_scroll_wrapper {
    position: relative;
    display: block;
    width: 100%;
    height: 10px;
    margin: 0 auto;

    .loader_scroll {
        .loader {
            display: block;
            width: 100%;
            height: 10px;
        }
    }
}

/* Pagination */
.pager_wrapper {
    padding-top: 40px;
    text-align: center;

    .see_all_product {
        text-align: center;
    }
}

/**
 * FILTRES
*/
.page_rayon {
    #rayon_sticky_block {
        height: 30px;
        border-top: solid 1px black;
        border-bottom: solid 1px black;
        width: 98%;
        max-width: 1920px;
        margin: 0 auto;
        margin-bottom: 25px;

        #viewChanger {
            display: none;
        }

        #trigger_filtre,
        .close_pop,
        .title {
            display: none;
        }

        #filter_sticky {
            height: 100%;
        }

        .filters_container {
            height: 100%;
            display: flex;
            align-items: center;
            position: relative;

            .show_more_filters_contain {
                position: absolute;
                height: 100%;
                background: white;
                z-index: 5;
                display: flex;
                align-items: center;
                opacity: 1;
                width: 100%;

                @media screen and(min-width: 2000px) {
                    display: none !important;
                }

                .show_more_filters {
                    font-size: 15px;
                    text-transform: uppercase;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    height: 100%;
                    cursor: pointer;
                    padding-left: 15px;
                    opacity: 0;
                }
            }

            //NB products
            .txt_bandeau {
                opacity: 0.5;
                font-size: 13px;
                margin-left: 20px;
                margin-right: 20px;
                text-transform: uppercase;
                position: relative;

                @media screen and(max-width: 900px) {
                    font-size: 12px;
                }

                &::after {
                    content: '';
                    height: 100%;
                    background: black;
                    width: 1px;
                    position: absolute;
                    right: -20px;
                }
            }

            .filter_wrapper,
            .filter_wrapper_last {
                margin-left: 20px;
                height: 100%;
                display: flex;
                align-items: center;
                cursor: pointer;
                position: relative;
                padding-right: 30px;

                &:first-child {
                    margin-left: 40px;
                }

                .filter_name {
                    position: relative;
                    text-transform: uppercase;
                    font-size: 13px;
                    color: $bgPrimaryBtn;
                    display: flex;

                    &.searchPriceFilter {
                        font-size: 15px;
                    }

                    &::after {
                        content: '';
                        border: solid $bgPrimaryBtn;
                        border-width: 0 1px 1px 0;
                        display: inline-block;
                        padding: 3px;
                        transform: rotate(45deg);
                        position: absolute;
                        right: -15px;
                        bottom: 5px;
                        transition: all 0.2s linear;
                    }

                    .nbr_actif {
                        padding-left: 5px;
                    }
                }

                .selected_options {
                    opacity: 0;
                    z-index: -1;
                    top: 30px;
                    left: -20px;
                    background-color: white;
                    padding: 25px 25px 10px 25px;
                    position: absolute;
                    transition: opacity 0.2s linear;
                    cursor: initial;

                    .form_itm.check:not(.size):not(.color) {
                        margin-bottom: 15px;
                        cursor: pointer;
                        display: flex;

                        input {
                            display: none;

                            &:checked+label::after {
                                background-color: $bgPrimaryBtn;
                            }

                            &.active+a::after {
                                background-color: $bgPrimaryBtn;
                            }
                        }

                        label,
                        a {
                            font-weight: 500;
                            position: relative;
                            padding-left: 30px;
                            cursor: pointer;
                            font-size: 15px;
                            white-space: nowrap;

                            &::before {
                                content: '';
                                display: block;
                                width: 19px;
                                height: 19px;
                                border-radius: 50%;
                                position: absolute;
                                border: solid 1px $bgPrimaryBtn;
                                top: -2px;
                                left: 0;
                            }

                            &::after {
                                content: '';
                                position: absolute;
                                width: 15px;
                                height: 15px;
                                border-radius: 50%;
                                background-color: transparent;
                                transition: background-color 0.2s linear;
                                left: 3px;
                                top: 1px;
                            }
                        }

                        a {
                            display: flex;
                            text-decoration: none;
                            color: black;

                            &:hover::after {
                                background-color: $bgPrimaryBtn;
                            }

                            span {
                                white-space: nowrap;
                            }

                            &.actif::after {
                                background-color: $bgPrimaryBtn;
                            }
                        }

                        &:hover label::after {
                            background-color: $bgPrimaryBtn;
                        }
                    }

                    //COLOR INPUT
                    &.colorOptions {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        min-width: 250px;
                        padding: 25px 0px 25px 25px;
                        box-sizing: content-box;
                        padding-top: 9px;

                        .form_itm.check.color {
                            display: flex;
                            align-items: center;
                            margin-top: 1rem;
                            width: 50%;
                            padding: 0px;

                            input {
                                display: none;

                                &:checked+.color_picto::after {
                                    border-color: $bgPrimaryBtn;
                                }
                            }

                            .color_picto {
                                display: block;
                                width: 15px;
                                height: 15px;
                                cursor: pointer;
                                border-radius: 50%;
                                position: relative;
                                //For bg img color
                                min-width: 15px;
                                background-size: cover !important;

                                &::after {
                                    content: '';
                                    position: absolute;
                                    width: 19px;
                                    height: 19px;
                                    border-radius: 50%;
                                    border: solid 1px;
                                    border-color: transparent;
                                    top: -3px;
                                    transition: border-color 0.2s linear;
                                    left: -3px;
                                }

                                &.white_picto {
                                    border: solid 1px gray;
                                }

                                &.multicolor_picto {
                                    background-image: url('../img/couleur/7.jpg');
                                }
                            }

                            .color_txt {
                                cursor: pointer;
                                padding-left: 15px;
                                position: relative;
                                text-transform: uppercase;

                                h3 {
                                    font-weight: 500;
                                }
                            }

                            &:hover .color_picto::after {
                                border-color: $bgPrimaryBtn;
                            }
                        }
                    }

                    //SIZE INPUT
                    &.sizeOptions {
                        display: flex;
                        flex-wrap: wrap;
                        min-width: 231px;
                        padding: 25px 10px 10px 25px;

                        input {
                            display: none;

                            &:checked+label {
                                background-color: $bgPrimaryBtn;
                                color: white;
                            }
                        }

                        label {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;
                            background-color: transparent;
                            transition: background-color 0.2s linear;
                            text-transform: uppercase;

                            &:hover {
                                background-color: $bgPrimaryBtn;
                                color: white;
                            }
                        }

                        .form_itm.check {
                            width: 60px;
                            display: flex;
                            justify-content: center;
                            height: 30px;
                            align-items: center;
                            margin-right: 15px;
                            border: solid 1px black;
                            margin-bottom: 15px;
                        }
                    }
                }

                &:hover {
                    .filter_name {
                        opacity: 0.5;
                    }

                    .filter_name::after {
                        transform: rotate(225deg);
                        bottom: 2px;
                    }

                    .selected_options {
                        opacity: 1;
                        z-index: 15;
                    }
                }

                //TRI PAR PRIX - NOUVEAUTES
                &#filter_trier {
                    margin-left: auto;
                    padding-right: 45px;
                    font-weight: 500;

                    .priceOption {
                        left: -92%;
                    }

                    .form_itm.check span {
                        text-transform: uppercase;
                    }
                }
            }

            //RESPONSIVE FILTERS SIZES
            .filter_wrapper {
                @media screen and(max-width: 1280px) {
                    margin-left: 15px;
                    padding-right: 20px;

                    .filter_name {
                        font-size: 11px;

                        &::after {
                            padding: 2px;
                        }
                    }
                }

                @media screen and(max-width: 900px) {
                    margin-left: 10px;
                    padding-right: 10px;

                    .filter_name {
                        font-size: 8px;

                        &::after {
                            padding: 2px;
                            right: -7px;
                            bottom: 3px;
                        }
                    }
                }
            }
        }
    }
}

//Suppression de l'effet smooth d'apparition des produits - demande client
.ill_img>img {
    animation: none !important;
}

//Min-height pour éviter l'eviter de déplacement du titre produit au changement couleur + saccadement au chargement produit
#scroll_items #list_item .item:not(.push) .imgWrapper .rolloverimgContainer {
    min-height: 38vh;
}

// Background pastille multi-couleurs
label[for='primary_color_filter14'].color_picto {
    background: url('../img/couleur/color_14.jpg') center center repeat scroll transparent;
}